import { optional, z } from "zod";
import { FormControlTypes } from "../../../Common/Utility";

let electricUnits = [
  { code: "01", description: "MWh" },
  { code: "02", description: "KWh" },
];

export const SelfGeneratedElectricityConfig = [
  {
    header: "Electricity",
    className: "",
    controls: [
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-6",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit",
        name: "unit",
        className: "col-6",
        type: FormControlTypes.Dropdown,
        options: electricUnits.map((unit) => {
          return { name: unit.description, value: unit.code };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Emission Factor (in tCO2/MWh)",
        placeholder: "",
        name: "indirectEmissionFactor",
        disabled: true,
        dependency: "name",
        className: "col-5",
        type: FormControlTypes.Text,
        validation: null,
      },

      {
        type: FormControlTypes.Trigger,
        text: "The emission factor is automatically calculated from the fuel mix. If a renewable energy source is used, the emission factor is 0.",
      },
    ],
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ]
  }
];
