import React, { useEffect } from "react";
import { DashboardLayout } from "../App/components/Layout";
import CgControlPanel from "../Common/Form/CgControlPanel";
import LoadingSpinner from "../Common/LoadingSpinner";
import { useHistory } from "react-router-dom";
import CgNoResults from "./CgNoResults";
import CgDataAnalytics from "./CgDataAnalytics";

/**
 * CgDataManagementPage is a generic page component for managing data with options to display
 * the data in a grid or table format. It also handles various actions like view, edit, delete,
 * create, import, and refresh.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the page.
 * @param {string} props.subtitle - The subtitle of the page.
 * @param {Array} props.actionButtons - An array of action buttons to display in the control panel.
 * @param {React.Component} props.gridComponent - The component used to display data in grid view.
 * @param {React.Component} props.tableComponent - The component used to display data in table view.
 * @param {Array} props.results - The array of data items to display.
 * @param {string} props.displayMode - The mode of display, either "Cubes" (grid) or "Table".
 * @param {Function} props.onViewItem - Callback function when an item is viewed.
 * @param {Function} props.onEditItem - Callback function when an item is edited.
 * @param {Function} props.onDeleteItem - Callback function when an item is deleted.
 * @param {Function} props.onCreateItem - Callback function when a new item is created.
 * @param {Function} props.onImport - Callback function when items are imported.
 * @param {Function} props.onRefresh - Callback function to refresh the data.
 * @param {boolean} props.loading - Boolean indicating whether the data is being loaded.
 * @param {Object} props.pagination - Object containing pagination details.
 * @returns {JSX.Element} The rendered data management page component.
 */
const CgDataManagementPage = ({
  title,
  subtitle,
  actionButtons,
  gridComponent: GridComponent,
  tableComponent: TableComponent,
  results,
  searchForm,
  dataAnalytics,
  displayMode,
  onViewItem,
  onEditItem,
  onDeleteItem,
  onCreateItem,
  onImport,
  onRefresh,
  loading,
  unit,
  pagination,
  tabComponent: TabComponent,
  mode, 
  modes, 
  handleModeChange
}) => {
  const history = useHistory();

  return (
    <DashboardLayout>
      {/* Display a loading spinner when the data is being fetched */}
      {loading && (
        <div className="flex justify-center items-center mt-[300px]">
          <LoadingSpinner loading={loading} size={100} />
        </div>
      )}
      {!loading && (
        <div className="pb-10 animate-fadeIn">
          {/* Header section displaying the title and subtitle */}
          <div className="flex justify-between items-center py-3">
            <div className="pl-4">
              <p className="text-2xl xl:text-3xl text-secondaryColor font-semibold">
                {title}
              </p>
              <p className="text-sm xl:text-base text-secondaryColor font-semibold">
                {subtitle}
              </p>
            </div>
            {/* Control panel containing action buttons */}
            {/* <CgControlPanel actionButtons={actionButtons} /> */}
            {dataAnalytics && dataAnalytics.length > 0 && (
              <CgDataAnalytics data={dataAnalytics} unit="tCO2e" />
            )}
          </div>

          {/* Main content section for displaying results */}
          <div className="flex flex-col justify-center items-center py-7">
            <div className="w-full">
              {/* Display a "no results" message if there are no items to show */}

              {/* Render the grid or table component based on the display mode and results */}
              <>
                {/* {displayMode === "Cubes" && GridComponent && results && (
                    <GridComponent
                      items={results}
                      onView={onViewItem}
                      onEdit={onEditItem}
                      onDelete={onDeleteItem}
                      pagination={pagination}
                    />
                  )} */}
                {/* {displayMode === "Table" && TableComponent && ( */}
                {TabComponent && <TabComponent 
                  mode={mode}
                  modes={modes}
                  handleModeChange={handleModeChange}
                />}
                <TableComponent
                  items={results}
                  onView={onViewItem}
                  onEdit={onEditItem}
                  onDelete={onDeleteItem}
                  handleCreate={onCreateItem}
                  onShowImport={onImport}
                  onRefresh={onRefresh}
                  pagination={pagination}
                  filterComponent={searchForm}
                  mode={mode}
                  modes={modes}
                />
                {/* )} */}
              </>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default CgDataManagementPage;
