import { z } from "zod";

export const zProducedGoodFormSchema = z.object({
  goods: z.array(
    z.object({
      cnCode: z.string().min(1, "Good is required"),
      productName: z.string(),
      params: z
        .array(
          z
            .object({
              name: z.string().min(1, "Parameter name is required"),
              value: z.union([
                z.string().min(1, "Value is required"),
                z.number().min(0).max(100),
                z.number(),
              ]),
              valueType: z.enum(["STRING", "PERCENTAGE", "NUMERIC"]),
              notes: z.string().optional(),
            })
            .transform((data, ctx) => {
              const { valueType, value } = data;

              // Handle STRING case
              if (valueType === "STRING") {
                return data;
              }

              // Handle PERCENTAGE case
              if (valueType === "PERCENTAGE") {
                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Value must be a number for PERCENTAGE",
                    path: ["value"],
                  });
                  return z.NEVER;
                }
                if (parsedValue < 0 || parsedValue > 100) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Percentage must be between 0 and 100",
                    path: ["value"],
                  });
                  return z.NEVER;
                }
                return {
                  ...data,
                  value: parsedValue,
                };
              }

              // Handle NUMERIC case
              if (valueType === "NUMERIC") {
                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Value must be a number for NUMERIC",
                    path: ["value"],
                  });
                  return z.NEVER;
                }
                return {
                  ...data,
                  value: parsedValue,
                };
              }

              return data;
            })
        )
        .optional()
        .default([]),
    })
  ),
  quantity: z.string().optional().or(z.number().optional()),
  cbamGood: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
        aggregatedGoodsCategory: z.string(),
      })
    )
    .min(1, "Required field"),
  unit: z.string().optional(),

  additionalInfo: z.string().or(z.literal("").optional()).optional(),
  direct: z.string().optional().or(z.number().optional()),
  indirect: z.string().optional().or(z.number().optional()),
  electricityConsumed: z.string().optional().or(z.number().optional()),
});
