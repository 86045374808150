import React, { forwardRef, useEffect, useState } from "react";
import {
  CountryDropdown,
  useAllCountries,
  useCbamCountries,
  useEUCountries,
} from "../Utility";
import CgDropdown from "./CgDropdown";
import CgSelectDropdown from "./CgSelectDropdown";

const CgCountrySelect = forwardRef(
  ({ formControl, loading, onChange, error, countryType, disabled }, ref) => {
    const [fc, setFc] = useState(formControl);
    const euCountries = useEUCountries();
    const allCountries = useAllCountries();
    const cbamCountries = useCbamCountries();
    let countries;
    switch (countryType) {
      case CountryDropdown.EU:
        countries = euCountries;
        break;
      case CountryDropdown.ALL:
        countries = allCountries;
        break;
      case CountryDropdown.CBAM:
        countries = cbamCountries;
        break;
    }
    countries = countries.map((country) => {
      return { value: country._id.toString(), label: country.name };
    });
    useEffect(() => {
      setFc({
        ...formControl,
        options: countries,
        value: formControl.value,
        optionLabel: "label",
        optionValue: "value",
        placeholder: "Please select a country",
      });
    }, [euCountries, allCountries, cbamCountries]);

    useEffect(() => {
      setFc({
        ...fc,
        options: countries,
        optionLabel: "label",
        optionValue: "value",
        value: formControl.value,
      });
    }, [formControl.value]);

    return (
      <CgSelectDropdown
        ref={ref}
        formControl={fc}
        loading={loading}
        error={error}
        disabled={disabled}
        onChange={onChange}
      />
    );
  }
);

export default CgCountrySelect;
