import { ProductionMethodsTab } from "./ProductionMethodsTab";
import { EmissionsDataTab } from "./EmissionsDataTab";
import React, { useEffect } from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useCnGoods } from "../../Common/Utility";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import http from "../../../helpers/requests";
import dayjs from "dayjs";
import productionMethods from "../data/ProdMethods.json";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

export const typesOfUnit = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
];

export const typesOfMeasurment = [
  { code: "Weight", description: "Weight" },
  { code: "Energy", description: "Energy" },
  { code: "Money", description: "Money" },
];

const defaultForm = {
  cnGood: "",
  indirectEmissionFactor: "",
  productionMethods: [],
  installationId: "",
  typeOfApplicableReportingMethodology: "TOM01",
  indirectDeterminationType: "02",
  indirectEmissionFactorSource: "",
  electricityConsumed: "",
  electricitySource: "SOE01",
  additionalInformation: "",
  date: "",
  lastRecordDate: "",
  unit: "tCO2e/tonne of product",
  specificDirectEmission: "",
  specificIndirectEmission: "",
  otherSourceIndication: "",
  previousTwoQuarters: false,
};

const ProductionMethod = {
  methodCode: "",
  name: "",
  steelMill: "",
  directParameters: [],
};

export default function InstallationRealEmissionsModal(props) {
  const [form, setForm] = useState(defaultForm);
  const [categoryProductionMethods, setCategoryProductionMethods] = useState(
    []
  );
  const [step, setStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorsTab1, setErrorsTab1] = useState({});
  const [errorsTab2, setErrorsTab2] = useState({});
  const cnGoods = useCnGoods();

  const onClose = (e) => {
    props.onClose();
    setStep(1);
    setLoading(false);
    setForm(defaultForm);
    setErrorsTab1({});
    setErrorsTab2({});
  };

  useEffect(() => {
    if (props.emission) {
      updateForm();
    }
  }, [props?.emission]);

  const updateForm = () => {
    const { directMeta, indirectMeta } = props.emission;
    let cnCode = typeof props.emission.cnGood == "object" ? props.emission.cnGood.cnCode : props.emission.cnGood;
    setForm({
      _id: props.emission._id,
      cnGood: cnCode,
      productionMethods: props.emission.productionMethods ?? [],
      installationId: props.emission.installationId ?? "",
      emissionFactors: {
        direct: props.emission.emissionFactors?.direct ?? "",
        indirect: props.emission.emissionFactors?.indirect ?? "",
        total: props.emission.emissionFactors?.total ?? "",
      },
      specificDirectEmission: props.emission.emissionFactors?.direct ?? "",
      specificIndirectEmission: props.emission.emissionFactors?.indirect ?? "",
      indirectEmissionFactorSource:
        indirectMeta?.indirectEmissionFactorSource ?? "",
      electricityConsumed: indirectMeta?.electricityConsumed ?? "",
      electricitySource: indirectMeta?.electricitySource ?? "",
      typeOfApplicableReportingMethodology:
        directMeta?.typeOfApplicableReportingMethodology ?? "",
      indirectEmissionFactor: indirectMeta?.indirectEmissionFactor ?? "",
      indirectDeterminationType: indirectMeta?.determinationType ?? "",
      otherSourceIndication: indirectMeta?.otherSourceIndication ?? "",
      additionalInformation: directMeta.additionalInformation ?? "",
      unit: props.emission.unit ?? "",
      description: props.emission.description ?? "",
      date: dayjs(props.emission.date).format("YYYY-MM-DD") ?? "",
      lastRecordDate:
        dayjs(props.emission.lastRecordDate).format("YYYY-MM-DD") ?? "",
    });
    const categoryMethods =
      productionMethods[
        cnGoods.find((good) => good.cnCode === cnCode)
          ?.aggregatedGoodsCategory
      ];
    categoryMethods?.sort((a, b) => b > a);
    setCategoryProductionMethods(categoryMethods);
  };

  const deleteUnnecessaryErrors = (newErrors) => {
    let keys = Object.keys(newErrors);
    for (let i = 0; i < keys.length; i++) {
      if (!newErrors[keys[i]]) {
        delete newErrors[keys[i]];
      }
    }
    return newErrors;
  }

  const getSignificantFigures = (beforePointVal, afterPointVal) => (value) => {
    value = value.toString();
    const [beforePoint, afterPoint] = value.split(".");
    if(beforePoint?.length > beforePointVal){
      return `Number cannot be greater than ${"9".repeat(beforePointVal)}.${"9".repeat(afterPointVal)}`;
    }
    if(afterPoint?.length > afterPointVal){
      return `Number must be at most ${afterPointVal} decimal places`;
    }
    return "";
  }

  const validateForm = () => {
    const {
      cnGood,
      indirectEmissionFactor,
      productionMethods,
      typeOfApplicableReportingMethodology,
      indirectDeterminationType,
      indirectEmissionFactorSource,
      electricityConsumed,
      electricitySource,
      additionalInformation,
      date,
      lastRecordDate,
      specificDirectEmission,
      specificIndirectEmission,
    } = form;
    const newErrors1 = {};
    const newErrors2 = {};
    const errorsConfig = [
      {
        step: 1,
        fields: [
          {
            field: "cnGood",
            message: "Please enter a CN Good",
            condition: !cnGood,
          },
          {
            field: "indirectEmissionFactor",
            message:
              "Please enter a numeric value for indirect emission factor",
            condition:
              indirectDeterminationType === "01" &&
              indirectEmissionFactorSource === "01" &&
              !indirectEmissionFactor,
            validate: getSignificantFigures(2,5)
          },
          {
            field: "typeOfApplicableReportingMethodology",
            message: "Please select a reporting rule",
            condition: !typeOfApplicableReportingMethodology,
          },
          {
            field: "indirectDeterminationType",
            message: "Please select an indirect determination type",
            condition: !indirectDeterminationType,
          },
          {
            field: "indirectEmissionFactorSource",
            message: "Please select an indirect emission factor source",
            condition:
              indirectDeterminationType === "01" &&
              !indirectEmissionFactorSource,
          },
          {
            field: "electricityConsumed",
            message: "Please enter a numeric value for electricity consumed",
            condition: indirectDeterminationType === "01" && !electricityConsumed,
            validate: getSignificantFigures(2,2)
          },
          {
            field: "electricitySource",
            message: "Please select an electricity source",
            condition: !electricitySource,
          },
          {
            field: "additionalInformation",
            message: "Please enter an applicable reporting methodology",
            condition:
              typeOfApplicableReportingMethodology === "TOM02" &&
              !additionalInformation,
          },
          {
            field: "date",
            message: "Please enter an initial date",
            condition: !date,
          },
          {
            field: "lastRecordDate",
            message: "Please enter a final date",
            condition: !lastRecordDate,
          },
          {
            field: "specificDirectEmission",
            message:
              "Please enter a numeric value for specific direct emission",
            condition: !specificDirectEmission,
          },
          {
            field: "specificIndirectEmission",
            message:
              "Please enter a numeric value for specific indirect emission",
            condition:
              !specificIndirectEmission && specificIndirectEmission !== 0,
          },
        ],
      },
      {
        step: 2,
        fields: [
          {
            field: "productionMethods",
            message: "Please select at least one production method",
            condition: productionMethods.length === 0,
          },
        ],
      },
    ];
    errorsConfig.forEach((config) => {
      const errorTarget = config.step === 1 ? newErrors1 : newErrors2;
      config.fields.forEach((fieldConfig) => {
        const fieldValue = form[fieldConfig.field];
        if (fieldConfig.condition) {
          errorTarget[fieldConfig.field] = fieldConfig.message;
        }else if(fieldValue && fieldConfig.validate){
          errorTarget[fieldConfig.field] = fieldConfig.validate(fieldValue);
        }
      });
    });

    deleteUnnecessaryErrors(newErrors1);
    deleteUnnecessaryErrors(newErrors2);

    return { newErrors1, newErrors2 };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors?.newErrors1).length > 0) {
      setErrorsTab1(formErrors?.newErrors1);
    } else if (Object.keys(formErrors?.newErrors2).length > 0) {
      setErrorsTab2(formErrors?.newErrors2);
    } else {
      // Remove production methods with an empty methodCode
      const filteredProductionMethods = form.productionMethods.filter(
        (method) => method.methodCode !== ""
      );
      // Remove direct parameters with all ids empty for each production method
      const filteredProductionMethodsUpdated = filteredProductionMethods.map(
        (method) => ({
          ...method,
          directParameters: method.directParameters.filter(
            (parameter) => parameter.id !== "" || parameter.value !== ""
          ),
        })
      );
      setForm((prevForm) => ({
        ...prevForm,
        productionMethods: filteredProductionMethodsUpdated,
      }));
      let data = {
        ...form,
        installationId: props.installation._id,
        emissionFactors: {
          direct: parseFloat(form?.specificDirectEmission),
          indirect: parseFloat(form?.specificIndirectEmission),
          total:
            parseFloat(form?.specificDirectEmission) +
            parseFloat(form?.specificIndirectEmission),
        },
        directMeta: {
          determinationType: "01",
          typeOfApplicableReportingMethodology:
            form.typeOfApplicableReportingMethodology,
          additionalInformation: form.additionalInformation,
        },
        indirectMeta: {
          determinationType: form.indirectDeterminationType,
          indirectEmissionFactor: form.indirectEmissionFactor
            ? parseFloat(form.indirectEmissionFactor)
            : undefined,
          indirectEmissionFactorSource: form.indirectEmissionFactorSource,
          electricityConsumed: form.electricityConsumed
            ? parseFloat(form.electricityConsumed)
            : undefined,
          electricitySource: form.electricitySource,
          otherSourceIndication: form.otherSourceIndication,
        },
        productionMethods: filteredProductionMethodsUpdated,
      };
      if (props.emission?._id) {
        try {
          let res = await http.patch(`/emissions/${props.emission?._id}`, data);
          props.handleRefresh();
          onClose();
          notify("Success", "Emission has been updated successfully", AlertType.SUCCESS);
        } catch (err) {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
        }
      } else {
        try {
          let res = await http.post("/emissions/", data);
          props.handleRefresh();
          onClose();
          notify("Success", "Emission has been created successfully", AlertType.SUCCESS);
        } catch (err) {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
        }
      }
    }
  };

  const handleNextStep = async (e) => {
    if (step == 1) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors1).length > 0) {
        setErrorsTab1(newErrors1);
      } else {
        setStep((previousVal) => +previousVal + 1 + "");
      }
    }
    if (step == 2) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors2).length > 0) {
        setErrorsTab2(newErrors2);
      } else {
        setStep((previousVal) => +previousVal + 1 + "");
      }
    }
    if (+step >= 2) return;
  };

  const handlePreviousStep = (e) => {
    if (+step <= 0) return;
    setStep((previousVal) => +previousVal - 1 + "");
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  const handleCheckBoxChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>Emissions Data</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <Tabs
            activeKey={step}
            onSelect={(k) => setStep(k)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="Emissions Data">
              <EmissionsDataTab
                form={form}
                productionMethod={ProductionMethod}
                cnGoods={cnGoods}
                cnGood={props?.emission?.cnGood}
                setForm={setForm}
                clearError={clearError}
                errorsTab1={errorsTab1}
                setErrorsTab1={setErrorsTab1}
                setCategoryProductionMethods={setCategoryProductionMethods}
                emission={props?.emission}
                lockForm={props?.installation?.independentOperatorId != null}
              />
            </Tab>
            <Tab
              eventKey="2"
              title="Production Methods & Qualifying Parameters"
              disabled={!Boolean(form.cnGood)}
            >
              <ProductionMethodsTab
                form={form}
                setForm={setForm}
                clearError={clearError}
                categoryProductionMethods={categoryProductionMethods}
                loading={loading}
                errorsTab2={errorsTab2}
                setErrorsTab2={setErrorsTab2}
                productionMethod={ProductionMethod}
                lockForm={props?.installation?.independentOperatorId != null}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Form.Check
                type="checkbox"
                label="Apply updates to shipments from the previous two quarters as well."
                id="previousTwoQuarters"
                name="previousTwoQuarters"
                checked={form?.previousTwoQuarters}
                onChange={(e) => handleCheckBoxChange(e)}
              />
            </div>
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Close
              </Button>{" "}
              {+step > 1 && step != 3 && (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={loading}
                    onClick={handlePreviousStep}
                    style={{ color: "white" }}
                  >
                    <FontAwesomeIcon icon={faIcons.faArrowLeft} /> Previous
                  </Button>{" "}
                </>
              )}
              {+step < 2 && (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={step == 2}
                    onClick={handleNextStep}
                    style={{ color: "white" }}
                  >
                    Next <FontAwesomeIcon icon={faIcons.faArrowRight} />
                  </Button>{" "}
                </>
              )}
              {step == 2 && (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={props?.installation?.independentOperatorId != null}
                    style={{ color: "white" }}
                  >
                    Submit <FontAwesomeIcon icon={faIcons.faSave} />
                  </Button>{" "}
                </>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
