import { Button, Modal } from "react-bootstrap";
import { ShipmentContentsTable } from "./ShipmentContentsTable";
import ShipmentContentViewDetails from "./ShipmentContentViewDetails";
import { useState } from "react";
import dayjs from "dayjs";
import CgButton from "../../Common/CgButton";
import http from "../../../helpers/requests";
import { AlertType, notify, notifyError } from "../../Common/Form/CgAlertMessage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ShipmentResolveAmbiguityModal(props) {
  const [showModal, setShowModal] = useState(false);
  const [viewContentDetails, setViewContentDetails] = useState(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleViewShipmentContentDetails = (content) => {
    openModal();
    setViewContentDetails(content);
  };

  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.onClose}
      style={{ marginTop: "50px" }}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resolve Ambiguity</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "40vh" }}>
        <div className="text-secondaryColor">There is ambiguity in this consignment because the operator either has multiple installations producing goods under this CN code or has a single installation producing multiple products under the same CN code. Therefore, our system requires you to manually assign the appropriate emission factor to your imported good.</div>
        <div className="mt-2 text-secondaryColor font-semibold">Please click on the fix button on one of the consignment's contents to start clearing up this mismatch.</div>
        <div className="py-10">
          <ShipmentContentsTable
            onViewDetails={handleViewShipmentContentDetails}
            form={props.shipment}
            fix
          />
          <ResolveAmbiguityModal
            show={showModal}
            shipment={props.shipment}
            content={viewContentDetails}
            onRefresh={() => {closeModal(); props.onClose(); props.onRefresh()}}
            onClose={() => closeModal()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ResolveAmbiguityModal(props){
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleOptionClick = (id) => {
    setSelectedOption(id);
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  function handleClose(){
    setSelectedOption(null);
    setSelectedRadio(null);
    props.onClose();
  }

  function handleSubmit(){
    let filters = {};
    if(selectedRadio !== "allConsignments"){
      filters["shipmentId"] = props.shipment?._id;
    }
    if(selectedRadio === "consignmentContentOnly"){
      filters["shipmentContentId"] = props.content?._id;
    }
    http.put("/shipments/resolve", {
      ...filters,
      ambigousFactors: props.content?.ambigousFactors?.map(f => f?._id),
      emissionFactorChoosen: selectedOption
    })
    .then(res => {
      notify("Success", "Ambiguity resolved successfully", AlertType.SUCCESS);
      props.onRefresh();
    })
    .catch(notifyError)
  }

  const selected = props.content?.ambigousFactors?.filter(factor => factor._id === selectedOption);

  return (
      <Modal
        size="xl"
        show={props.show}
        onHide={handleClose}
        style={{ marginTop: "50px" }}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Emission Factor for this Consignment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div className="p-6 space-y-6">
            <div className="text-lg font-semibold">You have to choose one of the following products produced by the Operator</div>
            {/* Option Selection */}
            <div className="flex flex-row space-x-4">
              {props.content?.ambigousFactors?.map((option) => (
                <div
                  key={option._id}
                  onClick={() => handleOptionClick(option._id)}
                  className={`cursor-pointer border rounded-lg p-4 w-60 shadow-md transition-colors duration-200 \
                    ${selectedOption === option._id ? 'bg-green-100 border-green-500' : 'bg-white border-gray-300 hover:border-gray-400'}`}
                >
                  <div className="text-lg font-semibold mb-2">{option.operatorGoodName}</div>
                  <div className="text-sm text-gray-600">
                    <div className="font-bold pb-2">Emission Factors: </div>
                    <div className="pl-2 font-medium"><span className="font-bold">Direct:</span> {option.emissionFactors.direct}</div>
                    <div className="pl-2 font-medium"><span className="font-bold">Indirect</span>: {option.emissionFactors.indirect}</div>
                    <div className="pl-2 font-medium"><span className="font-bold">Total</span>: {option.emissionFactors.total}</div>
                  </div>
                </div>
              ))}
            </div>
            {selectedOption && <div className="text-lg">You have selected: <span className="font-semibold">{selected[0].operatorGoodName}</span></div>}
            {/* Radio Button Section */}
            {selectedOption && (
              <div className="space-y-2">
                <label className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name="performOption"
                    value="consignmentContentOnly"
                    checked={selectedRadio === 'consignmentContentOnly'}
                    onChange={() => handleRadioChange('consignmentContentOnly')}
                    className="h-5 w-5 text-blue-500 border-gray-300 rounded-full focus:ring focus:ring-blue-200"
                  />
                  <span className="text-gray-700">Perform on this Consignment Content only</span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name="performOption"
                    value="consignment"
                    checked={selectedRadio === 'consignment'}
                    onChange={() => handleRadioChange('consignment')}
                    className="h-5 w-5 text-blue-500 border-gray-300 rounded-full focus:ring focus:ring-blue-200"
                  />
                  <span className="text-gray-700">Perform on this Consignment</span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name="performOption"
                    value="allConsignments"
                    checked={selectedRadio === 'allConsignments'}
                    onChange={() => handleRadioChange('allConsignments')}
                    className="h-5 w-5 text-blue-500 border-gray-300 rounded-full focus:ring focus:ring-blue-200"
                  />
                  <span className="text-gray-700">Perform on all Ambigous Consignments with this specific option selected</span>
                </label>
              </div>
            )}
            {selectedOption && (
              <CgButton
                label="Update"
                variant="primary"
                disabled={selectedRadio === null}
                onClick={handleSubmit}
              />
            )}
         </div>
        </Modal.Body>
      </Modal>
  );
}