import React from "react";
import {
  Button,
  FloatingLabel,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { ShipmentContentsModal } from "./ShipmentContentsModal";
import http from "../../../helpers/requests";
import { useCnGoods } from "../../Common/Utility";
import CgButton from "../../Common/CgButton";
import CgPopover from "../../Common/CgPopover";

export const ShipmentContentsTable = (props) => {
  const [suppliers, setSuppliers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const cnGoods = useCnGoods();
  const handleOpenModal = () => {
    if (props.form?.deliveryDate) {
      setShowModal(true);
    } else {
      props.setErrors({
        ...props.errors,
        deliveryDate: "Select a date before you proceed",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    http.get("/suppliers/declarant").then((response) => {
      setSuppliers(response.data.suppliers);
    });
  }, []);

  const typesOfUnit = [
    { code: "01", description: "Tonnes" },
    { code: "02", description: "Kg" },
    { code: "03", description: "MWH" },
    { code: "04", description: "m^3" },
    { code: "05", description: "GJ" },
  ];

  return (
    <div className="pb-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
        <p className="text-lg text-secondaryColor font-semibold pb-2">Consignment Contents</p>
          <p className="text-secondaryColor text-sm">
            A list of all the contents in your consignment including their goods,
            quantity, cost and more.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <ShipmentContentsModal
            shipment={props.form}
            onSave={props.onSave}
            show={showModal}
            handleClose={handleCloseModal}
          ></ShipmentContentsModal>{" "}
          {(!props.view && !props.fix) && (
            <CgButton variant="primary" outlined label="Add Content" icon="faPlus" onClick={handleOpenModal} />
          )}
        </div>
      </div>
      {props.form?.shipmentContents?.length > 0 ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead style={{ backgroundColor: "#00B894" }}>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        CN Codes
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Measurement Unit
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Cost
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Emission Values Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {props.form?.shipmentContents.map((content, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {content?.supplier?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <CgPopover
                            triggerElement={<div>{content?.cnGood}</div>}
                            popoverContent={
                              cnGoods.find(
                                (good) => good._id === `${content?.cnGood}`
                              )?.name
                            }
                          ></CgPopover>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {content?.quantity.toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {" "}
                          {
                            typesOfUnit.find(
                              (unit) => unit.code === `${content.unit}`
                            )?.description
                          }
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {content?.cost.toLocaleString()} €
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {content?.emissionsFactorUsed?.granularity ===
                          "Global" ? (
                            <div className="flex items-center">
                              <FontAwesomeIcon
                                color="orange"
                                icon={faIcons.faExclamationTriangle}
                                style={{ paddingRight: "6px" }}
                              />
                              <p> Default Values</p>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <FontAwesomeIcon
                                color="#00B894"
                                icon={faIcons.faCheckCircle}
                                style={{ paddingRight: "6px" }}
                              />
                              <p> Actual Values</p>
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {props.fix && (
                            <Button 
                              size="sm"
                              variant="warning"
                              className="text-white"
                              onClick={() => props.onViewDetails(content)}
                            >
                              <FontAwesomeIcon
                                icon={faIcons.faWrench}
                              />{" "}
                            </Button>
                          )}
                          {props.view && (
                            <FontAwesomeIcon
                              onClick={() => props.onViewDetails(content)}
                              color="#00b894"
                              icon={faIcons.faEye}
                            />
                          )}
                          {props.onCreate && (
                            <div className="flex space-x-5 justify-center">
                              <FontAwesomeIcon
                                onClick={() =>
                                  props.onUpdateContent(content, i)
                                }
                                color="#00b894"
                                icon={faIcons.faPencilAlt}
                              />
                              <FontAwesomeIcon
                                onClick={() => props.onRemove(i)}
                                color="#ed3215"
                                icon={faIcons.faTrash}
                              />
                            </div>
                          )}
                          {props.update && (
                            <div className="flex space-x-5 justify-center">
                              <FontAwesomeIcon
                                onClick={() =>
                                  props.onUpdateContent(content, i)
                                }
                                color="#00b894"
                                icon={faIcons.faPencilAlt}
                              />
                              <FontAwesomeIcon
                                onClick={() => props.onRemove(i)}
                                color="#ed3215"
                                icon={faIcons.faTrash}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-secondaryColor-light rounded-xl text-3xl font-semibold text-center py-16">
          No Shipment Content
        </div>
      )}
    </div>
  );
};
