import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgErrorLabel from "./CgErrorLabel";
import CgFormLabel from "./CgFormLabel";
import Creatable from "react-select/creatable";
import Select, { components } from "react-select";

const CgSelectDropdown = ({
  formControl,
  loading,
  onChange,
  error,
  formData,
  disabled,
  jsonBodyKey,
  jsonBodyValue,
}) => {
  const [options, setOptions] = useState(formControl.options ?? []);

  const getDefaultLabel = (value) => {
    let defaultLabel = "";
    if(jsonBodyValue && value){
      jsonBodyValue.forEach((key) => {
        defaultLabel += JSON.parse(value)[key]
        defaultLabel += " - ";
      });
      defaultLabel = defaultLabel.slice(0, defaultLabel.length - 3);
    }else{
      defaultLabel = value;
    }
    return defaultLabel;
  }

  useEffect(() => {
    let init = async () => {
      if (options.length <= 1 && formControl.fetchOptions) {
        let dependency = formControl.dependency
          ? formData[formControl.dependency]
          : null;
        let newOptions = await formControl.fetchOptions(dependency);
        setOptions(newOptions);
      } else if (formControl.options) {
        setOptions(formControl.options);
      }
    };
    init();
  }, [formControl.options]);

  const getOptions = () => {
    if (
      formControl.value &&
      !options.map((opt) => opt.value).includes(formControl.value)
    ) {
      let label = getDefaultLabel(formControl.value);
      setOptions([
        ...options,
        { label: label, value: formControl.value },
      ]);
    }
    return options;
  };
  const getValue = () => {
    if (formControl.isMulti) {
      if (formControl.value) {
        return getOptions().filter((option) =>
          formControl.value.some((val) => val === option.value)
        );
      }
      return [];
    } else {
      let defaultValue = null;
      if (jsonBodyValue && formControl.value) {
        let defaultLabel = "";
        jsonBodyValue.forEach((key) => {
          defaultLabel += JSON.parse(formControl.value)[key] + " - ";
        });
        defaultValue = {
          label: defaultLabel.slice(0, defaultLabel.length - 3),
          value: formControl.value,
        };
      }
      if (jsonBodyKey && formControl.value) {
        const matchedOption = getOptions().find((option) => {
          if (option.value && formControl.value) {
            const optionKey = JSON.parse(option.value)[jsonBodyKey];
            const valueKey = JSON.parse(formControl.value)[jsonBodyKey];
            return optionKey === valueKey;
          }
          return false;
        });
        return matchedOption || defaultValue;
      }
      return (
        getOptions().find((option) => option.value === formControl.value) ||
        defaultValue
      );
    }
  };
  
  const handleChange = (selectedOption) => {
    let actualValue;
    if (formControl.isMulti) {
      actualValue = selectedOption;
    } else {
      actualValue = selectedOption.value;
    }
    onChange({ target: { name: formControl.name, value: actualValue } });
  };

  let SelectComponent = formControl.creatable ? Creatable : Select;

  return (
    <div className={`my-2 ${formControl.className}`}>
      <SelectComponent
        id={formControl.name}
        onCreateOption={(inputValue) => {
          let newOption = { label: inputValue, value: inputValue };
          setOptions((prevOptions) => [...prevOptions, newOption]);
          handleChange(newOption); 
        }}        
        menuPortalTarget={document.body}
        options={getOptions()}
        value={getValue()}
        placeholder={formControl.placeholder}
        onChange={handleChange}
        isDisabled={disabled || loading}
        isMulti={formControl.isMulti}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#BFEDE4",
            primary: "#BFEDE4",
          },
        })}
        styles={{
          control: (provided, state) => ({
            ...provided,
            minHeight: "57px",
            height: "57px",
            borderRadius: "5px",
            overflow: "hidden",
            height: "fit",
          }),
          multiValue: (base) => ({
            ...base,
            maxWidth: "300px", // Adjust as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 10160 }),
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: "300px", // Adjust as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
          }),
        }}
      />
      <CgErrorLabel error={error} />
    </div>
  );
};

export default CgSelectDropdown;
