import React, { useEffect } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import MaxPageResultsSelect from "./MaxPageResultsSelect";
import CgPagination from "./CgPagination";
import CgButton from "./CgButton";
import CgPanel from "./CgPanel";
import CgNoResults from "./CgNoResults";
import { CgToggle } from "./CgToggle/CgToggle";

/**
 * CgTable is a component that displays a table with customizable headers, data, and actions.
 * It also includes pagination and custom buttons.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.headers - An array of header objects defining the table columns.
 * @param {Array} props.data - An array of data objects to be displayed in the table.
 * @param {Array} props.actions - An array of action objects to be rendered in the action column.
 * @param {Array} props.values - The values for actions and overlays.
 * @param {string} props.title - The title of the table.
 * @param {Array} props.tableButtons - An array of buttons to be displayed above the table.
 * @param {Object} props.pagination - Pagination data and callbacks.
 * @returns {JSX.Element} The rendered table component.
 */
const CgTable = ({
  headers,
  data,
  actions,
  values,
  title,
  filter,
  tableButtons,
  pagination,
}) => {
  /**
   * Renders the overlay for action buttons with popovers.
   *
   * @param {Object} overlay - The overlay data including title, body, and buttons.
   * @param {Object} item - The data item for which the overlay is rendered.
   * @returns {JSX.Element} The popover element.
   */

  const formatNumber = (number) => {
    if (number === null || number === undefined || isNaN(number)) {
      return ""; // Handle cases where the number is not valid
    }

    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const renderOverlay = (overlay, item) => (
    <Popover id={`popover-${overlay.id}`}>
      <Popover.Header as="h3">{overlay.title}</Popover.Header>
      <Popover.Body>
        {overlay.body}
        <div className="w-full d-flex gap-0 items-center justify-end">
          {overlay.buttons.map((btn, idx) => (
            <Button
              key={`popover-btn-${idx}`}
              variant={btn.variant}
              style={btn.style}
              onClick={() => {
                btn.onClick(item);
                document.body.click(); // Close the popover on button click
              }}
            >
              {btn.label}
            </Button>
          ))}
        </div>
      </Popover.Body>
    </Popover>
  );

  /**
   * Renders a table cell based on the column configuration.
   *
   * @param {Object} item - The data item for the row.
   * @param {Object} column - The column configuration.
   * @returns {JSX.Element} The rendered table cell.
   */
  const renderCell = (item, column) => {
    const cellStyle = {
      fontSize: "14px",
      fontWeight: "600",
      color: "#607489",
    };

    if (column.render) {
      return <div style={cellStyle}>{column.render(item)}</div>;
    }
    if (column.type === "date") {
      return (
        <div style={cellStyle}>
          {dayjs(item[column.key]).format(column.format || "DD/MM/YYYY")}
        </div>
      );
    }
    if (column.type === "number") {
      return (
        <div style={cellStyle}>
          {formatNumber(item[column.key])}
          {column.emissions ? " tCO2e" : ""}
        </div>
      );
    }
    return <div style={cellStyle}>{item[column.key] ?? ""}</div>;
  };

  return (
    <CgPanel>
      <div className="flow-root bg-white md:rounded-lg">
        <div className="flex justify-between space-x-3 pb-3">
          <p className="xl:text-xl text-lg text-secondaryColor font-semibold">
            {title}
          </p>
          <div className="flex space-x-3">
            {tableButtons?.map(
              (btn, idx) =>
                btn?.type === "toggle" && (
                  <CgToggle
                    key={`table-toggle-${idx}`}
                    offLabel={btn.offLabel}
                    onLabel={btn.onLabel}
                    toggled={btn.toggled}
                    onClick={btn.onClick}
                  />
                )
            )}
            {filter}
            {tableButtons?.map(
              (btn, idx) =>
                btn?.type !== "toggle" && (
                  <CgButton
                    key={`table-btn-${idx}`}
                    variant={btn.variant}
                    label={btn.label}
                    size="sm"
                    icon={btn.icon}
                    outlined={btn.outlined}
                    onClick={btn.onClick}
                  />
                )
            )}
          </div>
        </div>
        <hr
          style={{ borderTop: "2px solid #a0aebc", marginBottom: "25px" }}
          className="w-full"
        />
        <div className="-my-2 overflow-x-auto overflow-visible">
          <div className="inline-block min-w-full align-middle">
            {data?.length === 0 && <CgNoResults hasArrow />}
            {data?.length > 0 && (
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {headers.map((header, idx) => (
                      <th
                        key={`header-${idx}`}
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          background: "white",
                          opacity: "60%",
                          color: "#607489",
                        }}
                        className="px-3 py-2"
                      >
                        {header.label}
                      </th>
                    ))}
                    {actions && <th></th>}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.map((item, index) => (
                    <tr
                      key={`row-${index}`}
                      style={{
                        backgroundColor: "white", // Alternating colors manually
                        transition: "background-color 0.3s ease", // Smooth transition for hover effect
                      }}
                      onMouseEnter={
                        (e) =>
                          (e.currentTarget.style.backgroundColor = "#e0e0e0") // Hover effect
                      }
                      onMouseLeave={
                        (e) => (e.currentTarget.style.backgroundColor = "white") // Revert back
                      }
                    >
                      {headers.map((column, idx) => (
                        <td
                          key={`cell-${idx}`}
                          style={{ paddingTop: "11px", paddingBottom: "11px" }}
                          className="px-3 text-sm font-medium text-gray-900"
                        >
                          {renderCell(item, column)}
                        </td>
                      ))}
                      {/* Actions column */}
                      {actions && (
                        <td className="px-3">
                          <div
                            className="flex justify-end items-center gap-1"
                            style={{ height: "100%" }}
                          >
                            {actions.map((action, idx) =>
                              action.overlay ? (
                                <OverlayTrigger
                                  key={`action-btn-${idx}`}
                                  trigger="click"
                                  placement="top"
                                  overlay={renderOverlay(
                                    action.overlay,
                                    values[index]
                                  )}
                                  rootClose={true}
                                >
                                  <Button
                                    size="sm"
                                    variant={action.variant}
                                    className={action.className}
                                    style={action.style}
                                  >
                                    <FontAwesomeIcon
                                      icon={faIcons[action.icon]}
                                    />{" "}
                                    {action?.label}
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                <Button
                                  key={`action-btn-${idx}`}
                                  size="sm"
                                  variant={action.variant}
                                  onClick={() => action.onClick(values[index])} // Use the proper item from the values prop
                                  className={action.className}
                                >
                                  <FontAwesomeIcon
                                    icon={faIcons[action.icon]}
                                  />{" "}
                                  {action?.label}
                                </Button>
                              )
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <hr
          style={{ borderTop: "2px solid #e5e7eb", marginTop: "8px" }}
          className="w-full"
        />
        {pagination && (
          <div>
            <div className="pt-[40px] flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    opacity: "60%",
                    color: "#607489",
                  }}
                >
                  Total Results : {pagination?.total}
                </p>
              </div>
              <CgPagination
                {...pagination}
                changePage={(p) => {
                  pagination?.onPageChange(p);
                }}
              />
              <MaxPageResultsSelect
                options={[5, 10, 25, 50, 100]}
                value={pagination?.limit}
                onChange={(value) => {
                  pagination?.onPageChange(1, parseInt(value)); // Reset to page 1 when limit changes
                }}
              />
            </div>
          </div>
        )}
      </div>
    </CgPanel>
  );
};

export default CgTable;
