import React from "react";
import Routes from "./_routes";
import "./App.scss";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";

import { loadStripe } from "@stripe/stripe-js";
import MobileNotSupported from "../Common/MobileNotSupported";
import { initRum } from "./_rum";

import { Clerk } from "@clerk/clerk-js";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
export const clerk = new Clerk(clerkPubKey);
await clerk.load();
export const stripePubKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

initRum();

export const stripePromise = loadStripe(stripePubKey);
const App = () => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
 
  if (isMobile) {
    return <MobileNotSupported />;
  }

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <Routes />
    </ClerkProvider>
  );
};

export default App;
