import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faHandsHelping,
  faIndustry,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import { OrganizationType } from "../../Common/Utility";
import { useUser } from "@clerk/clerk-react";
import { PriceSlider, PricingTable } from "./PricingTable";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { stripePromise } from "../_app";
import http from "../../../helpers/requests";

const CheckoutForm = ({ priceId, onClose, quantity }) => {
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    let res = await http.post("/payments/create-checkout-session", {
      priceId,
      quantity,
    });
    return res.data.clientSecret;
  }, [priceId]);

  const options = { fetchClientSecret };

  return (
    <div className="mt-3 shadow-medium text-center min-w-[100]">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout className="Custom" />
        </EmbeddedCheckoutProvider>
      <div className="items-center px-4 py-3">
        <button
          id="close-modal"
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
        >
          Back to Selection
        </button>
      </div> 
    </div>
  );
};
const UserTypeButton = ({ title, icon, description, onClick }) => (
  <button
    onClick={onClick}
    className="w-64 h-80 p-6 bg-white rounded-lg shadow-medium flex flex-col items-center justify-center space-y-4 transition-transform hover:scale-105"
  >
    <FontAwesomeIcon icon={icon} size="6x" className="text-primaryColor" />

    <span className="text-2xl font-semibold text-primaryColor">{title}</span>
    <p className="text-secondaryColor">{description}</p>
  </button>
);

const PaymentPage = () => {
  const [selectedUserType, setSelectedUserType] = useState(null);
  const { user } = useUser();
  const [units, setUnits] = useState(5);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const handleUnitChange = (e) => {
    setUnits(parseInt(e.target.value));
  };

  const handleSubscribe = (priceId) => {
    setSelectedPriceId(priceId);
    setShowCheckout(true);
  };

  const declarantPlans = [
    {
      title: "CBAM Essentials",
      description: "Automate your CBAM compliance & reporting in minutes",
      // basePrice: 150,
      // included: 5,
      // perUnitPrice: 30,
      features: [
        "Upload your data all at once without manual data inputs",
        "Request and collect data from suppliers",
        "Generate your quarterly CBAM Reports with a few clicks",
      ],
      // unitName: "shipment",
      included: 0,
      time: "year",
      buttonText: "Contact Us",
      isPopular: false,
      isEnterprise: true,
    },
    // {
    //   title: "CBAM Enterprise",
    //   description:
    //     "Importing more than 50 shipments per quarter or handling multiple subsidiaries? Contact us for a custom quote",
    //   features: [
    //     "Everything in the previous tier",
    //     "Tier 1 suppliers access to operator tool",
    //     "Tailored support",
    //   ],
    //   buttonText: "Contact us",
    //   time: "quarter",
    //   isPopular: false,
    //   isEnterprise: true,
    // },
  ];

  const operatorPlans = [
    {
      title: "Monitoring & Reporting Tool",
      description: "Calculate your CBAM emissions data in minutes",
      features: [
        "Map your factory's CBAM emission sources with a digital schematic diagram",
        "Automate CBAM emission calculations",
        "Send and track supplier emissions data requests",
        "Compare your product emissions against country-specific default values",
        "Access step-by-step tutorials & Help Centre",
        "Generate the CBAM-required data for your clients"
      ],
      included: 1,
      unitName: "installation",
      time: "year",
      buttonText: "Subscribe",
      basePrice: 500,
      perUnitPrice: 500,
    },
  ];
  const consultantPlans = [
    {
      title: "Consultant Plan",
      description: "Tailored packages to help scale your consulting business.",
      features: [
        "Automate CBAM reporting for your EU clients",
        "Calculate emissions data for your non-EU clients in minutes",
      ],
      included: 0,
      time: "year",
      unitName: "client",
      buttonText: "Contact Us",
      isEnterprise: true,
    },
  ];
  const possiblePlans = {
    [OrganizationType.Declarant]: declarantPlans,
    [OrganizationType.Operator]: operatorPlans,
    [OrganizationType.Consultant]: consultantPlans,
  };
  const possibleUnits = {
    [OrganizationType.Declarant]: "shipments",
    [OrganizationType.Operator]: "installations",
    [OrganizationType.Consultant]: "clients",
  };
  const unitName = possibleUnits[selectedUserType];
  const plans = possiblePlans[selectedUserType];
  const getStep = () => {
    if (showCheckout) {
      return (
        <CheckoutForm
          priceId={selectedPriceId}
          quantity={units}
          onClose={() => setShowCheckout(false)}
        />
      );
    }
    if (selectedUserType) {
      return (
        <div className="space-y-8">
          <div className="p-8 justify-center items-center">
            {selectedUserType == OrganizationType.Operator && (
              <PriceSlider
                value={units}
                onChange={handleUnitChange}
                max={25}
                unitName={unitName}
              />
            )}
            <div className="flex justify-center gap-8">
              {plans.map((plan, index) => (
                <PricingTable
                  key={index}
                  {...plan}
                  units={units}
                  onSubscribe={() => {
                    if (plan.buttonText == "Contact Us") {
                      window.open(
                        "https://calendly.com/victor-e-0/product-demo",
                        "_blank"
                      );
                    } else {
                      let priceId;
                      if(process.env.REACT_APP_ENV_MODE == "PROD"){
                        priceId = "price_1QcRvmRwsLdKL2q4yuD3WbQR";
                      }
                      else {
                        priceId = "price_1QSIqNRwsLdKL2q4UJQo1AN7";
                      }
                      handleSubscribe(priceId);
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <button
            onClick={() => setSelectedUserType(null)}
            className="block mx-auto bg-[#00b894] text-white py-2 px-4 rounded hover:bg-[#00a583] transition-colors"
          >
            Back to Selection
          </button>
        </div>
      );
    } else {
      return (
        <div className="space-y-8">
          <h2 className="text-3xl font-bold text-secondaryColor text-center">
            Choose Your Company Type
          </h2>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-8">
            <UserTypeButton
              title="Declarant"
              icon={faFile}
              description={"Do you import CBAM goods?"}
              onClick={() => {
                setSelectedUserType(OrganizationType.Declarant);
              }}
            />
            <UserTypeButton
              title="Operator"
              icon={faIndustry}
              description={"Do you produce and/or sell CBAM goods?"}
              onClick={() => {
                setSelectedUserType(OrganizationType.Operator);
                setUnits(1);
              }}
            />
            <UserTypeButton
              title="Consultant"
              icon={faHandsHelping}
              description={"Do you assist CBAM-affected businesses?"}
              onClick={() => {
                setSelectedUserType(OrganizationType.Consultant);
              }}
            />
          </div>
        </div>
      );
    }
  };
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-4 px-[200px]">
      <div className="w-full mt-4 space-y-8">{getStep()}</div>
    </div>
  );
};

export default PaymentPage;
