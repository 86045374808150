import { useOrganization, useOrganizationList } from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import CgForm from "../Common/Form/CgForm";
import { ConsultantOperatorInfoFormConfig } from "../../FormConfigs/ConsultantOperatorInfoFormConfig";
import { OperatorInfoService } from "./OperatorInfoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAsync } from "react-select/async";

export default function OperatorInfoForm({ onSubmit }) {
  const history = useHistory();
  const formRef = useRef();
  const formGroups = ConsultantOperatorInfoFormConfig;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [operator, setOperator] = useState(null);

  const { isLoaded, organization } = useOrganization();

  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: {
      infinite: true
    }
  });

  const [existingOperator, setExistingOperator] = useState(false);
  const [awaitOrganizatoinLoading, setAwaitOrganizationLoading] = useState(false);
  const [isOrganizationLoaded, setIsOrganizationLoaded] = useState(false);


  useEffect(() => {
    if(awaitOrganizatoinLoading && isOrganizationLoaded){
      history.push("/connections/invites");
    }
  }, [isOrganizationLoaded]);

  useEffect(() => {
    const setOrg = async () => {
      if (!organization) {
        let userOrgs = userMemberships?.data;
        if (userOrgs?.length > 0) {
          await setActive({
            organization: userOrgs[0].organization.id,
          });
          setIsOrganizationLoaded(true);
        }
      }
    };
    setOrg();
  }, [userMemberships]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let res = await OperatorInfoService.get();
      if (!res.success && organization) {
        let orgAddr = {
          additionalLine: "",
          city: "",
          country: "",
          POBox: "",
          postcode: "",
          state: "",
          street: "",
          number: "",
        };

        let newOperator = {
          name: organization.name,
          address: orgAddr,
        };
        setFormFromOperator(newOperator);
        setOperator(newOperator);
        setLoading(false);
        return;
      }
      setLoading(false);

      if (!res?.success) return;
      if (!formRef.current) return;
      setExistingOperator(true);
      let op = res.success;
      setFormFromOperator(op);
      setOperator(op);
    };

    init();
  }, [isLoaded]);

  const getOperatorInfoFormData = (formData) => {
    const newAddr = {
      ...operator?.address,
      additionalLine: formData.additionalLine,
      city: formData.city,
      country: formData.country,
      POBox: formData.poBox,
      postcode: formData.postcode,
      state: formData.state,
      street: formData.street,
      number: formData.streetNumber,
    };

    const newOperator = {
      ...operator,
      name: formData.operatorName,
      address: newAddr,
      contactName: formData.contactName,
      contactEmail: formData.contactEmail,
      phoneNumber: formData.phoneNumber,
    };

    setOperator(newOperator);

    return newOperator;
  };


  const handleSubmit = async (formData) => {
    let newOperator = getOperatorInfoFormData(formData);

    setSubmitError(null);
    setSaving(true);
    let res;
    if (existingOperator) {
      res = await OperatorInfoService.update(newOperator);
    } else {
      res = await OperatorInfoService.create(newOperator);
      if(res.data.newOrganization){
        await userMemberships.revalidate();
        setAwaitOrganizationLoading(true);
      }
    }
    setSaving(false);
    if (["OK", "Created"].includes(res.statusText)) {
      formRef.current.showSubmitSuccess();
      if (onSubmit) {
        onSubmit(true);
      }
    }
    if (res.error) {
      setSubmitError(res.error.response.data.error);
    }
  };

  const setFormFromOperator = (op) => {
    const updateFormValue = (name, value) => {
      formRef.current.updateField(name, value);
    };

    updateFormValue("operatorName", op.name);
    updateFormValue("country", op.address.country);
    updateFormValue("city", op.address.city);
    updateFormValue("phoneNumber", op.phoneNumber);
    updateFormValue("contactName", op.contactName);
    updateFormValue("contactEmail", op.contactEmail);
  };

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return formGroups;
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <CgForm
        ref={formRef}
        formGroups={getFormGroups()}
        disabled={isFormDisabled()}
        submitting={isFormSubmitting()}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      />
    );
  };

  return (
    <div className="flex flex-column gap-2 mt-1">
      <h1>Operator Information {getLoadingSpinner()}</h1>
      {getSubmitErrorMessage()}
      {getForm()}
    </div>
  );
}
