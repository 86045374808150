import {
  getConnectedEdges,
  Handle,
  Position,
  useReactFlow,
} from "@xyflow/react";
import {
  Dropdown,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Badge,
} from "react-bootstrap";

import {
  BorderColors,
  FlowType,
  GraphColors,
  NodeType,
  NodeUnits,
  abbreviateNumber,
  calculateEmissions,
  calculatePFC,
  cascadeEmissionUpdates,
  commaSeparatedNumber,
  emissionUnit,
  externalTypes,
  internalTypes,
  nonEditableTypes,
  processNodes,
  validForm,
} from "./utils";
import Decimal from "decimal.js";
import { useState, useEffect } from "react";
import AddInputModal from "../AddInputModal";
import AddProcessModal from "../AddProcessModal";
import AddInternalInputModal from "../AddInternalInputModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { clearMemo } from "./memoTable";
import { useAllCountries } from "../../Common/Utility";
import { AlertType, notify } from "../../Common/Form/CgAlertMessage";

export function CustomNode({ data, id, onContextMenu }) {
  let {
    getNode,
    setNodes,
    getEdges,
    updateNode,
    updateEdgeData,
    updateNodeData,
    deleteElements,
    screenToFlowPosition,
  } = useReactFlow();
  let {
    inputType,
    aggregatedGoodsCategory,
    quantity,
    emissions,
    unit,
    direct,
    indirect,
    name,
    pfcEmissions,
    namePrefix,
  } = data;
  const countries = useAllCountries();
  let units = NodeUnits[inputType];
  const location = useLocation();
  const [installationId, setInstallationId] = useState(
    location.state?.installationId ?? null
  );
  let bg = GraphColors[inputType] ?? "bg-primary";
  let textColor = ["Self Electricity", "Electricity"].includes(inputType)
    ? "text-black"
    : "text-white";
  let maxDigits = 4;

  let calcQuantity = quantity;
  if (!quantity || quantity == 0) {
    calcQuantity = 1;
  }
  let directSEE = new Decimal(direct ?? 0).div(calcQuantity ?? 1);

  let indirectSEE = new Decimal(indirect ?? 0).div(calcQuantity ?? 1);
  let totalSEE = directSEE.plus(indirectSEE).toFixed(maxDigits);
  directSEE = directSEE.toFixed(maxDigits);
  indirectSEE = indirectSEE.toFixed(maxDigits);
  
  
  const getLabel = () => {
    let { inputType, name, namePrefix, label, cbamGood } = data;
    if (label) {
      return label;
    }
    return inputType;
  };
  const getName = () => {
    if (data.inputType == NodeType.ElectricityProcess) {
      return "Self-Generated";
    }
    if (data.inputType == NodeType.ProducedCBAMGood) {
      const goodsSet = new Set(
        data.goods
          ?.map((item) => item.productName)
          .filter((name) => name != "") || []
      );
      return Array.from(goodsSet).join(", ");
    }
    return name;
  };
  let rightColumnWeight = "font-light";

  const renderCountryFlag = (data) => {
    if (
      data.inputType == NodeType.CBAMGood &&
      data.country &&
      data.sourceSupplier
    ) {
      let country = countries.find(
        (country) => `${country._id}` == data.country
      );
      return (
        <div className="flex justify-between items-center">
          <div>Supplier</div>
          <div className="flex justify-center items-center">
            <span
              className={`fi fi-${country?.iso2?.toLowerCase()} h-[23px] w-[30px] mr-2 border border-white`}
            ></span>
            <span className="text-sm">{data.sourceSupplier}</span>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderEmissionsIndicator = () => {
    if (
      data.inputType === NodeType.CBAMGood &&
      data.useDefault != null &&
      data.useDefault != undefined
    ) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Popover id={`popover-emissions-type-${id}`}>
              <Popover.Body>
                {data.useDefault
                  ? "Using default emissions data"
                  : "Using actual emissions data"}
              </Popover.Body>
            </Popover>
          }
        >
          <Badge
            bg={data.useDefault ? "danger" : "primary"}
            style={{
              position: "absolute",
              top: "10px",
              left: "14px",
              fontSize: "10px",
              cursor: "help",
            }}
          >
            {data.useDefault ? "Default" : "Actual"}
          </Badge>
        </OverlayTrigger>
      );
    }
    return null;
  };
  const getPrecursorEmissions = (processNode) => {
    const getDefaultEmissions = (processNode) => {
      let connectedNodes = getConnectedEdges([processNode], getEdges())
        .filter((edge) => edge.target == processNode.id)
        .map((edge) => {
          return {
            id: edge.source,
            data: getNode(edge.source).data,
            quantity: edge.data.quantity,
          };
        });
      let precursorNodes = connectedNodes.filter(
        (node) => node.data.inputType == NodeType.CBAMGood
      );
      let defaultPrecursorNodes = precursorNodes.filter(
        (node) => node.data.useDefault
      );
      let totalDefaultPrecursorEmissions = defaultPrecursorNodes.reduce(
        (acc, node) => {
          let emissions = new Decimal(node.data.direct).plus(
            new Decimal(node.data.indirect)
          );
          let edgeQuantity = new Decimal(node.quantity);
          let totalQuantity = new Decimal(node.data.quantity);
          let ratio = edgeQuantity.div(totalQuantity);
          return acc.plus(emissions.mul(ratio));
        },
        new Decimal(0)
      );
      let processNodes = connectedNodes.filter(
        (node) =>
          node.data.inputType == NodeType.CBAMProcess ||
          node.data.inputType == NodeType.SubProcess
      );
      return totalDefaultPrecursorEmissions.plus(
        processNodes.reduce((acc, node) => {
          let edgeQuantity = new Decimal(node.quantity);
          let totalQuantity = new Decimal(node.data.quantity);
          let ratio = edgeQuantity.div(totalQuantity);
          return acc.plus(new Decimal(getDefaultEmissions(node)).mul(ratio));
        }, new Decimal(0))
      );
    };
    if (
      processNode.data.inputType == NodeType.CBAMProcess ||
      processNode.data.inputType == NodeType.SubProcess
    ) {
      let calc = getDefaultEmissions(processNode);
      return calc;
    } else {
      return new Decimal(0);
    }
  };
  const calculateShareOfDefault = () => {
    let productionEdge = getEdges().filter((edge) => edge.target == id);
    if (!productionEdge.length) {
      return new Decimal(0);
    }
    let processNode = getNode(productionEdge[0].source);
    let totalEmissions = new Decimal(processNode.data.direct ?? 0).plus(
      new Decimal(processNode.data.indirect ?? 0)
    );
    let totalPrecursorEmissions = getPrecursorEmissions(processNode);
    if (totalEmissions == 0) {
      return new Decimal("0");
    }
    return totalPrecursorEmissions.div(totalEmissions).mul(100);
  };

  const cbamNodes = [
    NodeType.CBAMProcess,
    NodeType.ProducedCBAMGood,
    NodeType.CBAMGood,
    NodeType.SubProcess,
  ];
  const goodNodes = [NodeType.ProducedCBAMGood, NodeType.CBAMGood];
  let defaultEmissionsShare = calculateShareOfDefault();
  let isHigher = defaultEmissionsShare.greaterThan(new Decimal(20));
  return (
    <div>
      <Handle
        id="a"
        className="p-2"
        type="source"
        position={Position.Left}
        //id="a"
        style={{ border: "0px" }}
      />
      {["Production Process", "Non CBAM Process"].includes(inputType) && (
        <Handle
          className="p-2"
          type="source"
          position={Position.Bottom}
          style={{ border: "0px" }}
          id="c"
        />
      )}
      {["Production Process", "Non CBAM Process"].includes(inputType) && (
        <Handle
          className="p-2"
          type="source"
          position={Position.Top}
          id="d"
          style={{ border: "0px" }}
        />
      )}
      <Container
        onContextMenu={(event) => {
          event.preventDefault();
          // update all other nodes to be selected: false
          setNodes((nodes) => {
            return nodes.map((node) => {
              if (node.id !== id) {
                return { ...node, selected: false };
              }
              return node;
            });
          })
          updateNode(id, { data: data, selected: true });
          event.stopPropagation();
          onContextMenu(event)
        }}
        fluid
        className={`${textColor} ${bg} p-6 shadow-md`}
        style={{
          borderRadius: "20px",
          border: `3px solid ${BorderColors[inputType]}`,
          width: processNodes.includes(inputType) ? "25vw" : "20vw",
        }}
      >
        <Col className="flex-col pb-4 text-center">
          {renderEmissionsIndicator()}
          <p style={{ fontWeight: "400" }}>{getLabel()}</p>
          <p style={{ fontWeight: "600" }}>{getName()}</p>
        </Col>
        <Row>
          <Col>
            {inputType == NodeType.CBAMProcess && (
              <div className="flex justify-between items-center">
                <p>CN Good Category</p>
                <p className={`text-right ${rightColumnWeight}`}>
                  {aggregatedGoodsCategory}
                </p>
              </div>
            )}
            {inputType == NodeType.CBAMProcess && (
              <div className="flex justify-between items-center">
                <p>Method</p>
                <p className={`text-right ${rightColumnWeight}`}>
                  {namePrefix}
                </p>
              </div>
            )}
            {renderCountryFlag(data)}
            {(quantity > 0 || cbamNodes.includes(inputType)) && (
              <OverlayTrigger
                key={`overlay-quantity-${id}`}
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                  <Popover id={`Popover-quantity-${id}`}>
                    <div className="p-3">
                      {commaSeparatedNumber(quantity)} {units[unit] ?? unit}
                    </div>
                  </Popover>
                }
                rootClose={true}
              >
                <div className="flex w-full justify-between">
                  <p>
                    {cbamNodes.includes(inputType) ? "Net Mass" : "Quantity"}
                  </p>
                  <p className={`text-right ${rightColumnWeight}`}>
                    {abbreviateNumber(quantity)} {units[unit] ?? unit}
                  </p>
                </div>
              </OverlayTrigger>
            )}
            {inputType == NodeType.ProducedCBAMGood && (
              <OverlayTrigger
                key={`overlay-cncodes-${id}`}
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                  <Popover id={`Popover-cncodes-${id}`}>
                    <div className="p-3">
                      {data.goods
                        .map((good) => {
                          return good.cnCode;
                        })
                        .join(", ")}
                    </div>
                  </Popover>
                }
                rootClose={true}
              >
                <div className="flex w-full justify-between">
                  <p>CN Codes</p>
                  <p className={`text-right ${rightColumnWeight}`}>
                    {data.cbamGood.length}
                  </p>
                </div>
              </OverlayTrigger>
            )}
            {inputType == NodeType.ProducedCBAMGood && (
              <div className="flex w-full justify-between">
                <p>Share of default data</p>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id={`popover-emissions-defaultIsHigher-${id}`}>
                      <Popover.Body>
                        {isHigher
                          ? "Share of default emissions is higher than 20%. Note that this exceeds the permitted limit under the EU CBAM regulation. Please contact your suppliers to collect actual data."
                          : "Share of default emissions is within the permitted 0-20% range."}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Badge
                    className={`${rightColumnWeight}`}
                    bg={isHigher ? "danger" : "primary"}
                    style={{
                      cursor: "help",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <p>{defaultEmissionsShare.toFixed(2)}%</p>
                  </Badge>
                </OverlayTrigger>
              </div>
            )}
            {cbamNodes.includes(inputType) && (
              <div className="w-full ">
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#ccc",
                    margin: "1rem 0",
                  }}
                />
                <div className="flex w-full justify-between">
                  <p>Direct</p>
                  <p>Indirect</p>
                </div>
                <div className="flex w-full justify-between">
                  <OverlayTrigger
                    key={`overlay-direct-${id}`}
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={
                      <Popover id={`Popover-direct-${id}`}>
                        <div className="p-3">
                          {commaSeparatedNumber(direct ?? 0)} {emissionUnit}
                        </div>
                      </Popover>
                    }
                    rootClose={true}
                  >
                    <p className={`font-thin`}>
                      {abbreviateNumber(direct ?? 0)} {emissionUnit}
                    </p>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={`overlay-indirect-${id}`}
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={
                      <Popover id={`Popover-indirect-${id}`}>
                        <div className="p-3">
                          {commaSeparatedNumber(indirect ?? 0)} {emissionUnit}
                        </div>
                      </Popover>
                    }
                    rootClose={true}
                  >
                    <p className={`font-thin`}>
                      {abbreviateNumber(indirect ?? 0)} {emissionUnit}
                    </p>
                  </OverlayTrigger>
                </div>
                {goodNodes.includes(inputType) && (
                  <div className="flex w-full justify-between">
                    <p className={`font-thin`}>
                      {directSEE} {emissionUnit}/t
                    </p>
                    <p className={`font-thin`}>
                      {indirectSEE} {emissionUnit}/t
                    </p>
                  </div>
                )}
              </div>
            )}

            {!cbamNodes.includes(inputType) && emissions > 0 && (
              <OverlayTrigger
                key={`overlay-emissions-${id}`}
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                  <Popover id={`Popover-emissions-${id}`}>
                    <div className="p-3">
                      {commaSeparatedNumber(emissions)} {emissionUnit}
                    </div>
                  </Popover>
                }
                rootClose={true}
              >
                <div className="flex w-full justify-between">
                  <p>Total</p>
                  <p className={`text-right ${rightColumnWeight}`}>
                    {abbreviateNumber(emissions)} {emissionUnit}
                  </p>
                </div>
              </OverlayTrigger>
            )}
            {NodeType.ElectricityProcess == inputType && (
              <div className="flex w-full justify-between">
                <p>EF</p>
                <p className={`text-right ${rightColumnWeight}`}>
                  {totalSEE} {emissionUnit}/MWh
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Handle
        className="p-2"
        type="source"
        position={Position.Right}
        id="b"
        style={{ border: "0px" }}
      />
    </div>
  );
}
