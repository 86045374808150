import React from "react";
import CgCountrySelect from "./CgCountrySelect";
import CgInput from "./CgInput";
import CgDropdown from "./CgDropdown";
import { CountryDropdown, FormControlTypes } from "../Utility";
import CgGoodSelect from "./CgGoodSelect";
import CgSelectDropdown from "./CgSelectDropdown";
import { OverlayTrigger, Popover } from "react-bootstrap";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CgPhoneNumber } from "./CgPhoneNumber";
import CgCheckbox from "./CgCheckbox";
import CgOODropdown from "./CgOODropdown";

const CgFormGroup = ({
  groupName,
  className,
  controls,
  loading,
  errors,
  handleChange,
  formData,
  addNewControl,
  shownForm
}) => {
  const getFormControl = (formControl) => {
    const value = formData[formControl.name] || "";
    const commonProps = {
      key: `FORMCONTROL-${formControl.name}`,
      formControl: { ...formControl, value },
      formData: formData,
      loading,
      error: errors[formControl.name],
      disabled: formControl.disabled,
      shownForm: shownForm,
      jsonBodyKey: formControl.jsonBodyKey,
      jsonBodyValue: formControl.jsonBodyValue,
      onChange: (e) => handleChange(formControl.name, e.target.value, e),
    };

    switch (formControl.type) {
      case FormControlTypes.Dropdown:
        return <CgDropdown {...commonProps} />;
      case FormControlTypes.DropdownSelect:
        return <CgSelectDropdown {...commonProps} />;
      case FormControlTypes.OODropdown:
        return <CgOODropdown {...commonProps}  />;
      case FormControlTypes.EUDropdown:
        return <CgCountrySelect {...commonProps} countryType="eu" />;
      case FormControlTypes.ALLDropdown:
        return <CgCountrySelect {...commonProps} countryType="all" />;
      case FormControlTypes.CBAMDropdown:
        return <CgCountrySelect {...commonProps} countryType="cbam" />;
      case FormControlTypes.GoodsDropdown:
        return <CgGoodSelect {...commonProps} />;
      case FormControlTypes.Trigger:
        return getOverlayTrigger(formControl);
      case FormControlTypes.Phone:
        return <CgPhoneNumber {...commonProps} />;
      case FormControlTypes.Checkbox:
        return <CgCheckbox {...commonProps} />;
      case FormControlTypes.Hidden:
        return <></> 
      default:
        return <CgInput {...commonProps} />;
    }
  };

  const getOverlayTrigger = (formControl) => {
    return (
      <div className="flex col-1 items-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Popover className="z-[100160]" id="Popover-basic">
              <div className="p-3">{formControl.text}</div>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faIcons.faQuestionCircle} color="#6C757D" />
        </OverlayTrigger>
      </div>
    );
  };

  const getFormControls = () => {
    return controls?.map(getFormControl);
  };

  return (
    <div className={`my-6 ${className}`}>
      <h3>{groupName}</h3>
      <div className="row">{getFormControls()}</div>
    </div>
  );
};

export default CgFormGroup;
