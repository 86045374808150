import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";
import { getEmissionsUnit, getNcvUnit } from "../../Nodes/utils";
import { MaterialUnits, molarMassConstant } from "../../Nodes/utils";

let emissionUnits = [
  { code: "01", description: "tCO2/tonne" },
  { code: "02", description: "tCO2/TJ" },
  { code: "04", description: "tCO2/m3" },
];

let ncvUnits = [
  { code: "02", description: "TJ/tonne" },
  { code: "01", description: "TJ/tonne" },
  { code: "04", description: "TJ/m3" },
];

let balanceControls = [
  {
    label: "Carbon Content",
    name: "carbonContent",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        if (num > 1 || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Must be between 0-1",
          });
        }
        return arg;
      })
      .or(z.literal("")),
  },
  {
    label: "Molar Mass Ratio Constant",
    name: "molarConstant",
    className: "col-6",
    defaultValue: molarMassConstant,
    type: FormControlTypes.Text,
    disabled: true,
  },
  {
    label: "Net Calorific Value (NCV)",
    placeholder: "",
    name: "ncv",
    dependencies: ["name", "unit"],
    fetchValue: async (fieldName, fieldValue, formData) => {
      if (formData.name != "" && formData.unit != "") {
        let ncvUnit = getNcvUnit(formData.unit);
        let res2 = await http.get(
          `/emissions/standard/factors?installationId=${formData.installationId}&resourceName=${formData.name}&ncvUnit=${ncvUnit}`
        );
        return res2.data?.ncv ?? "";
      }
      return "";
    },
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },

  {
    label: "Unit",
    placeholder: "Select unit",
    name: "ncvUnit",
    dependency: "unit",
    disabled: true,
    className: "col-5",
    type: FormControlTypes.Dropdown,
    fetchValue: async (unit, currentValue, formData) => {
      return unit;
    },
    options: ncvUnits.map((unit) => {
      return { name: unit.description, value: unit.code };
    }),
    optionLabel: "name",
    optionValue: "value",
    hideEmptyOption: false,
    validation: null,
  },
  {
    type: FormControlTypes.Trigger,
    text: "Standard factors are provided by default. You can overwrite them with your own values. You should contact your suppliers to obtain the specific values for each fuel.",
  },
  {
    label: "Biomass Fraction (between 0-100%)",
    placeholder: "",
    name: "biomass",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        if (num > 100 || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Must be between 0-100",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },
];
let standardControls = [
  {
    label: "Net Calorific Value (NCV)",
    placeholder: "",
    name: "ncv",
    dependencies: ["name", "unit"],
    fetchValue: async (fieldName, fieldValue, formData) => {
      if (formData.name != "" && formData.unit != "") {
        let ncvUnit = getNcvUnit(formData.unit);
        let res2 = await http.get(
          `/emissions/standard/factors?installationId=${formData.installationId}&resourceName=${formData.name}&ncvUnit=${ncvUnit}`
        );
        return res2.data?.ncv ?? "";
      }
      return "";
    },
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },

  {
    label: "Unit",
    placeholder: "Select unit",
    name: "ncvUnit",
    dependency: "unit",
    disabled: true,
    className: "col-5",
    type: FormControlTypes.Dropdown,
    fetchValue: async (value, currentValue, formData) => {
      return value;
    },
    options: ncvUnits.map((unit) => {
      return { name: unit.description, value: unit.code };
    }),
    optionLabel: "name",
    optionValue: "value",
    hideEmptyOption: false,
    validation: null,
  },
  {
    type: FormControlTypes.Trigger,
    text: "Standard factors are provided by default. You can overwrite them with your own values. You should contact your suppliers to obtain the specific values for each fuel.",
  },
  {
    label: "Emission Factor",
    placeholder: "",
    name: "emissionFactor",
    dependencies: ["name", "unit"],
    fetchValue: async (fieldName, fieldValue, formData) => {
      if (formData.name != "" && formData.unit != "") {
        let ncvUnit = getNcvUnit(formData.unit);
        let factorUnit = getEmissionsUnit(formData.unit);
        let res2 = await http.get(
          `/emissions/standard/factors?installationId=${formData.installationId}&resourceName=${formData.name}&ncvUnit=${ncvUnit}&factorUnit=${factorUnit}`
        );
        return res2.data?.factor ?? "";
      }
      return "";
    },
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Unit",
    placeholder: "Select unit",
    name: "calculationUnit",
    className: "col-5",
    type: FormControlTypes.Dropdown,
    dependencies: ["ncv", "unit"],
    disabled: true,
    fetchValue: async (fieldName, fieldValue, formData) => {
      if (formData.ncv && formData.ncv !== "") return "02";
      if (["01", "02"].includes(formData.unit)) return "01";
      if (formData.unit === "04") return "04";
      return "";
    },
    options: emissionUnits.map((unit) => {
      return { name: unit.description, value: unit.code };
    }),
    optionLabel: "name",
    optionValue: "value",
    hideEmptyOption: false,
    validation: null,
  },
  {
    type: FormControlTypes.Trigger,
    text: "Standard factors are provided by default. You can overwrite them with your own values. You should contact your suppliers to obtain the specific values for each fuel.",
  },
  {
    label: "Oxidation Factor (between 0-100%)",
    placeholder: "",
    name: "oxidationFactor",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        if (num > 100 || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Must be between 0-100",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },
  {
    label: "Biomass Fraction (between 0-100%)",
    placeholder: "",
    name: "biomass",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        if (num > 100 || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Must be between 0-100",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },
];

export const FuelFormConfig = [
  {
    header: "Fuel",
    name: "Fuel",
    className: "",
    controls: [
      {
        label: "Calculation Method",
        placeholder: "Select method",
        name: "method",
        className: "col-12",
        type: FormControlTypes.Dropdown,
        options: [
          {
            name: "Standard",
            value: "Standard",
          },
          {
            name: "Mass Balance",
            value: "Mass Balance",
          },
        ],
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: true,
      },
      {
        label: "Fuel Type",
        placeholder: "",
        name: "name",
        className: "col-4",
        creatable: true,
        type: FormControlTypes.DropdownSelect,
        fetchOptions: async () => {
          let res = await http.get(
            "/emissions/standard/resources?resourceType=fuel"
          );
          return res.data;
        },
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-4",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit",
        name: "unit",
        className: "col-4",
        type: FormControlTypes.Dropdown,
        options: Object.entries(MaterialUnits).map(([key, value]) => {
          return { name: value, value: key };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
    ],
  },
  {
    header: "Calculation Factors",
    name: "Calculation Factors",
    className: "",
    groupDependency: "method",
    controls: [...standardControls],
    possibleControls: {
      Standard: standardControls,
      "Mass Balance": balanceControls,
    },
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
