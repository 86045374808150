import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
} from "react";
import { DashboardLayout } from "../App/components/Layout";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  DropdownButton,
  Dropdown,
  Card,
  ProgressBar,
  Modal,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";

import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  addEdge,
  Panel,
  useReactFlow,
  getConnectedEdges,
  Controls,
} from "@xyflow/react";
import ContextMenu from "./ContextMenu";
import "@xyflow/react/dist/style.css";
import { CustomNode } from "./Nodes/CustomNode";
import { CustomEdge } from "./Nodes/CustomEdge";
import FlowInputForm from "./DataInputs/FlowInputForm";
import {
  FlowType,
  GraphColors,
  NodeType,
  calculateEmissions,
  calculatePFC,
  cascadeEmissionUpdates,
  electricityTypes,
  externalTypes,
  getEmissionsUnit,
  getNcvUnit,
  internalTypes,
  nonEditableTypes,
  validConnections,
  validPrecursors,
} from "./Nodes/utils";
import Decimal from "decimal.js";
import AddInputModal from "./AddInputModal";
import AddProcessModal from "./AddProcessModal";
import http from "../../helpers/requests";
import ReportingPeriodModal from "./ReportingPeriodModal";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import AddInternalInputModal from "./AddInternalInputModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertType, notify } from "../Common/Form/CgAlertMessage";
import cloneDeep from "lodash/cloneDeep";
import AddNodeDropdown from "./AddNodeDropdown";
import { clearMemo, getMemo, hasMemo, setMemo } from "./Nodes/memoTable";
import CgButton from "../Common/CgButton";
import { CgToggle } from "../Common/CgToggle/CgToggle";
import Joyride from "react-joyride";
import kickOffJourney from "./JoyRide/Configuration/kickOffJourney";
import StepsConnectingEdges from "./JoyRide/Configuration/stepsConnectingEdges";
import { useUser } from "@clerk/clerk-react";
import TemplatesModal from "./TemplatesModal";
import { TutorialContext } from "./JoyRide/Utilities/TutorialContext";

const defaultNodes = [];
const defaultEdges = [];
// custom hook to use stacks
function useStack() {
  const [stack, setStack] = useState([]);

  function push(newState) {
    if (stack.length < 10) {
      setStack((prev) => [newState, ...prev]);
    } else {
      setStack((prev) => [
        newState,
        ...prev.filter((x, idx) => idx !== stack.length - 1),
      ]);
    }
  }

  function pop() {
    if (stack.length > 0) {
      let currState = stack[0];
      setStack((prev) => [...prev.filter((x, idx) => idx !== 0)]);
      return currState;
    }
    return null;
  }

  return [stack, push, pop];
}

export default function GraphFlow() {
  const {
    getNode,
    getViewport,
    setViewport,
    addNodes,
    updateEdgeData,
    updateNodeData,
    getEdges,
    screenToFlowPosition,
    deleteElements,
  } = useReactFlow();
  let { id } = useParams();
  let history = useHistory();
  // fetch the installationId from the history's state
  const location = useLocation();
  const { user } = useUser();
  const [installationId, setInstallationId] = useState(
    localStorage.getItem("installationId") ?? null
  );
  const [installationName, setInstallationName] = useState();
  const [undoStack, pushUndo, popUndo] = useStack();
  const [redoStack, pushRedo, popRedo] = useStack();
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);
  const [copiedNode, setCopiedNode] = useState(null);
  const [pasteOffset, setPasteOffset] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);
  const [maxNodes, setMaxNodes] = useState(1);
  const [reportingPeriod, setReportingPeriod] = useState(null);
  const [isFinal, setIsFinal] = useState(false);
  const [currentJourneyIndex, setCurrentJourneyIndex] = useState(0);
  const [isDiagramUnsaved, setIsDiagramUnsaved] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isPlayTutorialAlert, setIsPlayTutorialAlert] = useState(false); // To track if the user confirmed the "Play Tutorial"
  const [addInput, setAddInput] = useState(false);
  const [inputType, setInputType] = useState(null);
  const [hasFired, setHasFired] = useState(false);
  const [addProcess, setAddProcess] = useState(false);
  const [addInternal, setAddInternal] = useState(false);
  const [addPeriod, setAddPeriod] = useState(
    location.pathname === "/new-diagram"
  );
  const [showTemplates, setShowTemplates] = useState(false);
  const [showExternalInputs, setShowExternalInputs] = useState(false);
  const [showInternalInputs, setShowInternalInputs] = useState(false);
  const [sourceNode, setSourceNode] = useState(null);
  const [targetNode, setTargetNode] = useState(null);
  const [connectingEdgesPanel, setConnectingEdgesPanel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [panel, showPanel] = useState(false);
  const [resolveInput, setResolveInput] = useState(null);
  const [nodeContextMenu, setNodeContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 },
  });
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 },
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [isCascading, setIsCascading] = useState(false);
  const {
    run,
    setRun,
    steps,
    setSteps,
    tutorialRunFlag,
    setTutorialRunFlag,
    miniTutorialRun,
    setMiniTutorialRun,
  } = useContext(TutorialContext);
  const nodeTypes = {
    customNode: (props) => (
      <CustomNode
        {...props}
        onContextMenu={(event) => {
          setNodeContextMenu({
            show: true,
            position: { x: event.clientX, y: event.clientY },
          });
        }}
      />
    ),
  };
  const edgeTypes = {
    "custom-edge": (props) => (
      <CustomEdge
        {...props}
        pushUndo={() => {
          pushUndoCurr();
        }}
      />
    ),
  };
  const onInit = useCallback((reactFlowInstance) => {
    // Set the default zoom and initial position
    reactFlowInstance.setViewport({ x: 0, y: 0, zoom: 0.5 }); // Adjust zoom as desired
  }, []);

  useEffect(() => {
    const sessionFlag = localStorage.getItem("playTutorial");

    if (sessionFlag === "true") {
      // If this is a programmatic refresh, allow the tutorial to play
      setTutorialRunFlag(true);
    } else {
      // Otherwise, handle it as a manual refresh
      if (
        !user.publicMetadata.tutorialDone ||
        localStorage.getItem("restartTutorial") === "true"
      ) {
        setTutorialRunFlag(true);
      } else {
        // Reset metadata and localStorage on manual refresh
        localStorage.removeItem("restartTutorial");
      }
    }
  }, [user]);

  useEffect(() => {
    if (tutorialRunFlag) {
      setRun(true);
      localStorage.removeItem("playTutorial"); // Clear the flag after use
    }
  }, [addInput, addProcess, addPeriod, steps]);

  useEffect(() => {
    // Load initial step or proceed to next journey step
    loadJourneyStep(currentJourneyIndex);
  }, [currentJourneyIndex]);

  // Function to handle journey step loading
  const loadJourneyStep = (index) => {
    const journeyStep = kickOffJourney[index];
    if (!journeyStep) {
      setRun(false);
      setTutorialRunFlag(false);

      localStorage.removeItem("restartTutorial");
      return; // end of journey
    }

    if (journeyStep?.stepName === "Connecting Edges") {
      setConnectingEdgesPanel(true);
    }

    if (journeyStep.steps) {
      // Filler steps
      setSteps(journeyStep.steps);
      //setRun(true);
    }
  };

  const handleNextStep = () => {
    if (tutorialRunFlag) {
      setCurrentJourneyIndex((prev) => prev + 1);
    }
  };

  const handlePreviousStep = () => {
    if (tutorialRunFlag) {
      setCurrentJourneyIndex((prev) => prev - 1);
    }
  };

  const handleQuitTutorial = () => {
    setTutorialRunFlag(false); // Stop the Joyride tutorial
    localStorage.removeItem("restartTutorial");
  };

  const playTutorial = () => {
    setIsPlayTutorialAlert(true);
    if (isDiagramUnsaved) {
      setShowConfirmModal(true);
    } else {
      localStorage.setItem("playTutorial", "true");
      localStorage.setItem("installationId", installationId);
      window.location.href = "/new-diagram";
    }
  };

  const pushUndoCurr = () => {
    if (isCascading) return;
    pushUndo({
      nodes: cloneDeep(nodes),
      edges: cloneDeep(edges),
      maxNodes: maxNodes,
    });
  };

  useEffect(() => {
    setInitialLoad(false);
  }, [nodes, edges]);

  // react flow passes an array of changes to the onNodeChange function
  // we only want to push the current state into the undo stack if the node is edited or created
  // this is denoted by a nodeChange type of replace or add respectively.
  const pushUndoOnNodeChange = (nodeChanges) => {
    if (isCascading) return;
    let needsUndo = false;
    nodeChanges.forEach((nodeChange) => {
      if (nodeChange.type === "replace" || nodeChange.type === "add") {
        needsUndo = true;
      }
    });
    if (needsUndo) pushUndoCurr();
  };

  const pushUndoOnEdgeChange = (nodeChanges) => {
    if (isCascading) return;
    let needsUndo = false;
    nodeChanges.forEach((nodeChange) => {
      if (nodeChange.type === "replace") {
        needsUndo = true;
      }
    });
    if (needsUndo) pushUndoCurr();
  };

  const performUndo = () => {
    let currState = popUndo();
    if (currState !== null) {
      pushRedo({
        nodes: cloneDeep(nodes),
        edges: cloneDeep(edges),
        maxNodes: maxNodes,
      });
      setNodes(currState.nodes);
      setEdges(currState.edges);
      setMaxNodes(currState.maxNodes);
    }
  };

  const performRedo = () => {
    let currState = popRedo();
    if (currState !== null) {
      pushUndo({
        nodes: cloneDeep(nodes),
        edges: cloneDeep(edges),
        maxNodes: maxNodes,
      });
      setNodes(currState.nodes);
      setEdges(currState.edges);
      setMaxNodes(currState.maxNodes);
    }
  };

  function handleKeyDown(event) {
    if (event.ctrlKey) {
      if (event.shiftKey && (event.key === "z" || event.key === "Z")) {
        // performRedo();
      } else if (event.key === "z" || event.key === "Z") {
        // performUndo();
      }
    }
  }

  const handleCopy = () => {
    if (selectedNode) {
      setCopiedNode({ ...selectedNode, id: `${selectedNode.id}-copy` });
      setPasteOffset(0);
    }
  };
  
  const handlePaste = () => {
    if (copiedNode) {
      const offsetIncrement = 50;
      const newOffset = pasteOffset + offsetIncrement;
  
      const newNode = {
        ...copiedNode,
        id: `${copiedNode.id}-${Date.now()}`,
        position: {
          x: copiedNode.position.x + newOffset,
          y: copiedNode.position.y + newOffset,
        },
        selected: false,
      };
  
      setNodes((nds) =>
        nds
          .map((node) => ({ ...node, selected: false }))
          .concat({ ...newNode, selected: true })
      );
      setPasteOffset(newOffset);
    }
  };
  
  const handlePasteContextMenu = () => {
    if (copiedNode) {
      const newNode = {
        ...copiedNode,
        id: `${copiedNode.id}-${Date.now()}`,
        position: getNodePosition(),
        selected: false,
      };
  
      setNodes((nds) =>
        nds
          .map((node) => ({ ...node, selected: false }))
          .concat({ ...newNode, selected: true })
      );
    }
  };
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if there's an active modal
      const modalIsOpen = document.querySelector('.modal.show');
      
      if (modalIsOpen) {
        // If a modal is open, block the copy/paste functionality
        return;
      }
  
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "c") {
          handleCopy();
        } else if (event.key === "v") {
          handlePaste();
        }
      }
    };
  
    document.addEventListener("keydown", handleKeyPress);
  
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [copiedNode, pasteOffset, nodes]);
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pushUndo, popUndo]);

  // Function to handle navigation attempt
  const handleNavigationAttempt = (location) => {
    if (isPlayTutorialAlert) {
      return true;
    }
    if (isDiagramUnsaved) {
      setShowConfirmModal(true); // Show your custom confirmation modal
      setPendingNavigation(location); // Store the desired navigation path
      return false; // Block the navigation
    }
    return true; // Allow navigation if no unsaved changes
  };

  // Function to confirm leaving and navigate
  const handleConfirmLeave = () => {
    setShowConfirmModal(false); // Close the modal
    if (isPlayTutorialAlert) {
      localStorage.setItem("playTutorial", "true");
      localStorage.setItem("installationId", installationId);
      window.location.href = "/new-diagram";
    } else if (pendingNavigation) {
      unblock(); // Unblock the history
      history.push(pendingNavigation.pathname); // Navigate to the pending location
      if (isPlayTutorialAlert) {
        localStorage.setItem("playTutorial", "true");
      }
      window.location.reload();
    }
  };

  // Function to cancel the navigation
  const handleCancelLeave = () => {
    setShowConfirmModal(false); // Close the modal
    setPendingNavigation(null); // Clear pending navigation
    if (isPlayTutorialAlert) {
      setIsPlayTutorialAlert(false);
    }
  };

  // Block navigation when there are unsaved changes
  const unblock = history.block((location) => {
    if (isPlayTutorialAlert) {
      return;
    }
    if (handleNavigationAttempt(location)) {
      return; // Allow navigation if no unsaved changes
    }
    return false; // Block navigation if there are unsaved changes
  });

  // Handle browser refresh or close
  useEffect(() => {
    if (isPlayTutorialAlert) {
      return;
    }
    const handleBeforeUnload = (event) => {
      if (isDiagramUnsaved) {
        event.preventDefault();
        event.returnValue = ""; // Required for modern browsers to show the prompt
      }
    };

    if (isDiagramUnsaved) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload); // Cleanup on unmount
      unblock(); // Unblock history on unmount
    };
  }, [isDiagramUnsaved, unblock]);
  // Your component logic and JSX here

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({
      show: true,
      position: { x: event.clientX, y: event.clientY },
    });
  };
  const closeContextMenu = () => {
    setContextMenu({ ...contextMenu, show: false });
  };
  const nodeMenuStructure = [
    {
      label: "Edit",
      icon: faIcons.faEdit,
      action: () => {
        if(nonEditableTypes.includes(selectedNode.data.inputType)){
          notify("Node is not editable", "", AlertType.ERROR);
          return;
        }
        setShowEdit(true);
      },
    },
    {
      label: "Copy",
      icon: faIcons.faCopy,
      action: () => {
        handleCopy();
      },
    },
    {
      label: "Delete",
      icon: faIcons.faTrashAlt,
      action: () => {
        clearMemo();
        deleteElements({ nodes: [{ id: selectedNode?.id }] });
      },
    },
  ];
  const menuStructure = [
    {
      label: "External Inputs",
      icon: faIcons.faAngleDoubleRight,
      submenu: [
        {
          label: "Fuel",
          action: () => {
            setInputType(NodeType.Fuel);
            setAddInput(true);
          },
        },
        {
          label: "Electricity",
          action: () => {
            setInputType(NodeType.Electricity);
            setAddInput(true);
          },
        },
        {
          label: "Purchased CBAM Good",
          action: () => {
            setInputType(NodeType.CBAMGood);
            setAddInput(true);
          },
        },
        {
          label: "Intermediate Good",
          action: () => {
            setInputType(NodeType.IntermediateGood);
            setAddInput(true);
          },
        },
        {
          label: "Raw Material",
          action: () => {
            setInputType(NodeType.RawMaterial);
            setAddInput(true);
          },
        },
        {
          label: "Heat",
          action: () => {
            setInputType(NodeType.Heat);
            setAddInput(true);
          },
        },
      ],
    },
    {
      label: "Self-generating Heat & Electricity",
      icon: faIcons.faHSquare,
      submenu: [
        {
          label: "Self Generated Electricity",
          action: () => {
            setInputType(NodeType.ElectricityProcess);
            setAddInternal(true);
          },
        },
        {
          label: "Central Heat Unit",
          action: () => {
            onInternalSubmit({ inputType: NodeType.HeatProcess });
          },
        },
      ],
    },
    {
      label: "CBAM",
      icon: faIcons.faIndustry,
      submenu: [
        {
          label: "Produced CBAM Good",
          action: () => {
            setInputType(NodeType.ProducedCBAMGood);
            setAddProcess(true);
          },
        },
        {
          label: "Production Process",
          action: () => {
            setInputType(NodeType.CBAMProcess);
            setAddProcess(true);
          },
        },
        {
          label: "Production Sub-process",
          action: () => {
            setInputType(NodeType.SubProcess);
            setAddInternal(true);
          },
        },
      ],
    },
    {
      label: "Non-CBAM Production Process",
      icon: faIcons.faDotCircle,
      action: () => addCbam("Non-CBAM"),
    },
    {
      label: "Sold to Market",
      icon: faIcons.faDollarSign,
      action: () => addMarket(),
    },
  ];
  const loadNodesAndEdges = (initialNodes, initalEdges) => {
    setNodes(initialNodes);
    const numericIds = initialNodes
      .map((node) => node.id)
      .filter((id) => Boolean(id) && !isNaN(Number(id)));
    let highestNumber = Math.max(
      ...numericIds.map((id) => new Decimal(id).toNumber())
    );
    let newMaxNodes = initialNodes.length + 1;
    if (numericIds.length > 0) {
      newMaxNodes = newMaxNodes + highestNumber;
    }
    setMaxNodes(newMaxNodes);
    setEdges(initalEdges);
  };
  useEffect(() => {
    const fetch = async () => {
      setInitialLoad(true);
      try {
        if (id) {
          let res = await http.get(`/diagrams/${id}`);
          let {
            nodes,
            edges,
            reportingPeriod,
            installationId,
            installationName,
            isFinal,
          } = res.data;
          loadNodesAndEdges(nodes, edges);
          setReportingPeriod({
            ...reportingPeriod,
            type: reportingPeriod.periodType,
          });
          setInstallationId(installationId);
          setInstallationName(installationName);
          setIsFinal(isFinal);
        }
      } catch (err) {
        notify(`Diagram loading failed`, "", AlertType.ERROR);
      }
    };
    fetch();
  }, []);
  const promptUser = (modalType) => {
    if (modalType == "Flow") {
      setShowModal(true);
    } else if (modalType == "Save") {
      setAddPeriod(true);
    }
    return new Promise((resolve) => {
      setResolveInput(() => resolve);
    });
  };
  const defineReportingPeriod = async (reportData) => {
    try {
      const res = await http.post("/diagrams", {
        nodes,
        edges,
        installationId,
        reportingPeriod: {
          periodType: reportData.periodType,
          from: reportData.from,
          to: reportData.to,
        },
        isFinal,
      });
      let { installation } = res.data;
      setInstallationId(installation._id);
      setInstallationName(installation.name);
      setReportingPeriod({
        periodType: reportData.periodType,
        from: reportData.from,
        to: reportData.to,
      });
      setAddPeriod(false);
      history.replace({
        pathname: `/diagrams/${res.data?._id}`,
        state: { installationId: res.data?.installationId },
      });
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
  };
  const handleReportPeriod = async (e) => {
    if (resolveInput) {
      resolveInput(e);
    }
    setReportingPeriod(e);
    await saveDiagram();
    setAddPeriod(false);
  };
  const saveDiagram = async (e) => {
    let period = reportingPeriod;
    let res;
    if (!period) {
      let newPeriod = await promptUser("Save");
      period = newPeriod;
      setReportingPeriod(newPeriod);
    }
    let action;
    try {
      if (!id) {
        action = "Creation";
        res = await http.post("/diagrams", {
          nodes,
          edges,
          installationId,
          reportingPeriod: period,
          isFinal,
        });
      } else {
        action = "Update";
        res = await http.put(`/diagrams/${id}`, {
          nodes,
          edges,
          installationId,
          reportingPeriod: period,
          isFinal,
        });
      }
      let { installation } = res.data;
      setInstallationId(installation._id);
      setInstallationName(installation.name);
      notify(`Diagram ${action}`, `${action} successful`, AlertType.SUCCESS);
      setIsDiagramUnsaved(false);
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `Diagram ${error?.action ?? ""} has failed`,
        error?.message,
        AlertType.ERROR
      );
      throw new Error();
    }
  };
  const deleteDiagram = async () => {
    setIsDiagramUnsaved(false);
    await http.remove(`/diagrams/${id}`);
    notify("Diagram deleted", "Deletion sucessful", AlertType.SUCCESS);
    history.push("/operator-installations");
  };

  const getNodePosition = () => {
    let { x, y } = contextMenu.position;
    if (x == 0 && y == 0) {
      x = window.innerWidth / 2;
      y = window.innerHeight / 2;
    }
    return screenToFlowPosition({ x, y });
  };

  const renderOverlay = () => (
    <Popover id="delete-confirmation-popover">
      <Popover.Header as="h3">Are you sure?</Popover.Header>
      <Popover.Body>
        <div className="pb-3">
          You are about to <span className="text-danger">delete</span> this
          diagram. Do you want to continue?
        </div>
        <div className="flex justify-between">
          <CgButton
            label="Cancel"
            variant="info"
            onClick={() => {
              document.body.click();
            }}
            size="sm"
          />
          <CgButton
            label="Delete"
            variant="danger"
            onClick={async () => {
              document.body.click();
              await deleteDiagram();
            }}
            size="sm"
          />
        </div>
      </Popover.Body>
    </Popover>
  );

  const getSaveButtonLabel = () => {
    const icon = submitting ? faIcons.faSpinner : faIcons.faSave;
    const spinClassName = submitting ? "fa-spin" : "";
    const label = submitting ? "Saving..." : "Save";
    return (
      <>
        <FontAwesomeIcon
          icon={icon}
          fixedWidth
          className={`mr-2 ${spinClassName}`}
        />
        {label}
      </>
    );
  };

  const [showPopover, setShowPopover] = useState(false);
  const loadTemplate = async (data) => {
    setShowTemplates(false);
    let { nodes, edges } = data;
    setNodes([]);
    setEdges([]);
    // load installation Id correctly
    nodes = nodes.map((node) => {
      return {
        ...node,
        data: { ...node.data, installationId: installationId },
      };
    });

    for (let node of nodes) {
      if (node.data.inputType == NodeType.Electricity) {
        if (node.data.name == "Grid") {
          let res = await http.get(
            `/emissions/standard/factors?installationId=${installationId}&resourceName=Electricity`
          );
          node.data.indirectEmissionFactor = res.data.factor;
        }
      } else if (node.data.inputType == NodeType.Fuel) {
        let ncvUnit = getNcvUnit(node.data.unit);
        let factorUnit = getEmissionsUnit(node.data.unit);
        let res = await http.get(
          `/emissions/standard/factors?installationId=${installationId}&resourceName=${node.data.name}&ncvUnit=${ncvUnit}&factorUnit=${factorUnit}`
        );
        let { factor, ncv } = res.data;
        node.data.emissionFactor = factor;
        node.data.ncv = ncv;
      }
      node.data.quantity = 0;
      node.data.pfcEmissions = 0;
      let { direct, indirect } = calculateEmissions(node.data);
      node.data.direct = direct.toNumber();
      node.data.indirect = indirect.toNumber();
      node.data.emissions = direct.plus(indirect).toNumber();
      setMemo(node.id, node);
    }
    for (let edge of edges) {
      edge.data = {
        ...edge.data,
        quantity: 0,
        emissions: 0,
        direct: 0,
        indirect: 0,
      };
      setMemo(edge.id, edge.data);
    }
    loadNodesAndEdges(nodes, edges);
    // sleep for state to catch up
    await new Promise((resolve) => setTimeout(resolve, 4000));
    for (let node of nodes) {
      cascadeEmissionUpdates(node.id, node.data, {
        getNode,
        getEdges,
        updateNodeData,
        updateEdgeData,
      });
    }
  };
  const getBottomLeft = () => {
    return (
      <CgButton
        label="Templates"
        variant="primary"
        className="text-white"
        onClick={() => setShowTemplates(true)}
      />
    );
  };
  const handleSaveErrors = async () => {
    try {
      await saveDiagram();
    } catch (err) {
      console.log(err);
    }
  };
  const getTopRight = () => {
    const handleToggleClick = (toggled) => {
      setIsFinal(toggled);
      setShowPopover(!showPopover);
    };

    const handleConfirm = async () => {
      setShowPopover(false);
      try {
        await saveDiagram();
      } catch (err) {
        setIsFinal(!isFinal);
      }
    };

    const handleCancel = () => {
      setIsFinal(!isFinal);
      setShowPopover(false);
    };

    const renderPopover = () => (
      <Popover id="toggle-confirmation-popover">
        <Popover.Header as="h3">Confirm Action</Popover.Header>
        <Popover.Body>
          <div className="pb-3">
            {isFinal
              ? "Saving as final will disable edits and enable exporting the emissions data. To continue editing, you will need to revert the diagram to draft status."
              : "Saving this diagram as draft will enable edits but disable exporting the emissions data. To enable exporting the emissions data, you will need to save the diagram as final."}
          </div>
          <div className="flex space-x-2 justify-between">
            <CgButton
              label="Confirm"
              variant="primary"
              onClick={handleConfirm}
              size="md"
            />
            <CgButton
              label="Cancel"
              variant="secondary"
              onClick={handleCancel}
              size="md"
            />
          </div>
        </Popover.Body>
      </Popover>
    );

    if (reportingPeriod) {
      return (
        <div className="flex space-x-3 items-center">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={renderPopover()}
            show={showPopover}
            onHide={() => setShowPopover(false)}
          >
            <div id="draftFinalToggle" className="flex space-x-3 items-center">
              <CgToggle
                toggled={isFinal}
                onClick={handleToggleClick}
                offLabel={"Draft"}
                onLabel={"Final"}
              />
            </div>
          </OverlayTrigger>
          <div id="saveButton">
            <CgButton
              icon={submitting ? "faSpinner" : "faSave"}
              label="Save"
              variant="primary"
              onClick={handleSaveErrors}
              disabled={submitting}
            />
          </div>
          <OverlayTrigger
            key={`action-btn`}
            trigger="click"
            placement="bottom"
            overlay={renderOverlay()}
            rootClose={true}
          >
            <Button className="text-white text-sm shadow-md" variant="danger">
              <div className="flex py-[1px] px-[8px] justify-center items-center">
                <FontAwesomeIcon icon={faIcons.faTrash} className="mr-2" />
                <p className="font-semibold">Delete</p>
              </div>
            </Button>
          </OverlayTrigger>
        </div>
      );
    }
    return (
      <Button
        variant="primary"
        onClick={handleSaveErrors}
        disabled={submitting}
        className="text-white"
      >
        {getSaveButtonLabel()}
      </Button>
    );
  };

  const addCbam = (name) => {
    let node = {
      id: name,
      position: getNodePosition(),
      type: "customNode",
      data: {
        name: "Rest of Factory",
        label: `${name} Process(es)`,
        quantity: 0,
        inputType: "Non CBAM Process",
      },
    };
    addNodeHook(node, false);
  };

  const addMarket = () => {
    let marketNode = {
      id: "market",
      position: getNodePosition(),
      type: "customNode",

      data: {
        label: "Sold/Exported to Market",
        name: "",
        quantity: 0,
        inputType: "Market",
      },
    };
    addNodeHook(marketNode, false);
  };
  const cannotConnect = (sourceNode, targetNode) => {
    const ConnectionErrors = Object.freeze({
      InvalidConnection: `Cannot connect ${sourceNode.data.inputType} to ${targetNode.data.inputType}`,
      InvalidPrecursor: `${sourceNode.data.aggregatedGoodsCategory} is not a valid precursor to ${targetNode.data.aggregatedGoodsCategory}`,
      InvalidProductOutput: `${targetNode.data.aggregatedGoodsCategory} is not a valid product output for Production Process - ${sourceNode.data.name}`,
      MultipleConnectionsToProductOutput: `Cannot connect multiple processes to Produced CBAM Good - ${targetNode.data.aggregatedGoodsCategory}`,
    });
    let sourceType = sourceNode.data.inputType;
    let targetType = targetNode.data.inputType;
    let cbamNodes = [
      NodeType.CBAMProcess,
      NodeType.CBAMGood,
      NodeType.ProducedCBAMGood,
    ];
    if (targetType == NodeType.ProducedCBAMGood) {
      if (
        sourceType == NodeType.CBAMProcess ||
        sourceType == NodeType.SubProcess
      ) {
        let connectedEdges = getConnectedEdges([targetNode], edges);
        let connectedNodeTypes = connectedEdges.map(
          (edge) => getNode(edge.source).data.inputType
        );
        if (
          connectedNodeTypes.includes(NodeType.CBAMProcess) ||
          connectedNodeTypes.includes(NodeType.SubProcess)
        ) {
          return ConnectionErrors.MultipleConnectionsToProductOutput;
        }
      }
    }
    if (!validConnections[sourceType].includes(targetType)) {
      return ConnectionErrors.InvalidConnection;
    } else if (
      cbamNodes.includes(sourceType) &&
      cbamNodes.includes(targetType)
    ) {
      let sourceCategory = sourceNode.data.aggregatedGoodsCategory;
      let targetCategory = targetNode.data.aggregatedGoodsCategory;
      if (
        sourceType == NodeType.CBAMProcess &&
        targetType == NodeType.ProducedCBAMGood
      ) {
        if (sourceCategory != targetCategory) {
          return ConnectionErrors.InvalidProductOutput;
        } else {
          return null;
        }
      }
      if (!validPrecursors[targetCategory].includes(sourceCategory)) {
        return ConnectionErrors.InvalidPrecursor;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const onConnect = useCallback(
    async (params) => {
      clearMemo();
      setIsCascading(true);
      let source = getNode(params.source);
      let target = getNode(params.target);
      let errorMsg = cannotConnect(source, target);
      if (errorMsg) {
        notify("Invalid connection", errorMsg, AlertType.ERROR);
        return;
      }

      setSourceNode(source);
      setTargetNode(target);

      let { flow, flowType, carbonContent } = await promptUser("Flow");

      if (!Boolean(carbonContent) && Boolean(source.data.carbonContent)) {
        carbonContent = source.data.carbonContent;
      }
      let { direct, indirect } = calculateEmissions({
        ...source.data,
        quantity: flow,
        flowType,
        carbonContent,
        totalQuantity: source.data.quantity,
      });
      if (source.data.inputType == NodeType.Fuel) {
        let generatedHeat = new Decimal(flow).mul(
          source.data.ncv ? source.data.ncv : 0
        );
        if (target.data.inputType == NodeType.HeatProcess) {
          target.data.quantity = new Decimal(target.data.quantity ?? 0)
            .plus(generatedHeat)
            .toNumber();
        }
        if (target.data.inputType == NodeType.CBAMProcess) {
          target.data.productionHeat = new Decimal(
            target.data.productionHeat ? target.data.productionHeat : 0
          )
            .plus(generatedHeat)
            .toNumber();
        }
      }

      if (
        source.data.inputType == NodeType.CBAMProcess ||
        source.data.inputType == NodeType.SubProcess
      ) {
        if (
          [FlowType.Emission, FlowType.Heat].includes(flowType) ||
          Boolean(carbonContent)
        ) {
          let capturedEmissions = direct;
          let sourceDirect = new Decimal(source.data.direct ?? 0).minus(
            capturedEmissions
          );

          let sourceIndirect = new Decimal(source.data.indirect ?? 0);
          source.data.direct = sourceDirect.toNumber();
          source.data.emissions = sourceDirect.add(sourceIndirect).toNumber();
          if (Boolean(carbonContent)) {
            // recalculate emissions for the target node without the captured emissions
            // this is necessary as carbon content effectively reduces the emissions of the source node
            // and does not transport these additional emissions to the target node

            let emissionsWithoutCarbonContent = calculateEmissions({
              ...source.data,
              quantity: flow,
              flowType,
              totalQuantity: source.data.quantity,
            });
            direct = emissionsWithoutCarbonContent.direct;
            indirect = emissionsWithoutCarbonContent.indirect;
          }
          cascadeEmissionUpdates(
            source.id,
            {
              ...source.data,
              emissions: sourceDirect.add(sourceIndirect).toNumber(),
              direct: sourceDirect.toNumber(),
              indirect: sourceIndirect.toNumber(),
            },
            {
              getNode,
              getEdges,
              updateNodeData,
              updateEdgeData,
            }
          );
        }
        if (
          FlowType.Good == flowType &&
          (target.data.inputType == NodeType.ProducedCBAMGood ||
            target.data.inputType == NodeType.SubProcess)
        ) {
          target.data.quantity = new Decimal(target.data.quantity ?? 0)
            .add(new Decimal(flow))
            .toNumber();
        }
      }
      if (target.data.inputType == NodeType.ElectricityProcess) {
        indirect = direct;
        direct = new Decimal(0);
      }

      let targetDirect = new Decimal(target.data.direct ?? 0).add(direct);
      let targetIndirect = new Decimal(target.data.indirect ?? 0).add(indirect);

      setEdges((eds) =>
        addEdge(
          {
            ...params,
            animated: true,
            data: {
              ...source.data,
              unit: source.data.unit,
              quantity: flow,
              flowType,
              carbonContent,
              direct: direct,
              indirect: indirect,
            },
            type: "custom-edge",
          },
          eds
        )
      );
      let updatedNodes = new Map();
      setMemo(source.id, source);
      cascadeEmissionUpdates(
        target.id,
        {
          ...target.data,
          emissions: targetDirect.add(targetIndirect).toNumber(),
          direct: targetDirect.toNumber(),
          indirect: targetIndirect.toNumber(),
        },
        {
          getNode,
          getEdges,
          updateNodeData,
          updateEdgeData,
        },
        new Set(),
        updatedNodes
      );
      setIsCascading(false);
    },
    [setEdges, nodes]
  );

  const handleEdgeDelete = (deletedEdges) => {
    clearMemo();
    setIsCascading(true);
    for (let edge of deletedEdges) {
      let source = getMemo(edge.source) || getNode(edge.source);
      let target = getMemo(edge.target) || getNode(edge.target);
      if (hasMemo(edge.id)) {
        edge.data = getMemo(edge.id);
      }
      let direct = new Decimal(edge.data.direct);
      let indirect = new Decimal(edge.data.indirect);

      let targetDirect = new Decimal(target.data.direct ?? 0);

      let targetIndirect = new Decimal(target.data.indirect ?? 0);
      targetDirect = targetDirect.minus(direct);
      targetIndirect = targetIndirect.minus(indirect);
      let targetEmissions = targetDirect.add(targetIndirect);
      // in case any other function references this edge, we need to update the memo table to refer to empty values
      // instead of the edge's old values which could lead to negative states
      setMemo(edge.id, { ...edge.data, direct: 0, indirect: 0, quantity: 0 });
      if (source.data.inputType == NodeType.Fuel) {
        let generatedHeat = new Decimal(edge.data.quantity).mul(
          edge.data.ncv ? edge.data.ncv : 0
        );
        if (target.data.inputType == NodeType.HeatProcess) {
          target.data.quantity = new Decimal(target.data.quantity ?? 0)
            .minus(generatedHeat)
            .toNumber();
        }
        if (target.data.inputType == NodeType.CBAMProcess) {
          target.data.productionHeat = new Decimal(
            target.data.productionHeat ? target.data.productionHeat : 0
          )
            .minus(generatedHeat)
            .toNumber();
        }
      }
      if (
        source.data.inputType == NodeType.CBAMProcess ||
        source.data.inputType == NodeType.SubProcess
      ) {
        if (
          [FlowType.Emission, FlowType.Heat].includes(edge.data.flowType) ||
          Boolean(edge.data.carbonContent)
        ) {
          if (
            edge.data.flowType == FlowType.Emission &&
            electricityTypes.includes(target.data.inputType)
          ) {
            direct = indirect;
          }
          let capturedEmissions = direct;
          if (Boolean(edge.data.carbonContent)) {
            let carbonContentEmissions = calculateEmissions({
              ...source.data,
              quantity: edge.data.quantity,
              flowType: edge.data.flowType,
              carbonContent: edge.data.carbonContent,
            });
            capturedEmissions = carbonContentEmissions.direct;
          }
          let sourceDirect = new Decimal(source.data.direct ?? 0).add(
            capturedEmissions
          );
          let sourceIndirect = new Decimal(source.data.indirect ?? 0);
          source.data.direct = sourceDirect.toNumber();
          source.data.emissions = sourceDirect.add(sourceIndirect).toNumber();
          cascadeEmissionUpdates(
            source.id,
            {
              ...source.data,
              emissions: sourceDirect.add(sourceIndirect).toNumber(),
              direct: sourceDirect.toNumber(),
            },
            {
              getNode,
              getEdges,
              updateNodeData,
              updateEdgeData,
            },
            new Set()
          );
        }

        if (
          target.data.inputType == NodeType.ProducedCBAMGood ||
          target.data.inputType == NodeType.SubProcess
        ) {
          target.data.quantity = new Decimal(target.data.quantity ?? 0)
            .minus(new Decimal(edge.data.quantity))
            .toNumber();
        }
      }
      setMemo(source.id, source);
      setMemo(edge.target, {
        ...target,
        data: {
          ...target.data,
          direct: targetDirect.toNumber(),
          indirect: targetIndirect.toNumber(),
          emissions: targetEmissions.toNumber(),
        },
      });
      cascadeEmissionUpdates(
        edge.target,
        {
          ...target.data,
          emissions: targetEmissions.toNumber(),
          direct: targetDirect.toNumber(),
          indirect: targetIndirect.toNumber(),
        },
        {
          getNode,
          getEdges,
          updateNodeData,
          updateEdgeData,
        },
        new Set()
      );
    }
    setIsCascading(false);
  };

  const handleFlowInputSubmit = (formData) => {
    setShowModal(false);
    let { quantity, flowType, carbonContent } = formData;

    if (resolveInput) {
      resolveInput({
        flow: quantity,
        flowType: flowType,
        carbonContent,
      });
    }
  };

  const onDataFormSubmit = (formData) => {
    setRun(false);
    if (kickOffJourney[currentJourneyIndex]?.steps) {
      setCurrentJourneyIndex(currentJourneyIndex + 1);
    }
    handleNextStep();
    setSteps([]);
    if (miniTutorialRun) {
      setMiniTutorialRun(false);
    }
    let { inputType, quantity, unit } = formData;

    if (unit == "02") {
      formData.unit = "01";
      formData.quantity = new Decimal(quantity).div(1000).toNumber();
    }

    // Calculate the center of the viewport in React Flow coordinates

    let emissions = 0;
    let direct,
      indirect = 0;

    let newId;

    if (inputType != NodeType.CBAMProcess) {
      newId = (maxNodes + 1).toString();
      ({ direct, indirect } = calculateEmissions(formData));
      formData.emissions = direct.plus(indirect).toNumber();
      formData.direct = direct.toNumber();
      formData.indirect = indirect.toNumber();
    } else {
      newId = `${NodeType.CBAMProcess} - ${formData.name}`;
      let duplicateNodes = nodes.filter(
        (node) =>
          node.data.inputType == NodeType.CBAMProcess && node.id == newId
      );
      if (duplicateNodes.length > 0) {
        notify("Cannot add node", `${newId} already exists`, AlertType.ERROR);
        return;
      }
      let pfcEmissions = calculatePFC(formData);
      formData.direct = pfcEmissions.toNumber();
      formData.emissions = formData.direct;
      formData.pfcEmissions = formData.direct;
    }
    if (inputType == NodeType.Heat) {
      formData.name = "External Heat";
    }
    let newNode = {
      id: newId,
      position: getNodePosition(),
      type: "customNode",
      data: { ...formData },
    };

    addNodeHook(newNode);
  };

  let selectedTypes = Array.from(
    new Set(nodes.map((node) => node.data.inputType))
  );

  const onInternalSubmit = (formData) => {
    let { quantity, unit, additionalInfo } = formData;
    if (formData.inputType == NodeType.ElectricityProcess && unit == "02") {
      unit = "01";
      quantity = new Decimal(quantity).div(1000).toNumber();
    }
    if (formData.inputType == NodeType.HeatProcess) {
      unit = "TJ";
      quantity = "0";
    }
    let newId = (maxNodes + 1).toString();
    let node = {
      id: newId,
      position: getNodePosition(),
      type: "customNode",
      data: {
        name: formData.name ?? "Self Generated",
        inputType: formData.inputType,
        quantity,
        unit,
        additionalInfo,
      },
    };
    addNodeHook(node);
    setAddInternal(false);
  };

  const callback = (data) => {
    const { status, stepIndex, step, type } = data;
    const target = step?.target?.substring(1);
    // If the step is finished or skipped, proceed to the next step
    if (["finished", "skipped"].includes(status)) {
      setMiniTutorialRun(false);
      setRun(false);
      if (kickOffJourney[currentJourneyIndex]?.steps) {
        setCurrentJourneyIndex(currentJourneyIndex + 1);
      }
    }
  };

  const cannotEditDiagram = (e, ignoredChanges = []) => {
    let cannotEdit = isFinal;
    if (e && e.map) {
      let changes = e
        ?.map((changes) => changes.type)
        .filter((type) => !ignoredChanges.includes(type) && type);
      cannotEdit = isFinal && changes.length > 0;
    }
    if (cannotEdit) {
      notify("Cannot edit diagram", "Diagram is final", AlertType.ERROR);
      return true;
    }
    return false;
  };
  const addNodeHook = (node, useMaxNodes = true) => {
    setNodes([...nodes, node]);
    if (useMaxNodes) setMaxNodes(maxNodes + 1);
    setIsDiagramUnsaved(true);
    setAddProcess(false);
    setAddInput(false);
  };
  const UnsavedChangedAlertMessage = () => {
    return (
      <Modal show={showConfirmModal} onHide={handleCancelLeave}>
        <Modal.Header closeButton>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have unsaved changes. Do you want to leave without saving?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleConfirmLeave}>
            Leave Anyway
          </Button>
          <Button variant="primary" onClick={handleCancelLeave}>
            <span className="text-white">Stay on Page</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const [showEdit, setShowEdit] = useState(false);
  const onEditSubmit = (formData) => {
    clearMemo();
    setShowEdit(false);

    let { quantity, unit } = formData;
    if (unit == "02") {
      formData.unit = "01";
      formData.quantity = new Decimal(quantity).div(1000).toNumber();
    }

    let { direct, indirect } = calculateEmissions({
      ...formData,
      totalQuantity: formData.quantity,
    });
    if (inputType == NodeType.CBAMProcess) {
      direct = direct.minus(new Decimal(selectedNode.data?.pfcEmissions ?? 0)); // get the old pfcEmissions from props.data
      let newPFCEmissions = calculatePFC(formData); // calculate new pfcEmissions & add them
      direct = direct.add(newPFCEmissions);
      formData.pfcEmissions = newPFCEmissions.toNumber();
    }
    let emissions = direct.plus(indirect);

    cascadeEmissionUpdates(
      selectedNode.id,
      {
        ...formData,
        direct: direct.toNumber(),
        indirect: indirect.toNumber(),
        emissions: emissions.toNumber(),
      },
      {
        getNode,
        getEdges,
        updateNodeData,
        updateEdgeData,
      }
    );
  };
  const [editModal, setEditModal] = useState(<></>);
  useEffect(() => {
    let ModalComponent;
    if (internalTypes.includes(selectedNode?.data?.inputType)) {
      ModalComponent = AddInternalInputModal;
    } else if (externalTypes.includes(selectedNode?.data?.inputType)) {
      ModalComponent = AddInputModal;
    } else {
      ModalComponent = AddProcessModal;
    }

    setEditModal(
      <ModalComponent
        show={showEdit}
        setShow={setShowEdit}
        onSubmit={onEditSubmit}
        initialState={{ ...selectedNode?.data, id: selectedNode?.id }}
        edges={getEdges()}
        installationId={installationId}
        inputType={selectedNode?.data?.inputType}
      />
    );
  }, [selectedNode, showEdit]);

  return (
    <DashboardLayout graph={true}>
      <div>
        {editModal}
        <AddInputModal
          show={addInput}
          setShow={setAddInput}
          onSubmit={onDataFormSubmit}
          initialState={{ installationId, inputType }}
          handlePreviousStep={handlePreviousStep}
        />
        <AddInternalInputModal
          show={addInternal}
          setShow={setAddInternal}
          onSubmit={onInternalSubmit}
          initialState={{ inputType }}
        />

        <AddProcessModal
          show={addProcess}
          setShow={setAddProcess}
          onSubmit={onDataFormSubmit}
          initialState={{
            quantity: "0",
            direct: "0",
            indirect: "0",
            emissions: "0",
          }}
          inputType={inputType}
          handlePreviousStep={handlePreviousStep}
        />
        <TemplatesModal
          show={showTemplates}
          setShow={setShowTemplates}
          onSubmit={loadTemplate}
        />

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Max Flow</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FlowInputForm
              onSubmit={(e) => handleFlowInputSubmit(e)}
              sourceNode={sourceNode}
              targetNode={targetNode}
            />
          </Modal.Body>
        </Modal>

        <ReportingPeriodModal
          show={addPeriod}
          setShow={setAddPeriod}
          onSubmit={(e) => {
            setRun(false);
            !reportingPeriod ? defineReportingPeriod(e) : handleReportPeriod(e);
            handleNextStep();
          }}
          initialState={reportingPeriod}
        />

        <div style={{ height: "93.5vh" }}>
          <ReactFlow
            onInit={onInit}
            minZoom={0.01}
            maxZoom={10.0}
            nodes={nodes}
            edges={edges}
            onEdgesDelete={(e) => {
              if (cannotEditDiagram(e)) {
                return;
              }
              pushUndoCurr();
              handleEdgeDelete(e);
              if (!initialLoad) setIsDiagramUnsaved(true);
            }}
            onNodesChange={(e) => {
              if (e && e.map) {
                for (let change of e) {
                  if (change.type == "select" && change.selected) {
                    setSelectedNode(getNode(change.id));
                    break;
                  }
                  if (change.type == "replace" && change.item.selected) {
                    setSelectedNode(change.item);
                    break;
                  }
                }
              }
              if (cannotEditDiagram(e, ["select", "position"])) {
                return;
              }
              pushUndoOnNodeChange(e);
              onNodesChange(e);
              if (!initialLoad) setIsDiagramUnsaved(true);
            }}
            onEdgesChange={(e) => {
              if (cannotEditDiagram(e, ["select"])) {
                return;
              }
              onEdgesChange(e);
              if (!initialLoad) setIsDiagramUnsaved(true);
            }}
            onConnect={(e) => {
              if (cannotEditDiagram(e)) {
                return;
              }
              pushUndoCurr();
              onConnect(e);
              if (!initialLoad) setIsDiagramUnsaved(true);
            }}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            connectionMode={"loose"}
            proOptions={{ hideAttribution: true }}
            onContextMenu={handleContextMenu}
          >
            <ContextMenu
              show={nodeContextMenu.show}
              onClose={() => {
                setNodeContextMenu({ show: false, position: { x: 0, y: 0 } });
              }}
              position={nodeContextMenu.position}
              menuStructure={nodeMenuStructure}
            />
            <ContextMenu
              show={contextMenu.show}
              onClose={closeContextMenu}
              position={contextMenu.position}
              menuStructure={menuStructure}
              setRun={setRun}
              currentStep={kickOffJourney[currentJourneyIndex]}
              tutorialRunFlag={tutorialRunFlag}
              handleNextStep={handleNextStep}
              kickOffJourney={kickOffJourney}
              currentJourneyIndex={currentJourneyIndex}
              setCurrentJourneyIndex={setCurrentJourneyIndex}
              handlePasteContextMenu={handlePasteContextMenu}
              copiedNode={copiedNode}
            />
            <Panel position={"top-left"} className="p-4">
              <Row className="justify-content-md-center">
                <Col>
                  <div>
                    <AddNodeDropdown menuStructure={menuStructure} />
                  </div>
                </Col>
              </Row>
            </Panel>
            {/* <Panel position="top-center" className="p-4">
              <Button
                className="text-white mr-2"
                onClick={() => performUndo()}
              >
                <FontAwesomeIcon icon={faIcons.faUndo}/> Undo
              </Button>
              <Button
                className="text-white"
                onClick={() => performRedo()}
              >
                Redo <FontAwesomeIcon icon={faIcons.faRedo}/>
              </Button>
            </Panel> */}
            <div
              id="addNode1"
              className="absolute top-[40%] left-[40%] w-[100px] h-[100px]"
            ></div>
            <div
              id="addNode2"
              className="absolute top-[40%] right-[30%] w-[100px] h-[100px]"
            ></div>

            <div
              id="addNode3"
              className="absolute top-[20%] left-[15%] w-[100px] h-[100px]"
            ></div>
            <div
              id="addNode4"
              className="absolute top-[40%] left-[15%] w-[100px] h-[100px]"
            ></div>
            <div
              id="addNode5"
              className="absolute top-[70%] left-[15%] w-[100px] h-[100px]"
            ></div>
            {
              <Panel position={"top-right"} className="p-4">
                {getTopRight()}
              </Panel>
            }
            {
              <Panel position={"top-center"} className="p-4">
                {tutorialRunFlag && (
                  <CgButton
                    onClick={handleQuitTutorial}
                    variant="danger"
                    label="Quit Tutorial"
                    icon="faStop"
                  />
                )}
                {!tutorialRunFlag && (
                  <CgButton
                    onClick={playTutorial}
                    variant="primary"
                    label="Play Tutorial"
                    icon="faPlay"
                  />
                )}
              </Panel>
            }
            {
              <Panel position={"bottom-left"} className="p-4">
                {getBottomLeft()}
              </Panel>
            }
            {
              <Panel position={"bottom-right"} className="p-4">
                {reportingPeriod?.from && reportingPeriod?.to && (
                  <>
                    <p className="text-secondaryColor text-lg text-right">
                      {installationName ?? installationName}
                    </p>
                    <p
                      className="text-secondaryColor text-lg text-right underline cursor-pointer"
                      onClick={() => {
                        if (isFinal) return;
                        setAddPeriod(true);
                      }}
                    >
                      Reporting Period:{" "}
                      {`${reportingPeriod?.from} to ${reportingPeriod?.to}`}
                    </p>
                  </>
                )}
              </Panel>
            }
            <Background variant="lines" gap={12} size={2} />
          </ReactFlow>
        </div>
      </div>
      {UnsavedChangedAlertMessage()}
      <Joyride
        run={(run && tutorialRunFlag) || miniTutorialRun}
        steps={steps}
        continuous={true} // Allows the user to click "Next"
        disableOverlayClose={true}
        showSkipButton={true}
        showProgress={true}
        spotlightClicks={true}
        disableScrolling={false}
        disableScrollParentFix={true}
        callback={callback}
        styles={{
          buttonNext: {
            backgroundColor: "#00B894", // Replace with your desired color
            color: "#FFFFFF",
          },
          buttonBack: {
            color: "#00B894",
          },
          options: { zIndex: 10060, textAlign: "left" },
          tooltipContent: {
            textAlign: "left", // Left-aligns text inside the tooltip content specifically
          },
        }}
      />
      <StepsConnectingEdges
        connectingEdgesPanel={connectingEdgesPanel}
        tutorialRunFlag={tutorialRunFlag}
        setConnectingEdgesPanel={setConnectingEdgesPanel}
        setRun={setRun}
        handleNextStep={handleNextStep}
      />
    </DashboardLayout>
  );
}
