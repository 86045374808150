import React from "react";
import {
  Button,
  Form,
  Modal,
  FloatingLabel,
  Popover,
  PopoverHeader,
  PopoverBody,
  OverlayTrigger,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useAvailableCompanies } from "../../Common/Utility";
import SelectSearch from "react-select-search";
import http from "../../../helpers/requests";
import CompanyCreateModal from "../../Companies/components/CompanyCreateModal";
import { useEUCountries, useCnGoods } from "../../Common/Utility";
import Select from "react-select";

const defaultForm = {
  index: "",
  companyId: "",
  supplierId: "",
  installationId: "",
  customsProcedure: {
    procedureCode: "01",
    previousProcedureCode: undefined,
    areaOfImport: "EU",
    inwardProcessingInformation: {
      memberState: null,
      inwardProcessingWaiverForBillOfDischarge: false,
      authorisation: "",
      globalisationTimeStart: "",
      globalisationTimeEnd: "",
      billOfDischargeDeadline: "",
    },
  },
  emissionFactorUsed: "",
  emissionsFactorUsed: "",
  declaredBy: "Importer",
  cost: null,
  cnGood: "",
  cnGoodSuperCategory: "",
  quantity: null,
  unit: "01",
};

export const customsProcedures = [
  {
    procedureCode: "01",
    shortDescription: "Release for free circulation of goods",
    longDescription: "Release for free circulation of goods",
  },
  {
    procedureCode: "07",
    shortDescription:
      "Release of goods for free circulation simultaneously placed under a warehousing",
    longDescription:
      "Release of goods for free circulation simultaneously placed under a warehousing",
  },
  {
    procedureCode: "40",
    shortDescription:
      "Simultaneous release for free circulation and home use of goods",
    longDescription:
      "Simultaneous release for free circulation and home use of goods",
  },
  {
    procedureCode: "42",
    shortDescription:
      "Simultaneous release for home use of VAT-exempt goods with excise-duty suspension",
    longDescription:
      "Simultaneous release for free circulation and home use of goods which are the subject of a VAT-exempt supply to another Member State and, when applicable, an excise-duty suspension",
  },
  {
    procedureCode: "43",
    shortDescription:
      "Release of goods with specific measures during post-accession transition",
    longDescription:
      "Simultaneous release for free circulation and home use of goods subject to specific measures connected with the collection of an amount during the transitional period following the accession of new Member States",
  },
  {
    procedureCode: "44",
    shortDescription: "End use",
    longDescription: "End use",
  },
  {
    procedureCode: "45",
    shortDescription: "Partial home entry and non-customs warehouse placement",
    longDescription:
      "Release of goods for free circulation and partial entry for home use for either VAT or excise duties and their placing in a warehouse other than customs warehouses",
  },
  {
    procedureCode: "46",
    shortDescription:
      "Import of processed products from equivalent outward-processing goods",
    longDescription:
      "Import of processed products obtained from equivalent goods under the outward- processing procedure before exportation of goods they are replacing",
  },
  {
    procedureCode: "48",
    shortDescription:
      "Home use entry with replacement product release under outward processing",
    longDescription:
      "Entry for home use with simultaneous release for free circulation of replacement products under outward processing prior to the export of the defective goods",
  },
];

export const previousProcedures = [
  {
    procedureCode: "01",
    shortDescription: "Release for free circulation of goods",
    longDescription: "Release for free circulation of goods",
  },
  {
    procedureCode: "40",
    shortDescription:
      "Simultaneous release for free circulation and home use of goods",
    longDescription:
      "Simultaneous release for free circulation and home use of goods",
  },
  {
    procedureCode: "42",
    shortDescription:
      "Simultaneous release for home use of VAT-exempt goods with excise-duty suspension",
    longDescription:
      "Simultaneous release for free circulation and home use of goods which are the subject of a VAT-exempt supply to another Member State and, when applicable, an excise-duty suspension",
  },
  {
    procedureCode: "45",
    shortDescription: "Partial home entry and non-customs warehouse placement",
    longDescription:
      "Release of goods for free circulation and partial entry for home use for either VAT or excise duties and their placing in a warehouse other than customs warehouses",
  },
  {
    procedureCode: "51",
    shortDescription: "Placing goods under inward processing procedure",
    longDescription: "Placing goods under inward processing procedure",
  },
  {
    procedureCode: "54",
    shortDescription:
      "Inward processing in another Member State without release for free circulation.",
    longDescription:
      "Inward processing in another Member State (without their being released for free circulation in that Member State) (a)",
  },
];

export const areasOfImport = [
  {
    AreaofImport: "EU",
    Description: "EU by means of Customs import declaration",
  },
  {
    AreaofImport: "EUOTH",
    Description: "EU by other means",
  },
];

export const typesOfUnit = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
];

export const ShipmentContentsModal = (props) => {
  const tempAvailableCompanies = useAvailableCompanies();
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [installations, setInstallations] = useState([]);
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [selectedCnGood, setSelectedCnGood] = useState(null);
  const [declarantGoods, setDeclarantGoods] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedEmissionFactor, setSelectedEmissionFactor] = useState(null);
  const [form, setForm] = useState();
  const [success, setSuccess] = useState(null);
  const [declarantRole, setDeclarantRole] = useState("Importer");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [prevProcCodeFlag, setPrevProcCodeFlag] = useState(false);
  const EUCountries = useEUCountries();
  const cnGoods = useCnGoods();
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (
      props.shipmentContent?.customsProcedure?.previousProcedureCode === "51" ||
      props.shipmentContent?.customsProcedure?.previousProcedureCode === "54"
    ) {
      setPrevProcCodeFlag(true);
    } else {
      setPrevProcCodeFlag(false);
    }
    setDeclarantRole(props.shipmentContent?.declaredBy);
    if (props.shipmentContent?.supplierId) {
      http
        .get(`/installations/supplier/${props.shipmentContent?.supplierId}`)
        .then((response) => {
          setInstallations(response.data);
        });
    }
    setForm(props.shipmentContent);
    setSelectedEmissionFactor(props.shipmentContent?.emissionsFactorUsed);
  }, [props.shipmentContent]);

  useEffect(() => {
    http.get(`/installations/declarant`).then((response) => {
      setInstallations(response.data.installations);
    });
    http.get("/declarant/companies/").then((response) => {
      setAvailableCompanies(response.data);
    });
    http.get("/suppliers/declarant").then((response) => {
      setSuppliers(response.data.suppliers);
    });

    http.get(`/cngoods/cbam`).then((response) => {
      setDeclarantGoods(response?.data?.goods);
    });
    if (!props.shipmentContent) {
      setForm(defaultForm);
    }
  }, []);

  useEffect(() => {
    let cnGood, installationId;
    cnGood = form?.cnGood;
    installationId = form?.installationId;
    let cnCode = cnGood;
    const queryParams = new URLSearchParams({
      installationId,
      cnCode,
    });
    updateFactors(queryParams);
  }, [selectedCnGood, selectedInstallation]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    clearError(errors, e.target.name, setErrors);
  };

  const handleNumChange = (e) => {
    const { name, value } = e.target;
    const floatValue = parseFloat(value);
    setForm({ ...form, [name]: floatValue });
    clearError(errors, name, setErrors);
  };
  const updateFactors = async (queryParams) => {
    try {
      let res = await http.get(`/emissions/factors?${queryParams}`);
      let emission = res.data;
      setSelectedEmissionFactor(emission);
      setLoading(false);
    } catch (err) {}
  };

  const handleDeclarantRoleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setDeclarantRole(e.target.value);
    clearError(errors, e.target.name, setErrors);
  };

  const handleCompanyCreate = () => {
    http.get("/declarant/companies/").then((response) => {
      setAvailableCompanies(response.data);
    });
  };

  const handleCompanyChange = (name) => {
    setForm({ ...form, companyId: name });

    clearError(errors, "companyId", setErrors);
  };

  const handleSupplierChange = (e) => {
    let supplier = JSON.parse(e.target.value);
    setForm({
      ...form,
      supplierId: supplier._id,
      supplier: supplier,
      installationId: "",
      emissionFactorUsed: "",
    });

    if (supplier !== null) {
      http.get(`/installations/supplier/${supplier._id}`).then((response) => {
        setInstallations(response.data);
      });
    } else {
      http.get(`/installations/declarant`).then((response) => {
        setInstallations(response.data.installations);
      });
    }

    setSelectedEmissionFactor(null);
    clearError(errors, "supplierId", setErrors);
  };
  const handleInstallationChange = (e) => {
    let installationId = e.target.value;
    setForm({
      ...form,
      installationId: installationId,
      emissionFactorUsed: "",
    });
    setSelectedInstallation(installationId);

    clearError(errors, "installationId", setErrors);
  };

  const handlecnGoodChange = (object) => {
    let cnGoodId = object.value;
    setForm({ ...form, cnGood: cnGoodId, emissionFactorUsed: "" });
    setSelectedCnGood(cnGoodId);

    clearError(errors, "cnGood", setErrors);
    setInputValue("");
  };

  const handlecnGoodInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleMenuClose = () => {
    // Prevent clearing the input value when the menu closes
    setTimeout(() => {
      setInputValue((prev) => prev);
    }, 0);
  };

  const handleCustomProcChange = (e) => {
    const defInwardProcessingInfo = {
      memberState: null,
      inwardProcessingWaiverForBillOfDischarge: false,
      authorisation: "",
      globalisationTimeStart: "",
      globalisationTimeEnd: "",
      billOfDischargeDeadline: "",
    };

    let newCustomsProcedure = {...form.customsProcedure};

    if(e.target.name === "previousProcedureCode" && e.target.value === "Please Select Previous Procedure"){
      delete newCustomsProcedure[e.target.name];
    }else{
      newCustomsProcedure[e.target.name] = e.target.value;
    }

    console.log(newCustomsProcedure);

    switch (e.target.value) {
      case "51":
      case "54":
        newCustomsProcedure.inwardProcessingInformation =
          defInwardProcessingInfo;
        setPrevProcCodeFlag(true);
        break;
      default:
        setPrevProcCodeFlag(false);
        delete newCustomsProcedure.inwardProcessingInformation;
    }

    setForm({ ...form, customsProcedure: newCustomsProcedure });

    clearError(errors, e.target.name, setErrors);
  };

  const handleMemberStateChange = (newMemberState) => {
    setForm({
      ...form,
      customsProcedure: {
        ...form.customsProcedure,
        inwardProcessingInformation: {
          ...form.customsProcedure.inwardProcessingInformation,
          memberState: newMemberState,
        },
      },
    });

    clearError(errors, "memberState", setErrors);
  };

  const handleCheckboxChange = () => {
    setForm({
      ...form,
      customsProcedure: {
        ...form.customsProcedure,
        inwardProcessingInformation: {
          ...form.customsProcedure.inwardProcessingInformation,
          inwardProcessingWaiverForBillOfDischarge:
            !form.customsProcedure.inwardProcessingInformation
              .inwardProcessingWaiverForBillOfDischarge,
        },
      },
    });
  };

  const handleInwardChange = (e) => {
    setForm({
      ...form,
      customsProcedure: {
        ...form.customsProcedure,
        inwardProcessingInformation: {
          ...form.customsProcedure.inwardProcessingInformation,
          [e.target.name]: e.target.value,
        },
      },
    });

    clearError(errors, e.target.name, setErrors);
  };

  const validateForm = () => {
    const formErrors = form;
    const sharedCondition =
      formErrors.customsProcedure?.previousProcedureCode === "51" ||
      formErrors.customsProcedure?.previousProcedureCode === "54";
    const errorsConfig = [
      {
        field: "companyId",
        message: "Please choose a company",
        condition:
          !formErrors.companyId && formErrors.declaredBy === "Representative",
      },
      {
        field: "supplierId",
        message: "Please choose a supplier",
        condition: !formErrors.supplierId,
      },
      {
        field: "declaredBy",
        message: "Please choose a declarant role",
        condition: !formErrors.declaredBy,
      },
      {
        field: "cost",
        message: "Please enter a cost",
        condition: !formErrors.cost,
      },
      {
        field: "cost",
        message: "You cannot enter a zero value",
        condition: formErrors.cost === 0,
      },
      {
        field: "cnGood",
        message: "Please choose a CN good",
        condition: !formErrors.cnGood,
      },
      {
        field: "quantity",
        message: "Please enter a quantity",
        condition: !formErrors.quantity || formErrors.quantity === null,
      },
      {
        field: "quantity",
        message: "You cannot enter a zero value",
        condition: formErrors.quantity === 0,
      },
      {
        field: "unit",
        message: "Please choose a type of unit",
        condition: !formErrors.unit,
      },
      {
        field: "procedureCode",
        message: "Please choose a procedure code",
        condition: !formErrors.customsProcedure?.procedureCode,
      },
      {
        field: "areaOfImport",
        message: "Please choose an area of import",
        condition: !formErrors.customsProcedure?.areaOfImport,
      },
      {
        field: "memberState",
        message: "Please choose a country",
        condition:
          (!formErrors.customsProcedure?.inwardProcessingInformation
            ?.memberState ||
            formErrors.customsProcedure?.inwardProcessingInformation
              ?.memberState === null) &&
          sharedCondition,
      },
      {
        field: "authorisation",
        message: "Please enter an authorization",
        condition:
          !formErrors.customsProcedure?.inwardProcessingInformation
            ?.authorisation && sharedCondition,
      },
      {
        field: "globalisationTimeStart",
        message: "Please select a date",
        condition:
          !formErrors.customsProcedure?.inwardProcessingInformation
            ?.globalisationTimeStart && sharedCondition,
      },
      {
        field: "globalisationTimeEnd",
        message: "Please select a date",
        condition:
          !formErrors.customsProcedure?.inwardProcessingInformation
            ?.globalisationTimeEnd && sharedCondition,
      },
      {
        field: "billOfDischargeDeadline",
        message: "Please select a date",
        condition:
          !formErrors.customsProcedure?.inwardProcessingInformation
            ?.billOfDischargeDeadline && sharedCondition,
      },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      if (fieldConfig.condition) {
        newErrors[fieldConfig.field] = fieldConfig.message;
      }
    });
    return newErrors;
  };

  const handleContentSave = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      let newForm = {
        ...form,
        emissionFactorUsed: selectedEmissionFactor?._id,
        emissionsFactorUsed: selectedEmissionFactor,
      };
      if (props.updateMode) {
        props.toggleUpdateMode(false);
        if (newForm.installationId === "" || newForm.installationId === null) {
          delete newForm.installationId;
        }
        newForm = { ...newForm, index: props.indexOfContent.toString() };
        if (prevProcCodeFlag) {
          props.onUpdate(newForm);
        } else {
          delete newForm.customsProcedure.inwardProcessingInformation;

          props.onUpdate(newForm);
        }
      } else {
        if (prevProcCodeFlag) {
          props.onSave(newForm);
        } else {
          delete newForm.customsProcedure.inwardProcessingInformation;
          props.onSave(newForm);
        }
      }
      setIsChecked(false);
      setPrevProcCodeFlag(false);
      setDeclarantRole("Importer");
      setError(null);
      setSuccess(null);
      setSelectedEmissionFactor(null);
      setForm(defaultForm);
      props.handleClose();
    }
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="xl"
      style={{}}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!props.updateMode ? "Add Consignment Content" : "Edit Consignment Content"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="mb-6">
            <FloatingLabel
              controlId="declaredBy"
              label="Declarant Role *"
              className="my-2"
            >
              <Form.Select
                aria-label="Default select example"
                name="declaredBy"
                value={form?.declaredBy}
                onChange={(e) => handleDeclarantRoleChange(e)}
              >
                <option>Importer</option>
                <option>Representative</option>
              </Form.Select>
              <p className="text-red-500 text-sm">{errors.declaredBy}</p>
            </FloatingLabel>
          </div>
          <div className="mb-6">
            <h2>Shipment </h2>
            <div className="row">
              <FloatingLabel
                controlId="supplier"
                label="&nbsp;&nbsp;&nbsp;Supplier *"
                className="my-2  col-sm"
              >
                <Form.Select
                  aria-label="Default select example"
                  name="supplierId"
                  value={JSON.stringify(form?.supplier)}
                  placeholder="Select a Supplier"
                  onChange={(e) => handleSupplierChange(e)}
                >
                  <option disabled={form?.supplierId} value={null}>
                    Select a Supplier
                  </option>
                  {suppliers?.length > 0 &&
                    suppliers?.map((supplier, i) => (
                      <option key={"supp" + i} value={JSON.stringify(supplier)}>
                        {supplier?.name}
                      </option>
                    ))}
                </Form.Select>
                <p className="text-red-500 text-sm">{errors.supplierId}</p>
              </FloatingLabel>
              <FloatingLabel
                controlId="installationId"
                label="&nbsp;&nbsp;&nbsp;Installation"
                className="my-2  col-sm"
              >
                <Form.Select
                  aria-label="Default select example"
                  name="installationId"
                  value={form?.installationId}
                  onChange={(e) => handleInstallationChange(e)}
                >
                  <option value={null}>Select an Installation</option>
                  {installations?.length > 0 &&
                    installations?.map(function (installation, i) {
                      return (
                        <option key={"install" + i} value={installation["_id"]}>
                          {installation.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
              <div className="col-sm mt-2 z-10" style={{ maxWidth: "400px" }}>
                <Select
                  options={declarantGoods.map((good, i) => ({
                    value: good["_id"],
                    label: good["_id"] + " - " + good.name,
                    key: "instModalGood_" + i,
                  }))}
                  value={
                    declarantGoods.find(
                      (good) => good._id === form?.cnGood
                    ) && {
                      value: declarantGoods.find(
                        (good) => good._id === form?.cnGood
                      )._id,
                      label:
                        declarantGoods.find((good) => good._id === form?.cnGood)
                          ._id +
                        " - " +
                        declarantGoods.find((good) => good._id === form?.cnGood)
                          .name,
                      key:
                        "instModalGood_" +
                        declarantGoods.findIndex(
                          (good) => good._id === form?.cnGood
                        ),
                    }
                  }
                  placeholder="Select a CN Good *"
                  name="cnGood"
                  onChange={handlecnGoodChange}
                  inputValue={inputValue}
                  onInputChange={handlecnGoodInputChange}
                  onMenuClose={handleMenuClose}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#BFEDE4",
                      primary: "#BFEDE4",
                    },
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "57px",
                      height: "57px",
                      borderRadius: "5px",
                    }),
                  }}
                />
                <p className="text-red-500 text-sm">{errors.cnGood}</p>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <h2>Quantity </h2>
            <div className="quantity">
              <div className="row">
                <FloatingLabel
                  controlId="quantity"
                  label="&nbsp;&nbsp;&nbsp;Net Mass *"
                  className="my-2 col-sm"
                >
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="quantity"
                    value={form?.quantity}
                    onChange={(e) => handleNumChange(e)}
                  />
                  <p className="text-red-500 text-sm">
                    {errors.economicActivity}
                  </p>
                </FloatingLabel>
                <p className="text-red-500 text-sm">{errors.quantity}</p>
                <FloatingLabel
                  controlId="unit"
                  label="&nbsp;&nbsp;&nbsp;Type of Unit *"
                  className="my-2 col-sm"
                >
                  <Form.Select
                    type="text"
                    placeholder=""
                    name="unit"
                    value={form?.unit}
                    onChange={(e) => handleChange(e)}
                  >
                    {typesOfUnit.map((type, i) => (
                      <option key={i} value={type.code}>
                        {type.description}
                      </option>
                    ))}
                  </Form.Select>
                  <p className="text-red-500 text-sm">{errors.unit}</p>
                </FloatingLabel>
                <FloatingLabel
                  controlId="cost"
                  label="&nbsp;&nbsp;&nbsp;Value of Goods (EUR) *"
                  className="my-2 col-sm"
                >
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="cost"
                    value={form?.cost}
                    onChange={(e) => handleNumChange(e)}
                  />
                  <p className="text-red-500 text-sm">{errors.cost}</p>
                </FloatingLabel>
              </div>
            </div>
          </div>
          {declarantRole === "Representative" && (
            <div className="mb-6">
              <h2>Company </h2>
              <div className="row">
                <FloatingLabel
                  controlId="companyId"
                  label="&nbsp;&nbsp;&nbsp;Available Companies *"
                  className="col-sm"
                >
                  <SelectSearch
                    options={availableCompanies?.companies?.map(
                      (company, i) => ({
                        value: company._id,
                        name: company.name,
                        key: "instModalCompany_" + i,
                      })
                    )}
                    search
                    name="companyId"
                    placeholder="Available Companies *"
                    value={form?.companyId}
                    onChange={handleCompanyChange}
                    className="select-search"
                  ></SelectSearch>
                  <p className="text-red-500 text-sm">{errors.companyId}</p>
                </FloatingLabel>
                <div className="col-2 flex items-center">
                  <Button
                    className=""
                    variant="outline-primary"
                    onClick={handleShowModal}
                  >
                    <FontAwesomeIcon icon={faIcons.faPlus} /> New Company
                  </Button>
                </div>
                <CompanyCreateModal
                  showModal={showModal}
                  closeModal={handleCloseModal}
                  onSave={handleCompanyCreate}
                />
              </div>
            </div>
          )}
          <div className="mb-6">
            <div className="row">
              {selectedEmissionFactor && (
                <div className="row mb-4">
                  <div className="flex items-center mb-2 space-x-3">
                    <h2 className="">Emissions Factors </h2>
                    {selectedEmissionFactor?.granularity == "Global" ? (
                      <div className="py-1 px-2 bg-orange-200 flex justify-center rounded-full">
                        <p className="text-orange-500 text-sm">
                          Default Emission Values
                        </p>
                      </div>
                    ) : (
                      <div className="py-1 px-2 bg-green-200 flex justify-center rounded-full">
                        <p className="text-green-500 text-sm px-3">
                          Actual Emission Values
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      {selectedEmissionFactor && (
                        <div>
                          <div className="row pt-2">
                            <div className="col-4 pl-4">
                              <p
                                style={{ color: "#6E7073" }}
                                className="text-lg"
                              >
                                Direct (tCO2/tonne)
                              </p>
                              <p className="text-lg  -mt-1">
                                {
                                  selectedEmissionFactor?.emissionFactors
                                    ?.direct
                                }
                              </p>
                            </div>
                            <div className="col-4 pl-8">
                              <p
                                style={{ color: "#6E7073" }}
                                className="text-lg "
                              >
                                Indirect (tCO2/tonne)
                              </p>
                              <p className=" text-lg -mt-1">
                                {
                                  selectedEmissionFactor?.emissionFactors
                                    ?.indirect
                                }
                              </p>
                            </div>
                            <div className="col-4 pl-12">
                              <p
                                style={{ color: "#6E7073" }}
                                className="text-lg"
                              >
                                Total (tCO2/tonne)
                              </p>
                              <p className="text-lg  -mt-1">
                                {selectedEmissionFactor?.emissionFactors?.total}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row mb-4">
                <h2 className="mb-3">Customs procedure </h2>
                <div className="flex justify-between items-center gap-x-6">
                  <div className="w-full">
                    <FloatingLabel
                      controlId="areaOfImport"
                      label="Area of import *"
                      className="my-2"
                    >
                      <Form.Select
                        aria-label="Default select example"
                        name="areaOfImport"
                        value={form?.customsProcedure?.areaOfImport}
                        onChange={(e) => handleCustomProcChange(e)}
                      >
                        {areasOfImport.map((area, i) => (
                          <option key={i} value={area.AreaofImport}>
                            {area.AreaofImport + " - " + area.Description}
                          </option>
                        ))}
                      </Form.Select>
                      <p className="text-red-500 text-sm">
                        {errors.areaOfImport}
                      </p>
                    </FloatingLabel>
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      controlId="procedureCode"
                      label="Requested procedure *"
                      className="my-2"
                    >
                      <Form.Select
                        aria-label="Default select example"
                        name="procedureCode"
                        value={form?.customsProcedure?.procedureCode}
                        onChange={(e) => handleCustomProcChange(e)}
                      >
                        {customsProcedures.map((proc, i) => (
                          <option key={i} value={proc.procedureCode}>
                            {proc.procedureCode + " - " + proc.shortDescription}
                          </option>
                        ))}
                      </Form.Select>
                      <p className="text-red-500 text-sm">
                        {errors.procedureCode}
                      </p>
                    </FloatingLabel>
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      controlId="previousProcedureCode"
                      label="Previous procedure"
                      className="my-2"
                    >
                      <Form.Select
                        aria-label="Default select example"
                        name="previousProcedureCode"
                        value={form?.customsProcedure?.previousProcedureCode}
                        onChange={(e) => handleCustomProcChange(e)}
                      >
                        <option value={undefined}>
                          Please Select Previous Procedure
                        </option>
                        {previousProcedures.map((proc, i) => (
                          <option key={i} value={proc.procedureCode}>
                            {proc.procedureCode + " - " + proc.shortDescription}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </div>
                </div>
                {prevProcCodeFlag && (
                  <div className="row mt-4">
                    <h2 className="mb-3">Inward Processing Information</h2>
                    <div className="row space-y-4">
                      <div className="col-6 mt-3">
                        <h5>Member State of inward processing authorisation</h5>
                        <SelectSearch
                          options={EUCountries.map((country, i) => ({
                            value: country["_id"],
                            name: country.name,
                            key: "instModalCountry_" + i,
                          }))}
                          search
                          name="memberState"
                          placeholder="Select a country *"
                          value={
                            form?.customsProcedure?.inwardProcessingInformation
                              ?.memberState
                          }
                          onChange={handleMemberStateChange}
                          className="select-search"
                        ></SelectSearch>
                        <p className="text-red-500 text-sm">
                          {errors.memberState}
                        </p>
                      </div>
                      <div className="col-6">
                        <h5>
                          The Authorisation related to the inward processing.
                        </h5>
                        <FloatingLabel
                          controlId="authorisation"
                          label="&nbsp;&nbsp;&nbsp;Authorisation *"
                          className="my-2"
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="authorisation"
                            value={
                              form?.customsProcedure
                                ?.inwardProcessingInformation?.authorisation
                            }
                            onChange={(e) => handleInwardChange(e)}
                          />
                          <p className="text-red-500 text-sm">
                            {errors.authorisation}
                          </p>
                        </FloatingLabel>
                      </div>
                      <div className="col-4">
                        <h5>Globalisation time start</h5>
                        <FloatingLabel
                          controlId="globalisationTimeStart"
                          label="&nbsp;&nbsp;&nbsp;Globalisation time start *"
                          className="my-2 col-sm"
                        >
                          <Form.Control
                            type="date"
                            placeholder=""
                            name="globalisationTimeStart"
                            value={
                              !props.updateMode
                                ? form?.customsProcedure
                                    ?.inwardProcessingInformation
                                    ?.globalisationTimeStart || ""
                                : form?.customsProcedure
                                      ?.inwardProcessingInformation
                                      ?.globalisationTimeStart
                                  ? new Date(
                                      form?.customsProcedure?.inwardProcessingInformation?.globalisationTimeStart
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                            }
                            onChange={(e) => handleInwardChange(e)}
                            required
                          />
                          <p className="text-red-500 text-sm">
                            {errors.globalisationTimeStart}
                          </p>
                        </FloatingLabel>
                      </div>
                      <div className="col-4">
                        <h5>Globalisation time end</h5>
                        <FloatingLabel
                          controlId="globalisationTimeEnd"
                          label="&nbsp;&nbsp;&nbsp;Globalisation time end *"
                          className="my-2 col-sm"
                        >
                          <Form.Control
                            type="date"
                            placeholder=""
                            name="globalisationTimeEnd"
                            value={
                              !props.updateMode
                                ? form?.customsProcedure
                                    ?.inwardProcessingInformation
                                    ?.globalisationTimeEnd || ""
                                : form?.customsProcedure
                                      ?.inwardProcessingInformation
                                      ?.globalisationTimeEnd
                                  ? new Date(
                                      form?.customsProcedure?.inwardProcessingInformation?.globalisationTimeEnd
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                            }
                            onChange={(e) => handleInwardChange(e)}
                            required
                          />
                          <p className="text-red-500 text-sm">
                            {errors.globalisationTimeEnd}
                          </p>
                        </FloatingLabel>
                      </div>
                      <div className="col-4">
                        <h5>Deadline for submission of Bill of discharge</h5>
                        <FloatingLabel
                          controlId="billOfDischargeDeadline"
                          label="&nbsp;&nbsp;&nbsp;Deadline time *"
                          className="my-2 col-sm"
                        >
                          <Form.Control
                            type="date"
                            placeholder=""
                            name="billOfDischargeDeadline"
                            value={
                              !props.updateMode
                                ? form?.customsProcedure
                                    ?.inwardProcessingInformation
                                    ?.billOfDischargeDeadline || ""
                                : form?.customsProcedure
                                      ?.inwardProcessingInformation
                                      ?.billOfDischargeDeadline
                                  ? new Date(
                                      form?.customsProcedure?.inwardProcessingInformation?.billOfDischargeDeadline
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                            }
                            onChange={(e) => handleInwardChange(e)}
                            required
                          />
                          <p className="text-red-500 text-sm">
                            {errors.billOfDischargeDeadline}
                          </p>
                        </FloatingLabel>
                      </div>
                      <div className="col-12">
                        <Form.Check
                          type="checkbox"
                          label="Inward Processing Waiver for Bill of Discharge"
                          id="inwardProcessingWaiverCheckbox"
                          checked={
                            form?.customsProcedure?.inwardProcessingInformation
                              ?.inwardProcessingWaiverForBillOfDischarge
                          }
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          onClick={handleContentSave}
          style={{ color: "white" }}
        >
          {!loading && (
            <>
              <FontAwesomeIcon icon={faIcons.faSave} /> Save
            </>
          )}
          {loading && (
            <>
              <FontAwesomeIcon
                className="fa-spin mr-1"
                icon={faIcons.faSpinner}
              />
              Saving ...
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
