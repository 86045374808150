import React, { useState } from "react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useCbamCountries } from "../../Common/Utility";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CgTable from "../../Common/CgTable";
import CgForm from "../../Common/Form/CgForm";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import CgButton from "../../Common/CgButton";
import http from "../../../helpers/requests";
import { AlertType, notify, notifyError } from "../../Common/Form/CgAlertMessage";
import { TableCountry } from "../../Common/Country";
import { OperatorConnectFormConfig } from "../../../FormConfigs/OperatorConnectForm";

function OperatorConnectedTable(props) {
  const config = OperatorConnectFormConfig; 
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let declarantHeaders = [
    {label: "Declarant's Name", key: "name"},
    {label: "Declarant's Country", key: "country"}
  ];

  let operatorHeaders = [
    {label: "Operator's Name", key: "name"},
    {label: "Operator's Country", key: "country"},
    {label: "Upstream/Downstream", key: "stream"},
    {label: "Status", key: "status"}
  ];

  let headers = props.mode === props.modes[0] ? declarantHeaders : operatorHeaders;

  let declarantData = props.items.map((item) => ({
    name: item?.declarantId?.name ?? "-",
    country: <>
      <TableCountry 
        nameOut
        name={item?.declarantId?.address?.country?.name}
        iso2={item?.declarantId?.address?.country?.iso2}
        iso3={item?.declarantId?.address?.country?.iso3}
      />
    </>
  }));

  const getOperatorName = (operator) => {
    if(operator?.stream === "Upstream"){
      if(operator.recieverId){
        return operator?.recieverId?.name;
      }else{
        return operator?.recieverName;
      }
    }else{
      return operator?.senderId?.name;
    }

  }

  const getOperatorCountry = (operator) => {
    if(operator?.stream === "Upstream"){
      if(operator.recieverId){
        return <>
          <TableCountry 
            nameOut
            name={operator?.recieverId?.address?.country?.name}
            iso2={operator?.recieverId?.address?.country?.iso2}
            iso3={operator?.recieverId?.address?.country?.iso3}
          />
        </>
      }else{
        return <>
          <TableCountry 
            nameOut
            name={operator?.country?.name}
            iso2={operator?.country?.iso2}
            iso3={operator?.country?.iso3}
          />
        </>
      }
    }else{
      return <>
        <TableCountry 
          nameOut
          name={operator?.senderId?.address?.country?.name}
          iso2={operator?.senderId?.address?.country?.iso2}
          iso3={operator?.senderId?.address?.country?.iso3}
        />
      </>
    }

  }

  let operatorData = props.items.map((item) => ({
    name: getOperatorName(item) ?? "-",
    stream: item?.stream ?? "-",
    status: item?.status ?? "-",
    country: getOperatorCountry(item)
  }));


  let data = props.mode === props.modes[0] ? declarantData : operatorData;

  const sendRequest = (form) => {
    http
      .post(`/requests/operator`, form)
      .then(() => {
        notify(
          "Connection sent Successfully",
          "Successfully sent Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError)
  }

  const handleReject = (dataRequest) => {
    http
      .remove(props.mode === props.modes[0] ?  `/requests/${dataRequest._id}` : `/requests/operator/${dataRequest._id}`)
      .then(() => {
        notify(
          "Rejected",
          "Successfully Rejected Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError);
  }

  const actions = [
    {
      label: "Delete Connection",
      variant: "danger",
      style: { color: "white" },
      overlay: {
        id: "delete-overlay",
        title: "Confirm Deletion",
        body: "Are you sure you want to delete this connection? The other party will no longer be able to use your data however any data previously used will remain available to them",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (connection) => {
              handleReject(connection);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  const declarantTableButtons = [];
  const operatorTableButtons = [
    {
      icon: "faPlug",
      label: "Connect with Operator",
      onClick: () => setShowConnectModal(true),
      variant: "primary",
      className: "text-white"
    },
  ];

  const tableButtons = props.mode === props.modes[0] ? declarantTableButtons : operatorTableButtons;

  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title={`${props.mode} Table`}
        tableButtons={tableButtons}
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        filter={props.filterComponent}
      />
      <Modal size="xl" show={showConnectModal} onHide={() => setShowConnectModal(false)}>
          <Modal.Header closeButton>
              <Modal.Title>
                  Connect With Operator
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <CgForm 
                  formGroups={config}
                  disabled={false}
                  submitting={false}
                  btnIcon={faIcons.faPlug}
                  btnName="Connect"
                  onSubmit={(e) => sendRequest(e)}
                  loading={loading}
                  actions={[
                    <CgButton
                      variant="secondary"
                      onClick={() => setShowConnectModal(false)}
                      label="Cancel" 
                    />
                  ]}
              />
          </Modal.Body>
      </Modal>
    </div>
  );
}

export default OperatorConnectedTable;
