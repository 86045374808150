import {
  RedirectToCreateOrganization,
  RedirectToOrganizationProfile,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import * as loadingLottie from "../../../assets/animations/loading-lottie.json";
import OnboardingPage from "./OnboardingPage";
import { OrganizationType } from "../../Common/Utility";
import http from "../../../helpers/requests";
import { clerk } from "../_app";

export default function InitPage(props) {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search)
  let paymentCompleted = Boolean(params.get("paymentComplete"))
  const [isInvited, setIsInvited] = useState(false);

  let { isLoaded, organization } = useOrganization();
  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: { pageSize: 10 },
  });

  let url;
  if (organization?.publicMetadata?.type === OrganizationType.Declarant) {
    url = "/suppliers/search";
  } else if (
    organization?.publicMetadata?.type === OrganizationType.Consultant
  ) {
    url = "/consultant-operators";
  } else {
    url = "/operator-installations";
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const loadIsInvited = async () => {
      http
        .get("/requests/is_invited")
        .then((res) => setIsInvited(res.data?.isInvited));
    };
    loadIsInvited();
  }, [userMemberships]);

  useEffect(() => {
    const check = async () => {
      if (!organization) {
        
        let orgMemberships = await clerk.user.getOrganizationMemberships({pageSize: 10});
        await userMemberships.revalidate();
        let userOrgs = userMemberships?.data;
        if(userOrgs.length == 0){
          userOrgs = orgMemberships.data;
        }
        if (userOrgs?.length > 0) {
          organization = userOrgs[0].organization;
          await setActive({
            organization: organization.id,
          });
          
        }
      }
    };

    setInterval(() => {
      check();
    }, 500)
  }, []);

  const getLoading = () => {
    return (
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
      />
    );
  };
  if (!isLoaded) {
    return getLoading();
  }
  
  if (!organization && !paymentCompleted) {
    let stripeOnboarding = "/payment";
    history.push(stripeOnboarding);
    return;
  }
  return (
    <>
      {!organization && !isInvited && getLoading()}
      {((organization && !organization?.publicMetadata?.onboarded) ||
        isInvited) && <OnboardingPage />}
      {organization?.publicMetadata.onboarded && history.push(url)}
    </>
  );
}
