import {z} from "zod";
import { FormControlTypes } from "../modules/Common/Utility";
import { validatePhoneNumber } from "./ConsultantOperatorInfoFormConfig";

export const OperatorConnectFormConfig= [
  {
    header: "Connection Info",
    name: "general",
    className: "",
    controls: [
      {
        label: "Operator Name",
        placeholder: "",
        name: "recieverName",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
      {
        label: "Country",
        placeholder: "Country",
        name: "country",
        className: "col-6",
        type: FormControlTypes.CBAMDropdown,
        validation: z.string().min(1, "Required field").or(z.number()),
      },
      {
        label: "Email",
        placeholder: "",
        name: "recieverEmail",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().email()
      }
    ],
  },
];