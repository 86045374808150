import React, { useState } from "react";
import CustomReportingPeriodForm from "./CustomReportingPeriodForm"; // import the custom form

export default function ReportingPeriodForm({
  onSubmit,
  onFormChange,
  initialState,
}) {
  return (
    <div className="flex flex-column gap-2 mt-1">
      <h1>Reporting Period Information</h1>
      <div>
        Please note that all quantities and data input in this diagram refer to
        the specified reporting period.
      </div>
      <CustomReportingPeriodForm
        onSubmit={onSubmit}
        onFormChange={onFormChange}
        initialState={initialState}
        disabled={false}
        submitting={false}
        loading={false}
        btnName={onSubmit ? "Next" : "Save"}
      />
    </div>
  );
}
