import CgDataManagementPage from "../Common/CgDataManagementPage";
import OperatorConnectedTable from "./Components/OperatorConnectedTable";
import { useEffect, useState } from "react";
import http from "../../helpers/requests";
import { notifyError } from "../Common/Form/CgAlertMessage";
import CgTabs from "../Common/CgTabs";
import OperatorConnectedSearchForm from "./Components/OperatorConnectSearchForm";

export default function OperatorConnectedPage(){
    const [results, setResults] = useState([]);
    const [form, setForm] = useState({
      name: "",
      email: "",
    });
    const [loading, setLoading] = useState(false);
    const modes = ["Connected Declarants", "Connected Operators"];
    const [mode, setMode] = useState(modes[0]);

    useEffect(() => {
      handleSubmit();
    }, [mode]);

    const handleSubmit = () => {
        setLoading(true);
        http
          .get(mode === modes[0] ? "/requests?status=accepted" : "/requests/operator?status=sent")
          .then((response) => {
            setResults(response.data);
          })
          .catch(notifyError)
          .finally(() => setLoading(false));
    }

    const handleReset = () => setResults([]);

    const renderFilterComponent = () => {
      return (
        <OperatorConnectedSearchForm
          key="supplier-search-form"
          form={form}
          onFormChange={setForm}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
      );
    };

    const tabComponent = (props) => 
      <div className="px-12 pb-4">
        <CgTabs 
          tabs={modes}
          selectedTab={props.mode}
          setSelectedTab={props.handleModeChange}
        />
      </div>;

    return(
        <CgDataManagementPage
            title="Connections"
            tabComponent={tabComponent}
            mode={mode}
            modes={modes}
            handleModeChange={setMode}
            tableComponent={OperatorConnectedTable}
            searchForm={renderFilterComponent()}
            results={results}
            onRefresh={handleSubmit}
            loading={loading}
        />
    );
}