import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";

/**
 * Button component with customizable size, variant, and icon.
 * 
 * @param {Object} props - The component props.
 * @param {Function} [props.onClick] - Function to be called on button click. Defaults to an empty function.
 * @param {string} [props.size="md"] - Size of the button. Can be "sm", "md", or "lg". Defaults to "md".
 * @param {string} [props.label="Button"] - Text label for the button. Defaults to "Button".
 * @param {string} [props.variant="info"] - Variant of the button. Can be "primary", "secondary", "info", or "danger". Defaults to "info".
 * @param {string} [props.href] - URL for the button to act as a link. If provided, renders an `<a>` element.
 * @param {string} [props.icon] - FontAwesome icon name to display inside the button.
 * @param {boolean} [props.outlined=false] - Whether the button should use outlined styles. Defaults to false.
 * 
 * @returns {JSX.Element} The rendered button or link element.
 */
const CgButton = ({
  onClick = () => {},
  size = "md",
  label,
  variant = "info",
  href,
  icon,
  outlined = false,
  disabled = false
}) => {
  const sizeClasses = {
    sm: "text-xs px-3 py-1",
    md: "text-sm px-4 py-2",
    lg: "text-lg px-6 py-3",
  };

  const variantClasses = {
    primary: "bg-primaryColor hover:bg-primaryColor-dark text-white",
    secondary: "bg-secondaryColor hover:bg-slate-700 text-white",
    info: "bg-gray-100 hover:bg-gray-500 text-gray-700",
    danger: "bg-red-500 hover:bg-red-700 text-white",
  };

  const outlinedClasses = {
    primary: "border-primaryColor text-primaryColor hover:bg-primaryColor hover:text-white",
    secondary: "border-secondaryColor text-secondaryColor hover:bg-secondaryColor hover:text-white",
    info: "border-gray-200 text-gray-700 hover:bg-gray-300 hover:text-white",
    danger: "border-red-500 text-red-500 hover:bg-red-500 hover:text-white",
  };

  const buttonBaseClass = `${sizeClasses[size]} font-semibold rounded-lg transition-colors shadow-md duration-300 ease-in-out flex items-center justify-center`;
  const variantClass = outlined ? outlinedClasses[variant] : variantClasses[variant];
  const borderClass = outlined ? "border-2" : "";
  const buttonClass = disabled ? `${buttonBaseClass} border-gray-200 text-gray-500 bg-gray-200` : `${buttonBaseClass} ${variantClass} ${borderClass}`;
  const iconClass = "text-current"; // Ensures icon color matches button text

  const renderIcon = icon ? (
    <span className={`${label ? "mr-2" : ""} ${iconClass}`}>
      <FontAwesomeIcon size={size} icon={faIcons[icon]} />
    </span>
  ) : null;

  if (href) {
    return (
      <a
        href={href}
        onClick={onClick}
        className={buttonClass}
      >
        {renderIcon}
        {label}
      </a>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClass}
      disabled={disabled}
    >
      {renderIcon}
      {label}
    </button>
  );
};

CgButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  label: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "info", "danger"]),
  href: PropTypes.string,
  icon: PropTypes.string,
  outlined: PropTypes.bool,
};

export default CgButton;
