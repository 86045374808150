import { optional, z } from "zod";
import { FormControlTypes } from "../../../Common/Utility";

export const WasteGasFlowFormConfig = [
  {
    className: "",
    controls: [
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-4",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
    ],
  },
];
