import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import CgFormGroup from "./CgFormGroup";
import { Button } from "react-bootstrap";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { z } from "zod";
import cloneDeep from "lodash/cloneDeep";

const CgForm = forwardRef(
  (
    {
      formGroups,
      onSubmit,
      submitting,
      loading,
      btnName,
      btnIcon,
      onChange,
      hideButton,
      actions,
      initialState = {},
      formShown
    },
    ref
  ) => {
    const [formData, setFormData] = useState(initialState);
    const [dynamicFormGroups, setDynamicFormGroups] = useState(formGroups);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [validationSchema, setValidationSchema] = useState(z.object({}));
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, () => ({
      updateField: (fieldName, newValue = "") => {
        let useHandleChange = newValue.useHandleChange;
        if (useHandleChange) {
          handleChange(fieldName, newValue.value, {
            target: fieldName,
            value: newValue.value,
          });
        } else {
          setFormData((prevData) => ({
            ...prevData,

            [fieldName]: newValue ?? "",
          }));
        }
      },
      showSubmitSuccess: () => {
        setSubmitSuccess(true);
      },
      handleSubmit: (e) => {
        handleSubmit(e);
      },
    }));
    useEffect(() => {
      updateDynamicFormGroups();
    }, [formData.inputType]);

    const updateDynamicFormGroups = () => {
      let newGroups = [];
      dynamicFormGroups.forEach((group) => {
        if (group.groupDependency === "inputType") {
          const selectedGroups =
            group.possibleFormGroups[formData.inputType] || [];
          newGroups.push(...selectedGroups);
        }
      });
      setDynamicFormGroups([...formGroups, ...newGroups]);
    };
    useEffect(() => {
      let validations = {};
      let newFormData = { ...formData }; // Start with existing formData

      dynamicFormGroups.forEach((group) => {
        group.controls.forEach((control) => {
          if (control.validation) {
            validations[control.name] = control.validation;
          }
          // Only set default value if it's not already in formData
          if (!(control.name in newFormData)) {
            newFormData[control.name] = control.defaultValue || "";
          }
          if (control.shouldBeDisabled) {
            control.disabled = control.shouldBeDisabled(newFormData);
          }
        });
      });

      setValidationSchema(z.object(validations));
      setFormData(newFormData);
    }, [dynamicFormGroups]);

    useEffect(() => {
      if (submitSuccess === false) return;
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 3000);
    }, [submitSuccess]);

    const validateField = (fieldName, value) => {
      const fieldSchema = validationSchema.shape[fieldName];

      try {
        fieldSchema.parse(value);
        return false;
      } catch (error) {
        if (error?.errors?.[0]?.message) {
          return error.errors[0].message;
        }
      }
    };

    const hasDependency = (control, fieldName) =>
      control.dependency === fieldName ||
      (control.dependencies && control.dependencies.includes(fieldName));

    const handleChange = async (fieldName, value, e) => {
      let cursorPos = e.target.selectionStart;
      // Create a new copy of the form data
      let newData = { [fieldName]: value };
      let originallyDisabled = {};
      // Disable controls before performing async operations
      let updatedFormGroups = dynamicFormGroups.map((group) => {
        group.controls.forEach((control) => {
          if (
            !control.disabled &&
            hasDependency(control, fieldName) &&
            (control.fetchOptions || control.fetchValue)
          ) {
            control.disabled = true;
          } else if (control.disabled) {
            originallyDisabled[control.name] = true;
          }
        });

        return group;
      });

      // Update the form state with disabled controls
      setDynamicFormGroups(updatedFormGroups);

      // update form options
      for (const group of updatedFormGroups) {
        for (const control of group.controls) {
          if (hasDependency(control, fieldName)) {
            if (control.fetchOptions) {
              const options = await control.fetchOptions(value, e);
              control.options = options;
            }
          }
        }
      }

      //update form data
      // let updatedData = await recursiveFetchValue(fieldName, value, 1, updatedFormGroups, originallyDisabled);
      let runningData = { ...newData };
      let updatedData = {};
      let affectedData = [{ ...newData }];
      let count = 0;
      while (affectedData.length > 0) {
        let currData = {};
        let [head, ...rest] = affectedData;
        let fieldName = Object.keys(head)[0];
        let value = head[fieldName];
        runningData = { ...runningData, [fieldName]: value };
        if (count >= 12) {
          throw "circular dependency list, depth of 12 reached";
        }
        // Perform async operations and update controls
        for (const group of updatedFormGroups) {
          if (
            group.groupDependency == fieldName &&
            fieldName !== "inputType" &&
            group.possibleControls
          ) {
            const newControls = group.possibleControls[value] ?? [];
            if (newControls && newControls.length != group.controls.length) {
              group.controls = cloneDeep(newControls);
              group.controls.forEach((control) => {
                if (!affectedData[control.name]) {
                  affectedData[control.name] = control.defaultValue || "";
                }
                if (control.disabled) {
                  originallyDisabled[control.name] = true;
                }
              });
            }
          }
          for (const control of group.controls) {
            if (hasDependency(control, fieldName)) {
              if (control.fetchValue) {
                let newValue;
                if (control.dependency) {
                  newValue = await control.fetchValue(value, formData);
                } else {
                  newValue = await control.fetchValue(fieldName, value, {
                    ...formData,
                    ...runningData,
                  });
                }
                currData[control.name] = newValue;
              }
              if (!originallyDisabled[control.name]) {
                control.disabled = false;
              }
            }
          }
        }
        count++;
        let currDataArr = Object.keys(currData).map((key) => ({
          [key]: currData[key],
        }));
        affectedData = [...rest, ...currDataArr];
        updatedData = { ...updatedData, ...currData };
      }

      let finalData = { ...formData, ...newData, ...updatedData };
      for (const group of updatedFormGroups) {
        for (const control of group.controls) {
          if (control.shouldBeDisabled) {
            control.disabled = control.shouldBeDisabled(finalData);
          }
        }
      }
      let newErrors = { ...errors };
      for (let key in { ...updatedData, ...newData }) {
        let error = validateField(key, finalData[key]);
        if (!error) {
          delete newErrors[key];
        } else {
          newErrors[key] = error;
        }
      }
      // Update form data state and trigger onChange callback if provided
      setFormData(finalData);
      setDynamicFormGroups(updatedFormGroups);
      setErrors(newErrors);
      if (onChange) {
        onChange(finalData);
      }
      requestAnimationFrame(() => {
        if (
          typeof e.target.setSelectionRange === "function" &&
          !e.target.type === "date"
        ) {
          e.target.setSelectionRange(cursorPos, cursorPos);
        }
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      const result = validationSchema.safeParse(formData);

      if (!result.success) {
        const newErrors = {};
        result.error.errors.forEach((error) => {
          newErrors[error.path[0]] = error.message;
        });

        setErrors(newErrors);
        return;
      }
      setErrors({});
      if (onSubmit) {
        onSubmit(formData);
      }
    };

    const isLoading = () => submitting || loading;
    const isSubmitBtnDisabled = () => isLoading() || !valid();
    const valid = () => Object.keys(errors).length === 0;

    const getSaveButtonLabel = () => {
      const defaultIcon = btnIcon ?? faIcons.faSave;
      const icon = submitting ? faIcons.faSpinner : defaultIcon;
      const spinClassName = submitting ? "fa-spin" : "";
      const label = submitting ? "Saving..." : btnName;
      return (
        <>
          <FontAwesomeIcon icon={icon} className={`mr-2 ${spinClassName}`} />
          {label}
        </>
      );
    };

    const getSavedMessage = () => {
      if (isLoading()) return;
      return submitSuccess ? (
        <div className="flex gap-1 align-items-center text-green-500">
          <FontAwesomeIcon icon={faIcons.faCheckCircle} />
          Saved Successfully
        </div>
      ) : (
        ""
      );
    };

    const getFormGroups = () => {
      return dynamicFormGroups.map(
        (group, groupIndex) =>
          group.controls.length > 0 && (
            <CgFormGroup
              key={groupIndex}
              className={group.className}
              groupName={group.header}
              controls={group.controls}
              errors={errors}
              loading={loading}
              handleChange={handleChange}
              formData={formData}
              formShown={formShown}
            />
          )
      );
    };

    return (
      <form onSubmit={handleSubmit}>
        {getFormGroups()}
        {!hideButton && (
          <div className="flex gap-3">
            {actions && actions.map((action) => action)}
            <Button
              id="lastButton"
              variant="primary"
              type="submit"
              className="text-white"
              disabled={isSubmitBtnDisabled()}
            >
              {getSaveButtonLabel()}
            </Button>
            {getSavedMessage()}
          </div>
        )}
      </form>
    );
  }
);

export default CgForm;
