import React, { useState } from "react";
import { Form } from "react-bootstrap";

const CgYearSelector = ({
  startYear = 1900,
  endYear = new Date().getFullYear(),
  onYearSelect,
  value,
  displayValue, // Added displayValue prop for custom display text
}) => {
  // Set the default selected year to the current year (today's year)
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear); // Default value to today's year

  // Handle year change
  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    if (onYearSelect) onYearSelect(year); // Call callback if provided
  };

  // Generate years from endYear to startYear
  const generateYears = () => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };

  return (
    <Form.Group>
      <Form.Control
        as="select"
        value={value || selectedYear}
        onChange={handleYearChange}
        custom
      >
        {generateYears().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default CgYearSelector;
