import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { z } from "zod";
import CgYearSelector from "../../Common/Form/CgYearSelector";

// Zod validation schema
const formValidationSchema = z.object({
  periodType: z.string().min(1, "Period type is required"),
  from: z.string().min(1, "From date is required"),
  to: z.string().min(1, "To date is required"),
});

const CustomReportingPeriodForm = ({
  onSubmit,
  onFormChange,
  initialState,
  disabled,
  submitting,
  loading,
  btnName,
}) => {
  // Default form values
  const defaultFormValues = {
    periodType: "Calendar year",
    from: "2024-01-01",
    to: "2024-12-30",
  };

  // Normalize initialState
  const normalizeInitialState = (state) => {
    if (!state) return defaultFormValues;
    const normalizedState = { ...state };
    if (normalizedState.periodType) {
      normalizedState.periodType = normalizedState.periodType;
      delete normalizedState.periodType;
    }
    return { ...defaultFormValues, ...normalizedState };
  };

  const normalizedInitialState = normalizeInitialState(initialState);

  const [selectedPeriodType, setSelectedPeriodType] = useState(
    normalizedInitialState.periodType
  );
  const [formData, setFormData] = useState(normalizedInitialState);
  
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    onFormChange && onFormChange({ name, value });
  };

  // Handle period type change
  const handlePeriodTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedPeriodType(selectedType);
    if (selectedType === "Calendar year") {
      const currentYear = new Date().getFullYear() - 1;
      setFormData({
        periodType: selectedType,
        from: `${currentYear}-01-01`,
        to: `${currentYear}-12-31`,
      });
    } else {
      const today = new Date().toISOString().split("T")[0];
      setFormData({
        periodType: selectedType,
        from: today,
        to: today,
      });
    }
  };

  // Handle year select for Calendar year
  const handleYearSelect = (name, year) => {
    const fromDate = `${year}-01-01`;
    const toDate = `${year}-12-31`;
    setFormData({
      periodType: "Calendar year",
      from: fromDate,
      to: toDate,
    });
    onFormChange &&
      onFormChange(
        { name: "from", value: fromDate },
        { name: "to", value: toDate }
      );
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const result = formValidationSchema.safeParse(formData);
    if (!result.success) {
      console.error(result.error);
      return;
    }
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleFormSubmit} disabled={disabled}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12">
          <Form.Label>Period Type</Form.Label>
          <Form.Control
            as="select"
            name="periodType"
            value={formData?.periodType}
            onChange={handlePeriodTypeChange}
            isInvalid={formData.periodType && !formData.periodType.trim()}
          >
            <option value="Calendar year">Calendar year</option>
            <option value="Fiscal year">Fiscal year</option>
            <option value="National carbon pricing system">
              National carbon pricing system
            </option>
            <option value="National mandatory emissions monitoring system">
              National mandatory emissions monitoring system
            </option>
            <option value="GHG reduction project with verification">
              GHG reduction project with verification
            </option>
            <option value="Other">Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Period type is required
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      {selectedPeriodType === "Calendar year" ? (
        <Row className="mb-3">
          <Form.Group as={Col} md="12">
            <Form.Label>Year</Form.Label>
            <CgYearSelector
              startYear={2022}
              endYear={new Date().getFullYear()}
              value={formData.from.slice(0, 4)}
              onYearSelect={(year) => handleYearSelect("year", year)}
              displayValue={`${formData.from} to ${formData.to}`}
            />
          </Form.Group>
          <p className="flex justify-center pt-3">
            {formData?.from} to {formData?.to}
          </p>
        </Row>
      ) : (
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="date"
              name="from"
              value={formData.from || ""}
              onChange={handleInputChange}
              isInvalid={formData.from && !formData.from.trim()}
            />
            <Form.Control.Feedback type="invalid">
              From date is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>To</Form.Label>
            <Form.Control
              type="date"
              name="to"
              value={formData.to || ""}
              onChange={handleInputChange}
              isInvalid={formData.to && !formData.to.trim()}
            />
            <Form.Control.Feedback type="invalid">
              To date is required
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      )}

      <Button
        type="submit"
        variant="primary text-white"
        disabled={disabled || submitting || loading}
        className="mt-3"
      >
        {btnName || "Submit"}
      </Button>
    </Form>
  );
};

export default CustomReportingPeriodForm;
