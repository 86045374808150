import React, { useState } from "react";
import CgTable from "../../Common/CgTable";
import http from "../../../helpers/requests";
import { AlertType, notify, notifyError } from "../../Common/Form/CgAlertMessage";
import { TableCountry } from "../../Common/Country";

function OperatorInviteTable(props) {

  let declarantHeaders = [
    {label: "Declarant's Name", key: "name"},
    {label: "Declarant's Country", key: "country"}
  ];

  let operatorHeaders = [
    {label: "Operator's Name", key: "name"},
    {label: "Operator's Country", key: "country"}
  ];

  let isDeclarant = props.mode === props.modes[0]

  let headers = isDeclarant ? declarantHeaders : operatorHeaders;

  let declarantData = props.items.map((item) => ({
    name: item?.declarantId?.name ?? "-",
    country: <>
      <TableCountry 
        nameOut
        name={item?.declarantId?.address?.country?.name}
        iso2={item?.declarantId?.address?.country?.iso2}
        iso3={item?.declarantId?.address?.country?.iso3}
      />
    </>
  }));

  let operatorData = props.items.map((item) => ({
    name: item?.senderId?.name ?? "-",
    country: <>
      <TableCountry 
        nameOut
        name={item?.senderId?.address?.country?.name}
        iso2={item?.senderId?.address?.country?.iso2}
        iso3={item?.senderId?.address?.country?.iso3}
      />
    </>
  }));

  let data = isDeclarant ? declarantData : operatorData;

  const handleAccept = (dataRequest) => {
    http
      .put(isDeclarant ? `/requests/accept/${dataRequest._id}` : `/requests/operator/accept/${dataRequest._id}`)
      .then(() => {
        notify(
          "Accepted",
          "Successfully Accepted Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError)
  }

  const handleReject = (dataRequest) => {
    http
      .remove(isDeclarant ?  `/requests/${dataRequest._id}` : `/requests/operator/${dataRequest._id}`)
      .then(() => {
        notify(
          "Rejected",
          "Successfully Rejected Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError);
  }

  const actions = [
    {
      label: "Accept Request",
      variant: "primary",
      style: { color: "white" },
      overlay: {
        id: "accept-overlay",
        title: "Confirm Acceptance",
        body: `When you accept the ${isDeclarant ? "declarant": "operator"} will be able to view and use your emissions data`,
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Accept",
            variant: "primary",
            style: { color: "white" },
            onClick: (connection) => {
              handleAccept(connection);
              document.body.click();
            },
          },
        ],
      },
    },
    {
      label: "Reject Request",
      variant: "danger",
      style: { color: "white" },
      overlay: {
        id: "delete-overlay",
        title: "Confirm Rejection",
        body: "Are you sure you want to reject this connection request?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Reject",
            variant: "danger",
            style: { color: "white" },
            onClick: (connection) => {
              handleReject(connection);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title={`${props.mode}`}
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        filter={props.filterComponent}
      />
    </div>
  );
}

export default OperatorInviteTable;
