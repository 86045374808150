import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";
import { molarMassConstant } from "../../Nodes/utils";

let units = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
];
let standardControls = [
  {
    label: "Emission Factor (tCO2e/t)",
    placeholder: "",
    dependency: "name",
    name: "emissionFactor",
    className: "col-5",
    fetchValue: async (value, formData) => {
      let res = await http.get(`/emissions/standard/factors?installationId=${formData.installationId}&resourceName=${value}`);
      return res.data.factor ?? null;
    },
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (parseFloat(arg) != 0 && !parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    type: FormControlTypes.Trigger,
    text: "Standard factors are provided by default. Overwrite them with your own",
  },
  {
    label: "Conversion Factor (between 0-100)",
    placeholder: "",
    name: "conversionFactor",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
    .string()
    .or(z.number())
    .transform((arg, ctx) => {
      let num = parseFloat(arg)
      if (!num && num != 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid number",
        });
      }
      if(num > 100 || num < 0){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Must be between 0-100"
        })
      }
      return arg;
    }).optional().or(z.literal('').optional()).optional(),
  },
];
let balanceControls = [
  {
    label: "Carbon Content",
    name: "carbonContent",
    dependency: "name",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
    .string()
    .or(z.number())
    .transform((arg, ctx) => {
      let num = parseFloat(arg)
      if (!num) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid number",
        });
      }
      if(num > 1 || num < 0 ){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Must be between 0-1"
        })
      }
      return arg;
    }).or(z.literal('')),
  },
  {
    label: "Molar Mass Ratio Constant",
    name: "molarConstant",
    className: "col-6",
    defaultValue: molarMassConstant,
    type: FormControlTypes.Text,
    disabled: true,
  },
];
export const IntermediateFormConfig = [
  {
    header: "Intermediate Goods",
    name: "Raw Materials",
    className: "",
    controls: [
      {
        label: "Calculation Method",
        placeholder: "Select method",
        name: "method",
        className: "col-12",
        type: FormControlTypes.Dropdown,
        options: [
          {
            name: "Standard",
            value: "Standard",
          },
          {
            name: "Mass Balance",
            value: "Mass Balance",
          },
        ],
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: true,
      },
      {
        label: "Intermediate Good",
        placeholder: "",
        name: "name",
        creatable: true,
        className: "col-4",
        fetchOptions: async () => {
          let res = await http.get("/emissions/standard/resources?resourceType=intermediate")
          return res.data
        },
        optionLabel: "label",
        optionValue: "value",
        type: FormControlTypes.DropdownSelect,
        validation: null,
      },
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-4",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit",
        name: "unit",
        className: "col-4",
        type: FormControlTypes.Dropdown,
        options: units.map((unit) => {
          return { name: unit.description, value: unit.code };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
    ],
  },
  {
    header: "Calculation Factors",
    name: "Calculation Factors",
    className: "",
    groupDependency: "method",
    controls: [...standardControls],
    possibleControls: {
      Standard: standardControls,
      "Mass Balance": balanceControls,
    },
  },
];
