import { optional, z } from "zod";
import http from "../../../helpers/requests";
import { FormControlTypes, useCnGoods } from "../../Common/Utility";
import Decimal from "decimal.js";

let units = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
  { code: "04", description: "m3" },
];

const list = [
  "Calcined clays",
  "Cement clinker",
  "Cement",
  "Aluminous cement",
  "Sintered Ore",
  "Electricity",
  "Hydrogen",
  "Nitric acid",
  "Ammonia",
  "Mixed fertilizers",
  "Urea",
  "Pig iron",
  "Ferro-manganese",
  "Ferro-chromium",
  "Ferro-nickel",
  "Direct reduced iron",
  "Iron or steel products",
  "Crude steel",
  "Unwrought aluminium",
  "Aluminium products",
];

const categories = list.map((item) => ({ label: item, value: item }));

let wasteGasControls = [
  {
    label: "Waste Gas",
    placeholder: "",
    name: "wasteGas",
    className: "col-6",
    type: FormControlTypes.Numeric,
    defaultValue: "0",
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg) && parseFloat(arg) != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .or(z.literal("")),
  },
  {
    label: "Unit",
    placeholder: "",
    name: "wasteGasUnit",
    options: [
      { label: "TJ", value: "TJ" },
      { label: "Nm3", value: "Nm3" },
    ],
    optionLabel: "label",
    optionValue: "value",
    defaultValue: "Nm3",
    className: "col-6",
    type: FormControlTypes.Dropdown,
    validation: z.string().min(1, "Required field"),
  },
  {
    label: "Waste Gas Emission Factor",
    placeholder: "",
    name: "wasteGasFactor",
    className: "col-6",
    defaultValue: 0.00393,
    disabled: false,
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .or(z.literal("")),
  },
  {
    type: FormControlTypes.Trigger,
    text: "The EU CBAM default is 0.00393 tCO2e/Nm3. Overwrite it if you have actual data",
  },
];
let overTechs = [
  {
    label: "Centre Worked Prebake",
    value: "CWPB",
  },
  {
    label: "Side Worked Prebake",
    value: "SWPB",
  },
];
let slopeTechs = [
  {
    label: "Legacy Point Feed Pre Bake",
    value: "PFPB L",
  },
  {
    label: "Modern Point Feed Pre Bake",
    value: "PFPB M",
  },
  {
    label:
      "Modern Point-Fed Prebake without fully automated anode effect intervention strategies for PFC emissions",
    value: "PFPB MW",
  },
  ...overTechs,
  {
    label: "Vertical Stud Søderberg",
    value: "VSS",
  },
  {
    label: "Horizontal Stud Søderberg",
    value: "HSS",
  },
];

const sefValues = {
  "PFPB L": 0.122,
  "PFPB M": 0.104,
  "PFPB MW": 0.143,
  CWPB: 0.143,
  SWPB: 0.233,
  VSS: 0.058,
  HSS: 0.165,
};

const slopeF2F6Values = {
  "PFPB L": 0.097,
  "PFPB M": 0.057,
  "PFPB MW": 0.121,
  CWPB: 0.121,
  SWPB: 0.28,
  VSS: 0.086,
  HSS: 0.077,
};
const ovcValues = {
  CWPB: 1.16,
  SWPB: 3.65,
};
const overF2F6Values = {
  CWPB: 0.121,
  SWPB: 0.252,
};
const SlopeControls = [
  {
    label: "Technology",
    name: "tech",
    className: "col-11",
    options: slopeTechs,
    optionLabel: "label",
    optionValue: "value",
    type: FormControlTypes.Dropdown,
    validation: z.string().min(1, "Required field"),
  },
  {
    type: FormControlTypes.Trigger,
    text: "Technology-specific emission factors related to activity data for the slope method provided by the EU CBAM Regulation",
  },
  {
    label: "Anode Effect Minutes / Cell Day (AEM)",
    placeholder: "",
    name: "aem",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "CF4 Emission Factor (kg CF4 / t Al produced) / AEM",
    dependency: "tech",
    className: "col-6",
    name: "sef",
    fetchValue: (value) => {
      return sefValues[value];
    },
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "C2F6 Emission Factor (t C2F6 / t CF4)",
    dependency: "tech",
    className: "col-6",
    name: "f2f6",
    fetchValue: (value) => {
      return slopeF2F6Values[value];
    },
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
];

const OvervoltageConfig = [
  {
    label: "Technology",
    name: "tech",
    options: overTechs,
    optionLabel: "label",
    optionValue: "value",
    className: "col-11",
    type: FormControlTypes.Dropdown,
    validation: z.string().min(1, "Required field"),
  },
  {
    type: FormControlTypes.Trigger,
    text: "Technology-specific emission factors related to activity data for the overvoltage method provided by the EU CBAM Regulation",
  },
  {
    label: "Overvoltage Coefficient (kg CF4 / t Al) / mV overvoltage",
    placeholder: "",
    dependency: "tech",
    name: "overvoltageCoefficient",
    className: "col-12",
    type: FormControlTypes.Numeric,
    fetchValue: (value) => {
      return ovcValues[value];
    },
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Anode effect overvoltage / cell",
    placeholder: "",
    name: "anodeOvervoltage",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Aluminum production efficiency (%)",
    dependency: "tech",
    className: "col-6",
    name: "productionEfficiency",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        if (num > 100 || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Must be between 0-100",
          });
        }
        return arg;
      }),
  },
  {
    label: "C2F6 Emission Factor (t C2F6 / t CF4)",
    dependency: "tech",
    className: "col-6",
    name: "f2f6",
    fetchValue: (value) => {
      return overF2F6Values[value];
    },
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
];
// const res = await http.get(`/cngoods/cbam`);
export let ProcessFormConfig = [
  {
    header: "Production Process Category",
    name: "",
    className: "",
    controls: [
      {
        label: "Process Name",
        name: "name",
        type: FormControlTypes.Text,
        dependency: "namePrefix",
        fetchValue: (value, currentValue) => {
          if (currentValue) {
            return currentValue;
          }
          if (value.value) {
            return value.value;
          }
        },
        validation: null,
        className: "col-12",
      },
      {
        label: "CBAM Good",
        placeholder: "Select CN Goods",
        name: "cbamGood",
        dependency: "cbamGood",
        filterCnCodes: true,
        fetchOptions: async (values) => {
          let res;
          if (values && values.length > 0) {
            res = await http.get(
              `/cngoods/cbam?aggregatedGoodsCategory=${values[0].aggregatedGoodsCategory ?? ""}`
            );
          } else {
            res = await http.get(`/cngoods/cbam`);
          }
          return res.data.goods.map((good) => {
            return {
              label: `${good._id} - ${good.name} - ${good.aggregatedGoodsCategory}`,
              value: good._id,
              aggregatedGoodsCategory: good.aggregatedGoodsCategory,
            };
          });
        },
        className: "col-12",
        type: "dropdownSelect",
        isMulti: true,
        validation: z
          .array(
            z.object({
              label: z.string(),
              value: z.string(),
              aggregatedGoodsCategory: z.string(),
            })
          )
          .min(1, "Required field"),
      },
      {
        label: "Aggregated Goods Category",
        name: "aggregatedGoodsCategory",
        className: "col-6",
        dependency: "cbamGood",
        fetchValue: async (cbamGood) => {
          if (!cbamGood || cbamGood.length == 0) {
            return "";
          }
          return cbamGood[0].aggregatedGoodsCategory;
        },
        disabled: true,
        type: FormControlTypes.Text,
        validation: null,
      },

      {
        label: "Production Method",
        placeholder: "Select a method *",
        dependency: "cbamGood",
        name: "namePrefix",
        options: [],
        className: "col-6",
        optionLabel: "name",
        optionValue: "name",
        fetchOptions: async (values) => {
          if (values && values.length != 0) {
            let category = values[0].aggregatedGoodsCategory;
            let res = await http.get(`/cngoods/${category}/methods`);
            return res.data.methods.map((method) => {
              return {
                label: method.name,
                value: method.name,
                params: method.qualifyingParameters,
              };
            });
          } else {
            return [];
          }
        },
        type: "dropdownSelect",
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Net Mass",
        placeholder: "",
        name: "quantity",
        className: "col-5",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        type: "trigger",
        text: "Total quantity of CBAM goods produced in this process during the specified reporting period. Please do not include scrap or any other by-products here.",
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit *",
        name: "unit",
        className: "col-6",
        type: "dropdown",
        options: units.map((unit) => {
          return { name: unit.description, value: unit.code };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Net Mass of By-products (Tonnes)",
        placeholder: "",
        name: "byproduct",
        className: "col-11",
        type: FormControlTypes.Numeric,
        validation: z
          .string()
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          })
          .optional()
          .or(z.literal("").optional())
          .optional(),
      },
      {
        type: "trigger",
        text: "If applicable, specify the quantity of any by-products from this production process that contain carbon which would otherwise be emitted as CO2.",
      },
    ],
  },
  {
    header: "Waste Gas (if applicable)",
    name: "wasteGas",
    className: "",
    groupDependency: "aggregatedGoodsCategory",
    controls: [],
    possibleControls: {
      "Pig iron": wasteGasControls,
      "Crude steel": wasteGasControls,
    },
  },
  {
    header: "PFC Emissions",
    name: "inputDetails",
    className: "",
    groupDependency: "namePrefix",
    controls: [],
    possibleControls: {
      "Primary (electrolytic) smelting": [
        {
          label: "Method",
          placeholder: "Select output type",
          name: "pfcMethod",
          className: "col-6",
          type: FormControlTypes.Dropdown,
          options: [
            { name: "Slope method", value: "slope" },
            { name: "Overvoltage method", value: "overvoltage" },
          ],
          optionLabel: "name",
          optionValue: "value",
          hideEmptyOption: false,
          validation: z.string().min(1, "Required field"),
        },
      ],
      "Mix of primary and secondary production": [
        {
          label: "Method",
          placeholder: "Select output type",
          name: "pfcMethod",
          className: "col-6",
          type: FormControlTypes.Dropdown,
          options: [
            { name: "Slope method", value: "slope" },
            { name: "Overvoltage method", value: "overvoltage" },
          ],
          optionLabel: "name",
          optionValue: "value",
          hideEmptyOption: false,
          validation: z.string().min(1, "Required field"),
        },
      ],
    },
  },
  {
    name: "soemething",
    header: "",
    groupDependency: "pfcMethod",
    controls: [],
    possibleControls: {
      slope: SlopeControls,
      overvoltage: OvervoltageConfig,
    },
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
