import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const PriceSlider = ({ value, onChange, max, unitName }) => (
  <div className="w-full max-w-md mx-auto mb-8">
    <label htmlFor="units-slider" className="block text-sm font-medium text-gray-700 mb-2">
      Number of {unitName}: {value}
    </label>
    <input
      type="range"
      id="units-slider"
      min="1"
      max={max}
      value={value}
      onChange={onChange}
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
    />
  </div>
);

export const PricingTable = ({ title, description, basePrice, perUnitPrice, units, features, buttonText, time, unitName, included, isPopular, isEnterprise, pricingTiers, onSubscribe }) => {
  const calculatePrice = () => {
    if (isEnterprise) return null;
    
    if (pricingTiers) {
      // Operator pricing
      let totalPrice = basePrice;
      let remainingUnits = Math.max(0, units - included);
      
      for (const tier of pricingTiers) {
        if (remainingUnits > 0) {
          const unitsInTier = Math.min(remainingUnits, tier.max - tier.min + 1);
          totalPrice += unitsInTier * tier.price;
          remainingUnits -= unitsInTier;
        } else {
          break;
        }
      }
      
      return totalPrice;
    } else {
      // Declarant pricing
      if (units <= included) return basePrice;
      return basePrice + (units - included) * perUnitPrice;
    }
  };

  const totalPrice = calculatePrice();
  const periods = {
    "quarter": "3 months",
    "month": "month",
    "year": "12 months"
  };
  const period = periods[time];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
      <h2 className="text-2xl font-bold mb-2 text-secondaryColor">{title}</h2>
      <p className="mb-4 text-secondaryColor-light">{description}</p>
      {!isEnterprise && (
        <div className="mb-4">
          <span className="text-4xl text-secondaryColor font-bold">£{totalPrice}</span>
          <span className=" text-secondaryColor-light">/{period}</span>
          <p className="text-sm text-secondaryColor-light mt-2">
            £{basePrice} base fee (includes {included} {unitName})
            {pricingTiers ? (
              <React.Fragment>
                <br />
                {pricingTiers.map((tier, index) => (
                  <span key={index}>
                    £{tier.price} per {unitName} ({tier.min}{tier.max === Infinity ? '+' : `-${tier.max}`})<br />
                  </span>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <br />£{perUnitPrice} per additional {unitName}
              </React.Fragment>
            )}
          </p>
        </div>
      )}

      <div className="mb-4">
        <h3 className="font-semibold  text-secondaryColor pb-3">This includes:</h3>
        <ul className="pl-2">
          {features.map((feature, index) => (
            <li key={index} className="flex pb-3">
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#00B894] mr-2 mt-1 " size={20} />
              <span className="text-secondaryColor">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <button onClick={onSubscribe} className="w-full bg-[#00B894] text-white py-2 rounded-md hover:bg-[#00A080] transition-colors mt-4">
        {buttonText}
      </button>
    </div>
  );
};