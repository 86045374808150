import React, { forwardRef, useEffect, useState } from "react";
import {
  useAllCountries,
  useCbamCountries,
  useCnGoods,
  useEUCountries,
} from "../Utility";
import CgDropdown from "./CgDropdown";
import CgSelectDropdown from "./CgSelectDropdown";
import http from "../../../helpers/requests";

const CgOODropdown = forwardRef(
  ({ formControl, loading, onChange, error, shownForm }, ref) => {
    const [fc, setFc] = useState(formControl);
    const [operators, setOperators] = useState([]);

    useEffect(() => {
      http
        .get("/requests/operator?status=connected")
        .then((data) => {
          setOperators(data.data);
        });
    }, [shownForm]);

    let modifiedOperators = operators.map((operator) => {
      return {
        value: JSON.stringify(operator?.recieverId),
        label: `${operator?.recieverId?.name}`,
      };
    });

    useEffect(() => {
      setFc({
        ...formControl,
        options: modifiedOperators,
        optionLabel: "label",
        optionValue: "value",
        placeholder: formControl.placeholder
          ? formControl.placeholder
          : "Select a Supplier",
      });
    }, [operators]);

    useEffect(() => {
      setFc({
        ...fc,
        options: modifiedOperators,
        optionLabel: "label",
        optionValue: "value",
        value: formControl.value,
      });
    }, [formControl.value]);
    return (
      <CgSelectDropdown
        ref={ref}
        formControl={fc}
        loading={loading}
        error={error}
        onChange={onChange}
        jsonBodyKey="_id"
        jsonBodyValue={["name"]}
      />
    );
  }
);

export default CgOODropdown;
