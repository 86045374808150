import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import http from "../../helpers/requests";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import ShipmentGrid from "./components/ShipmentGrid";
import ShipmentTable from "./components/ShipmentTable";
import UpdateShipmentModal from "./components/UpdateShipmentModal";
import ShipmentDetailsModal from "./components/ShipmentDetailsModal";
import ShipmentsSearchForm from "./components/ShipmentsSearchForm";
import { notify, AlertType, notifyError } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";
import CgToggleButton from "../Common/CgToggleButton";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import fields from "./ShipmentsImportFields.js";
import { useCnGoods } from "../Common/Utility.js";
import ShipmentConfusionModal from "./components/ShipmentConfusionModal.jsx";
import CgTabs from "../Common/CgTabs.jsx";
import ShipmentResolveAmbiguityModal from "./components/ShipmentResolveAmbiguityModal.jsx";

/**
 * ShipmentsSearchPage is a component for managing shipments including search, view, create, update, and delete operations.
 * It also includes functionality for importing shipments from a spreadsheet.
 *
 * @returns {JSX.Element} The rendered ShipmentsSearchPage component.
 */
export default function ShipmentsSearchPage() {
  const history = useHistory();
  const allCnGoods = useCnGoods();
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      if (newLimit !== undefined) {
        setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
      } else {
        setPagination((prev) => ({ ...prev, page: newPage }));
      }
    },
  });
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [showUpdateShipmentModal, setShowUpdateShipment] = useState(false);
  const [showDetailsModal, setShowDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [declarantGoods, setDeclarantGoods] = useState([]);
  const [showConfusionErros, setShowConfusionErrors] = useState(false);
  const [confusionErrors, setConfusionErrors] = useState([]);
  const [form, setForm] = useState({
    supplierName: "",
    quarter: "",
    year: "",
    installationName: "",
    goods: [],
  });
  const [ambigous, setAmbigous] = useState(false);
  const modes = ["Imports", "Ambigous Imports"];
  const [mode, setMode] = useState(modes[0]);
  const [hasAmbigous, setHasAmbigous] = useState(false);
  const [refresh, setRefresh] = useState(false);

  function handleModeChange(newMode){
    setMode(newMode);
    // set ambigous to true if mode is set to ambigous
    setAmbigous(newMode === modes[1]); 
  }

  // Fetch declarant goods on component mount
  useEffect(() => {
    http.get("/cngoods/declarant").then((response) => {
      setDeclarantGoods(
        response.data.goods.map((good) => ({
          label: `${good.cnCode}-${good.name}`,
          value: good._id,
        }))
      );
    });
  }, []);
  useEffect(() => {
    http
      .post("/shipments/search", {
        ...form,
        ambigous: true,
        page: parseInt(pagination.page),
        items: parseInt(pagination.limit),
      })
      .then((response) => {
        setHasAmbigous(response.data.itemCount > 0);
        if(response.data.itemCount === 0){
          handleModeChange(modes[0]);
        }
      })
      .catch()
      .finally(() => setLoading(false));

  }, [refresh]);

  // Fetch search results whenever pagination changes
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit, ambigous, refresh]);

  // Handle search form reset
  const handleReset = () => {
    setResults(null);
  };

  const validateColumns = (table, rawData, columns) => {
    let cnCodes = allCnGoods.map((good) => good._id.split(" ").join(""));
    let data = table.filter((row) => cnCodes.includes(row.code));
    let filtered = table.length - data.length;
    notify(
      `Filtered ${filtered} rows with non-CBAM CN Codes`,
      "",
      AlertType.INFO
    );
    return data;
  };

  const handleImportSubmit = async (data) => {
    let { validData } = data;
    function combineObjectsByShipmentID(objects) {
      // Create an empty object to store the combined entries
      let combinedObject = {};
      // Submit search form and fetch results
      // Iterate over the array of objects
      objects.forEach((obj) => {
        let newContents = { ...obj };
        delete newContents["shipmentID"];
        delete newContents["deliveryDate"];
        // Check if the shipmentID already exists in the combined object
        if (combinedObject.hasOwnProperty(obj.shipmentID)) {
          // If it exists, push the current object's contents to the existing array
          combinedObject[obj.shipmentID].shipmentContents.push(newContents);
        } else {
          // If it doesn't exist, create a new entry with the shipmentID and contents array
          combinedObject[obj.shipmentID] = {
            shipmentID: obj.shipmentID,
            deliveryDate: obj.deliveryDate,
            shipmentContents: [newContents],
          };
        }
      });
      // Convert the combinedObject into an array of values
      return Object.values(combinedObject);
    }
    const combinedShipments = combineObjectsByShipmentID(validData);
    try {
      let res = await http.post(`/shipments/import`, {
        excelShipments: combinedShipments,
      });
      handleSubmit();
    } catch (err) {
      notifyError(err);
      let error = err?.response?.data;
      setConfusionErrors(error?.confusionErrors);
      setShowConfusionErrors(error?.confusionErrors != undefined && error?.confusionErrors.length > 0);
      throw new Error(err);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    http
      .post("/shipments/search", {
        ...form,
        ambigous,
        page: parseInt(pagination.page),
        items: parseInt(pagination.limit),
      })
      .then((response) => {
        setResults(response.data.shipments);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
      })
      .catch()
      .finally(() => setLoading(false));
  };

  // Redirect to the create shipment page
  const handleCreateShipment = () => {
    history.push("/imports/create");
  };

  // Handle shipment View
  const handleViewShipment = (shipment) => {
    setSelectedShipment(shipment);
    setShowDetails(true);
  };

  // Handle shipment Edt
  const handleEditShipment = (shipment) => {
    setSelectedShipment(shipment);
    setShowUpdateShipment(true);
  };

  // Handle shipment deletion
  const handleDeleteShipment = (shipment) => {
    http
      .remove(`/shipments/${shipment._id}`)
      .then(() => {
        if (results.length === 1 && pagination.page > 1) {
          setPagination((prev) => ({ ...prev, page: pagination.page - 1 }));
        }
        handleSubmit();
        notify(
          "Success",
          "Consignment has been deleted successfully",
          AlertType.SUCCESS
        );
      })
      .catch((err) => {
        notify(
          `${err?.resource} ${err?.action} has failed`,
          err.response.data.message,
          AlertType.ERROR
        );
      });
  };

  const renderFilterComponent = () => {
    return (
      <ShipmentsSearchForm
        key="shipments-search-form"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
        onReset={handleReset}
        goods={declarantGoods}
      />
    );
  };

  // Action buttons for the page
  const actionButtons = [
    <CgButton
      key="create-shipment"
      variant="primary"
      label="New Shipment"
      icon="faPlusCircle"
      size="md"
      onClick={handleCreateShipment}
    />,
    <CgButton
      key="import"
      variant="primary"
      label="Upload"
      icon="faCloudUploadAlt"
      size="md"
      onClick={() => setIsOpen(true)}
    />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
  ];

  const tabComponent = (props) => 
    <div className="px-12 pb-4">
      <CgTabs 
        tabs={modes}
        tabIcons={[null, {icon: "faExclamationTriangle", color: "orange"}]}
        selectedTab={props.mode}
        setSelectedTab={props.handleModeChange}
      />
    </div>;
  
  return (
    <>
      <CgDataManagementPage
        title="Manage Imports"
        subtitle="Here you can search, view, create or edit your Imports"
        actionButtons={actionButtons}
        searchForm={renderFilterComponent()}
        displayModes={["Table", "Cubes"]}
        gridComponent={ShipmentGrid}
        tableComponent={ShipmentTable}
        tabComponent={hasAmbigous ? tabComponent : (props) => <></>}
        mode={mode}
        modes={modes}
        handleModeChange={handleModeChange}
        results={results}
        displayMode={displayMode}
        onViewItem={(shipment) => handleViewShipment(shipment)}
        onEditItem={(shipment) => handleEditShipment(shipment)}
        onDeleteItem={handleDeleteShipment}
        onCreateItem={handleCreateShipment}
        onImport={() => setIsOpen(true)}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
      <UpdateShipmentModal
        show={showUpdateShipmentModal}
        shipment={selectedShipment}
        onClose={() => setShowUpdateShipment(false)}
        onRefresh={handleSubmit}
      />
      {mode === modes[0] && <ShipmentDetailsModal
        show={showDetailsModal}
        shipment={selectedShipment}
        onClose={() => setShowDetails(false)}
      />}
      {mode === modes[1] && <ShipmentResolveAmbiguityModal
        show={showDetailsModal}
        shipment={selectedShipment}
        onRefresh={() => setRefresh(prev => !prev)}
        onClose={() => setShowDetails(false)}
      />}
      <ReactSpreadsheetImport
        isOpen={isOpen}
        fields={fields}
        autoMapSelectValues={true}
        isNavigationEnabled={true}
        autoMapDistance={20}
        onClose={() => setIsOpen(false)}
        onSubmit={handleImportSubmit}
        matchColumnsStepHook={validateColumns}
      />
      <ShipmentConfusionModal
        errors={confusionErrors}
        showModal={showConfusionErros}
        onCloseModal={() => setShowConfusionErrors(false)}
      />
    </>
  );
}
