import React, { useEffect, useRef, useState, useContext } from "react";
import { Modal, Tab, Tabs, Table } from "react-bootstrap";
import http from "../../helpers/requests";
import DataInputInfoForm from "./DataInputs/DataInputInfoForm";
import { dataSourceNodeTypes, getNcvUnit, NodeType } from "./Nodes/utils";
import CgButton from "../Common/CgButton";
import stepsElectricity from "./JoyRide/Configuration/stepsElectricity";
import stepsFuelStandard from "./JoyRide/Configuration/stepsFuelStandard";
import stepsFuelMassBalance from "./JoyRide/Configuration/stepsFuelMassBalance";
import stepsPurchasedCBAMGoods from "./JoyRide/Configuration/stepsPurchasedCBAMGoods";
import { TutorialContext } from "./JoyRide/Utilities/TutorialContext";

export default function AddInputModal({
  show,
  setShow,
  onSubmit,
  initialState = {},
  edges,
  handlePreviousStep,
}) {
  const isChanged = useRef(false);
  const [formData, setFormData] = useState({});
  const [dataSources, setDataSources] = useState(
    initialState.dataSources ?? {}
  );

  // Access context states
  const {
    run,
    setRun,
    steps,
    setSteps,
    tutorialRunFlag,
    miniTutorialRun,
    setMiniTutorialRun,
  } = useContext(TutorialContext);

  useEffect(() => {
    if (show) {
      setDataSources(initialState.dataSources ?? {});
    } else {
      setDataSources({});
    }
  }, [show, initialState.dataSources]);

  useEffect(() => {
    if (show) {
      if (initialState?.inputType === "Electricity") {
        setSteps(stepsElectricity);
      } else if (initialState?.inputType === "Fuel") {
        if (formData?.method === "Standard") {
          setSteps(stepsFuelStandard);
        } else if (formData?.method === "Mass Balance") {
          setSteps(stepsFuelMassBalance);
        } else {
          setSteps(stepsFuelStandard);
        }
      } else if (initialState?.inputType === "Purchased CBAM Good") {
        setSteps(stepsPurchasedCBAMGoods);
      }
    }
  }, [show, formData?.method, initialState?.inputType, setSteps]);

  const onCloseInput = () => {
    if (miniTutorialRun) {
      setMiniTutorialRun(false);
    }
    if (handlePreviousStep) {
      handlePreviousStep();
    }
    setSteps([]); // Clear steps when modal is closed
    setShow(false);
  };

  const handleSubmit = async (data) => {
    let dataSources = await handleDataSources();
    data.dataSources = dataSources;
    onSubmit(data);
  };

  const onFormChange = (formData) => {
    setFormData(formData);
    isChanged.current = true;
  };

  const fetchDataSources = async (
    formResourceName,
    formInstallationId,
    formNcvUnit,
    formEmissionsFactor
  ) => {
    let dataSources = {};

    let queryParams = new URLSearchParams({
      installationId: formInstallationId,
      resourceName: formResourceName,
    });
    if (formNcvUnit) queryParams.append("ncvUnit", getNcvUnit(formNcvUnit));

    let dataSourceResponse = await http.get(
      `/emissions/standard/factors?${queryParams.toString()}`
    );

    if (
      dataSourceResponse.data?.ncv &&
      dataSourceResponse.data?.ncv == formData.ncv
    ) {
      dataSources = {
        ...dataSources,
        netCalorificValue: {
          dataSource: dataSourceResponse.data?.dataSource,
          dataLink: dataSourceResponse.data?.dataLink,
          notes: dataSourceResponse.data?.notes,
          date: dataSourceResponse.data?.date,
          granularity: dataSourceResponse.data?.granularity,
        },
      };
    } else if (formData.ncv) {
      dataSources = {
        ...dataSources,
        netCalorificValue: {
          dataSource: "User Input",
          dataLink: "",
          notes: "",
          date: "",
          granularity: "",
        },
      };
    }

    if (dataSourceResponse.data?.factor == formEmissionsFactor) {
      dataSources = {
        ...dataSources,
        emissionFactor: {
          dataSource: dataSourceResponse.data?.dataSource,
          dataLink: dataSourceResponse.data?.dataLink,
          notes: dataSourceResponse.data?.notes,
          date: dataSourceResponse.data?.date,
          granularity: dataSourceResponse.data?.granularity,
        },
      };
    } else {
      dataSources = {
        ...dataSources,
        emissionFactor: {
          dataSource: "User Input",
          dataLink: "",
          notes: "",
          date: "",
          granularity: "",
        },
      };
    }
    return dataSources;
  };

  const handleDataSources = async () => {
    if (isChanged.current) {
      isChanged.current = false;
      let dataSources = {};

      if (dataSourceNodeTypes.includes(formData.inputType)) {
        let resourceName, formEmissionsFactor;
        if (formData.inputType == NodeType.Electricity) {
          resourceName = NodeType.Electricity;
          formEmissionsFactor = formData.indirectEmissionFactor;
        } else {
          resourceName = formData.name;
          formEmissionsFactor = formData.emissionFactor;
        }
        dataSources = await fetchDataSources(
          resourceName,
          formData.installationId,
          formData.ncvUnit,
          formEmissionsFactor
        );
      }

      setDataSources(dataSources);
      return dataSources;
    }
    return dataSources;
  };

  const handleHelp = () => {
    if (show) {
      if (!tutorialRunFlag) {
        setMiniTutorialRun(true);
      } else {
        setRun(true);
      }
      if (initialState?.inputType === "Electricity") {
        setSteps(stepsElectricity);
      } else if (initialState?.inputType === "Fuel") {
        if (formData?.method === "Standard") {
          setSteps(stepsFuelStandard);
        } else if (formData?.method === "Mass Balance") {
          setSteps(stepsFuelMassBalance);
        } else {
          setSteps(stepsFuelStandard);
        }
      } else if (initialState?.inputType === "Purchased CBAM Good") {
        setSteps(stepsPurchasedCBAMGoods);
      }
    }
  };

  const camelCaseToHuman = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());

  const getBody = () => {
    const dataForm = (
      <DataInputInfoForm
        onFormChange={onFormChange}
        installationId={formData.installationId}
        onSubmit={handleSubmit}
        initialState={initialState}
        edges={edges}
        shownForm={show}
      />
    );
    if (!dataSourceNodeTypes.includes(initialState.inputType)) {
      return dataForm;
    } else {
      return (
        <Tabs
          defaultActiveKey="form"
          transition={false}
          className="mb-3"
          onClick={() => handleDataSources()}
        >
          <Tab eventKey="form" title="Input Form">
            {dataForm}
          </Tab>
          <Tab eventKey="data" title="Data Sources">
            <Table size="sm" striped responsive hover borderless>
              <thead>
                <td>
                  <b>Name</b>
                </td>
                <td>
                  <b>Source</b>
                </td>

                <td>
                  <b>Year</b>
                </td>
                <td>
                  <b>Granularity</b>
                </td>
                <td>
                  <b>Notes</b>
                </td>
              </thead>
              <tbody>
                {Object.keys(dataSources).map((key, index) => (
                  <tr key={index}>
                    <td>{camelCaseToHuman(key)}</td>
                    <td>
                      {dataSources[key].dataSource !== "User Input" ? (
                        <a
                          className="text-blue-700 underline"
                          target="_blank"
                          href={dataSources[key].dataLink}
                          rel="noreferrer"
                        >
                          {dataSources[key].dataSource}
                        </a>
                      ) : (
                        "User Input"
                      )}
                    </td>
                    <td>
                      {new Date(dataSources[key].date).getFullYear() || ""}
                    </td>
                    <td>{dataSources[key].granularity || ""}</td>

                    <td>{dataSources[key].notes || ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      );
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onCloseInput}
      enforceFocus={false}
      style={{ position: "absolute" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          External Data Input{" "}
          <div className="absolute right-12 top-4">
            <CgButton
              onClick={handleHelp}
              variant="primary"
              label="Guide"
              icon="faBookOpen"
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{getBody()}</Modal.Body>
    </Modal>
  );
}
