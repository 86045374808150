import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";
import { Form } from "react-bootstrap";

let units = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
  // {code: "04", description: "m3"}
];

let manualFormControls = [
  {
    label: "CBAM Good",
    placeholder: "",
    name: "cbamGood",
    className: "col-12",
    fetchOptions: async () => {
      let res = await http.get("/cngoods/cbam");
      return res.data.goods.map((good) => {
        return {
          label: `${good._id} - ${good.name} - ${good.aggregatedGoodsCategory}`,
          value: good._id,
          aggregatedGoodsCategory: good.aggregatedGoodsCategory,
        };
      });
    },
    type: FormControlTypes.DropdownSelect,
    validation: z.string().min(1, "Required field"),
  },
  {
    type: FormControlTypes.Hidden,
    name: "aggregatedGoodsCategory",
    dependency: "cbamGood",
    fetchValue: async (value) => {
      let res = await http.get(`/cngoods/cbam?cnCode=${value}`);

      return res.data.goods[0].aggregatedGoodsCategory;
    },
  },
  {
    label: "Supplier Name",
    placeholder: "",
    name: "sourceSupplier",
    className: "col-6",
    type: FormControlTypes.Text,
    validation: null,
  },
  {
    label: "Supplier Country",
    placeholder: "",
    name: "country",
    className: "col-6",
    type: FormControlTypes.ALLDropdown,
    validation: z.string().min(1, "Required field"),
  },
  {
    label: "Net Mass",
    placeholder: "",
    name: "quantity",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Measurement Unit",
    placeholder: "Select unit",
    name: "unit",
    className: "col-6",
    type: FormControlTypes.Dropdown,
    options: units.map((unit) => {
      return { name: unit.description, value: unit.code };
    }),
    optionLabel: "name",
    optionValue: "value",
    hideEmptyOption: false,
    validation: z.string().min(1, "Required field"),
  },
  {
    label: "Direct SEE",
    placeholder: "",
    dependencies: ["cbamGood", "country", "useDefault"],
    shouldBeDisabled: (formData) => {
      return formData.useDefault == true;
    },
    name: "emissionFactor",
    className: "col-4",
    type: FormControlTypes.Numeric,
    fetchValue: async (fieldName, value, formData) => {
      if (formData.useDefault) {
        try {
          let res = await http.get(
            `/emissions/factors?country=${formData.country}&cnCode=${formData.cbamGood}`
          );
          return res.data.emissionFactors.direct;
        } catch (err) {
          console.log(err);
        }
      } else {
        return formData.emissionFactor;
      }
    },
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Indirect SEE",
    placeholder: "",
    dependencies: ["cbamGood", "country", "useDefault"],
    name: "indirectEmissionFactor",
    className: "col-4",
    type: FormControlTypes.Numeric,
    shouldBeDisabled: (formData) => {
      return formData.useDefault == true;
    },
    fetchValue: async (fieldName, value, formData) => {
      if (formData.useDefault) {
        try {
          let res = await http.get(
            `/emissions/factors?country=${formData.country}&cnCode=${formData.cbamGood}`
          );
          return res.data.emissionFactors.indirect;
        } catch (e) {
          console.log(e);
        }
      } else {
        return formData.indirectEmissionFactor ?? 0;
      }
    },
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        const num = parseFloat(arg);

        // Check if the value is not a number or is negative
        if (isNaN(num) || num < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }

        return num; // Return the parsed number for further use
      }),
  },
  {
    label: "Use default values",
    name: "useDefault",
    className: "col-4",
    type: FormControlTypes.Checkbox,
    validation: null,
  },
  {
    label: "Electricity Consumed (MWh/tonne of product)",
    placeholder: "",
    name: "electricityConsumed",
    className: "col-8",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },
];

let connectionFormControls = [
  {
    label: "Supplier Name",
    placeholder: "",
    name: "supplier",
    className: "col-6",
    type: FormControlTypes.OODropdown,
    validation: null,
  },
  {
    label: "Supplier Country",
    placeholder: "",
    name: "country",
    className: "col-6",
    type: FormControlTypes.ALLDropdown,
    dependency: "supplier",
    disabled: true,
    fetchValue: (value, e) => {
      return value ? JSON.parse(value)?.address?.country?._id.toString() : null;
    },
    validation: z.string().min(1, "Required field"),
  },
  {
    label: "Supplier Name",
    placeholder: "",
    name: "sourceSupplier",
    type: FormControlTypes.Hidden,
    dependency: "supplier",
    fetchValue: (sourceSupplier) => {
      let name = sourceSupplier ? JSON.parse(sourceSupplier).name : "";
      return name;
    },
  },
  {
    label: "Supplier Product",
    placeholder: "Select the Supplier's Product",
    name: "product",
    className: "col-12",
    dependency: "supplier",
    jsonBodyKey: "id",
    jsonBodyValue: ["cnGood"],
    fetchOptions: async (value, e) => {
      let id = value ? JSON.parse(value)?._id : null;
      if (!id) return [];
      let res = await http.get(`/emissions/operators/${id}`);
      return res.data.map((emission) => ({
        label: `${emission._id.cnGood} - ${emission._id.operatorGoodName} - ${emission._id.lastRecordYear}`,
        value: JSON.stringify({
          id: emission?._id?._id,
          cnGood: emission?._id?.cnGood,
          electricity: emission?.indirectMeta?.electricityConsumed,
          aggregatedGoodsCategory: emission?.cnGood?.aggregatedGoodsCategory,
          direct: emission?.emissionFactors?.direct,
          indirect: emission?.emissionFactors?.indirect,
        }),
      }));
    },
    type: FormControlTypes.DropdownSelect,
    validation: z.string().min(1, "Required field"),
  },
  {
    type: FormControlTypes.Hidden,
    name: "cbamGood",
    dependency: "product",
    fetchValue: (value) => (value ? JSON.parse(value).cnGood : null),
  },
  {
    type: FormControlTypes.Hidden,
    name: "aggregatedGoodsCategory",
    dependency: "product",
    fetchValue: (value) =>
      value ? JSON.parse(value).aggregatedGoodsCategory : null,
  },
  {
    label: "Net Mass",
    placeholder: "",
    name: "quantity",
    className: "col-6",
    type: FormControlTypes.Numeric,
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Measurement Unit",
    placeholder: "Select unit",
    name: "unit",
    className: "col-6",
    type: FormControlTypes.Dropdown,
    options: units.map((unit) => {
      return { name: unit.description, value: unit.code };
    }),
    optionLabel: "name",
    optionValue: "value",
    hideEmptyOption: false,
    validation: z.string().min(1, "Required field"),
  },
  {
    label: "Direct SEE",
    placeholder: "",
    dependency: "product",
    disabled: true,
    name: "emissionFactor",
    className: "col-6",
    type: FormControlTypes.Numeric,
    fetchValue: (value) => (value ? JSON.parse(value)?.direct : null),
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Indirect SEE",
    placeholder: "",
    dependency: "product",
    name: "indirectEmissionFactor",
    className: "col-6",
    type: FormControlTypes.Numeric,
    disabled: true,
    fetchValue: (value) => (value ? JSON.parse(value)?.indirect : null),
    validation: z
      .string()
      .min(1, "Required field")
      .or(z.number())
      .transform((arg, ctx) => {
        if (!parseFloat(arg)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      }),
  },
  {
    label: "Electricity Consumed (MWh/tonne of product)",
    placeholder: "",
    dependency: "product",
    disabled: true,
    name: "electricityConsumed",
    className: "col-12",
    type: FormControlTypes.Numeric,
    fetchValue: (value) => (value ? JSON.parse(value).electricity : null),
    validation: z
      .string()
      .or(z.number())
      .transform((arg, ctx) => {
        let num = parseFloat(arg);
        if (!num && num != 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
        }
        return arg;
      })
      .optional()
      .or(z.literal("").optional())
      .optional(),
  },
  {
    label: "Use default values",
    name: "useDefault",
    type: FormControlTypes.Hidden,
    dependency: "product",
    fetchValue: (value) => false,
    validation: null,
  },
];

export const CbamGoodFormConfig = [
  {
    header: "Precursor CBAM Good",
    name: "Precursor CBAM Good",
    className: "",
    controls: [
      {
        label: "Input Method",
        placeholder: "Select method",
        name: "method",
        className: "col-12",
        type: FormControlTypes.Dropdown,
        options: [
          {
            name: "Manual",
            value: "Manual",
          },
          {
            name: "Connection",
            value: "Connection",
          },
        ],
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: true,
      },
    ],
  },
  {
    header: "CBAM Good Information",
    name: "CBAM Good Information",
    className: "",
    groupDependency: "method",
    controls: [...manualFormControls],
    possibleControls: {
      Manual: manualFormControls,
      Connection: connectionFormControls,
    },
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
