import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  getSimpleBezierPath,
  getSmoothStepPath,
  getStraightPath,
  useReactFlow,
} from "@xyflow/react";
import {
  abbreviateNumber,
  BorderColors,
  calculateEmissions,
  cascadeEmissionUpdates,
  commaSeparatedNumber,
  electricityTypes,
  ElectricityUnits,
  FlowType,
  FlowTypeDefaultUnit,
  getDisplayUnit,
  GraphColors,
  MaterialUnits,
  NodeType,
  NodeUnits,
  validEdge,
} from "./utils";
import { useEffect, useState } from "react";
import { Dropdown, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import FlowInputForm from "../DataInputs/FlowInputForm";
import Decimal from "decimal.js";
import { clearMemo, setMemo } from "./memoTable";

export function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
  style = {},
  pushUndo,
}) {
  let {
    getEdge,
    updateEdge,
    getEdges,
    getNode,
    updateNodeData,
    updateEdgeData,
    deleteElements,
    screenToFlowPosition,
  } = useReactFlow();
  let { quantity, inputType, flowType, unit } = data;
  let edge = getEdge(id);

  let source = getNode(edge.source);
  let target = getNode(edge.target);
  let displayUnit = getDisplayUnit(source, flowType);

  const [resolveInput, setResolveInput] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  let textColor = [NodeType.Electricity, NodeType.ElectricityProcess].includes(
    inputType
  )
    ? "text-black"
    : "text-white";

  const [showModal, setShowModal] = useState(false);

  const [edgePath, labelX, labelY] = getSimpleBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const handleEdgeEdit = async () => {
    clearMemo();
    let edge = getEdge(id);
    let source = getNode(edge.source);
    let target = getNode(edge.target);
    let direct = new Decimal(edge.data.direct);
    let indirect = new Decimal(edge.data.indirect);
    let targetDirect = new Decimal(target.data.direct ?? 0);
    let targetIndirect = new Decimal(target.data.indirect ?? 0);

    if (
      source.data.inputType == NodeType.Fuel &&
      target.data.inputType == NodeType.HeatProcess
    ) {
      target.data.quantity = new Decimal(target.data.quantity)
        .minus(new Decimal(edge.data.quantity).mul(edge.data.ncv ?? 0))
        .toNumber();
    }

    let { flow, flowType, carbonContent } = await promptUser();
    if (
      source.data.inputType == NodeType.CBAMProcess ||
      source.data.inputType == NodeType.SubProcess
    ) {
      if (
        [FlowType.Emission, FlowType.Heat].includes(edge.data.flowType) ||
        Boolean(edge.data.carbonContent)
      ) {
        if (
          edge.data.flowType == FlowType.Emission &&
          electricityTypes.includes(target.data.inputType)
        ) {
          direct = indirect;
        }
        let capturedEmissions = direct;
        if (Boolean(edge.data.carbonContent)) {
          let storedEmissions = calculateEmissions({
            ...source.data,
            quantity: edge.data.quantity,
            totalQuantity: source.data.quantity,
            carbonContent: edge.data.carbonContent,
          });
          capturedEmissions = storedEmissions.direct;
        }
        let sourceDirect = new Decimal(source.data.direct ?? 0).add(
          capturedEmissions
        );
        let sourceIndirect = new Decimal(source.data.indirect ?? 0);
        source.data.direct = sourceDirect.toNumber();
        source.data.emissions = sourceDirect.add(sourceIndirect).toNumber();
      }
      if (
        FlowType.Good == edge.data.flowType &&
        (target.data.inputType == NodeType.ProducedCBAMGood ||
          target.data.inputType == NodeType.SubProcess)
      ) {
        target.data.quantity = new Decimal(target.data.quantity ?? 0)
          .minus(new Decimal(edge.data.quantity))
          .add(new Decimal(flow))
          .toNumber();
      }
    }
    if (Boolean(carbonContent)) {
      let newCapturedEmissions = calculateEmissions({
        ...source.data,
        quantity: flow,
        totalQuantity: source.data.quantity,
        carbonContent,
      });
      source.data.direct = new Decimal(source.data.direct)
        .minus(newCapturedEmissions.direct)
        .toNumber();
      source.data.emissions = new Decimal(source.data.direct)
        .add(source.data.indirect)
        .toNumber();
    }
    if (!validEdge(source, target, flow, flowType, getEdges())) {
      return;
    }
    pushUndo();

    targetDirect = targetDirect.minus(direct);
    targetIndirect = targetIndirect.minus(indirect);

    ({ direct, indirect } = calculateEmissions({
      ...source.data,
      quantity: flow,
      flowType,
      totalQuantity: source.data.quantity,
    }));

    if (target.data.inputType == NodeType.ElectricityProcess) {
      indirect = direct;
      direct = new Decimal(0);
    }

    targetDirect = targetDirect.add(direct);
    targetIndirect = targetIndirect.add(indirect);
    let targetEmissions = targetDirect.plus(targetIndirect);
    let newEdgeData = {
      ...data,
      quantity: flow,
      direct: direct.toNumber(),
      indirect: indirect.toNumber(),
      carbonContent,
    };
    cascadeEmissionUpdates(
      edge.source,
      { ...source.data },
      { getNode, getEdges, updateNodeData, updateEdgeData }
    );
    setMemo(edge.id, { ...edge, data: newEdgeData });
    updateEdge(id, {
      data: newEdgeData,
    });

    cascadeEmissionUpdates(
      edge.target,
      {
        ...target.data,
        emissions: targetEmissions.toNumber(),
        direct: targetDirect.toNumber(),
        indirect: targetIndirect.toNumber(),
      },
      {
        getNode,
        getEdges,
        updateNodeData,
        updateEdgeData,
      }
    );
    setShowModal(false);
  };
  useEffect(() => {
    const handleDocumentClick = () => {
      if (contextMenuVisible) {
        setContextMenuVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [contextMenuVisible]);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuVisible(true);
    // Get the node's bounding rectangle
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the position of the context menu
    let x = event.clientX;
    let y = event.clientY;

    // Adjust the position if it's too close to the right or bottom edge
    const menuWidth = 200; // Approximate width of the context menu
    const menuHeight = 100; // Approximate height of the context menu

    if (x + menuWidth > viewportWidth) {
      x = viewportWidth - menuWidth;
    }

    if (y + menuHeight > viewportHeight) {
      y = viewportHeight - menuHeight;
    }
    event.stopPropagation(); // necessary to avoid conflicting with the context menu inside GraphFlow
    setContextMenuPosition(screenToFlowPosition({ x, y }));
  };
  const handleDelete = () => {
    clearMemo();
    if (!validEdge(source, target, 0, flowType, getEdges())) {
      return;
    }
    deleteElements({ edges: [{ id }] });
    setContextMenuVisible(false);
  };
  const promptUser = () => {
    setShowModal(true);
    return new Promise((resolve) => {
      setResolveInput(() => resolve);
    });
  };

  let bg = GraphColors[inputType] ?? "bg-primary";

  const getFlowName = () => {
    const capitalizeString = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    let CBAMGood = "CBAM Good";
    if (inputType == NodeType.CBAMProcess) {
      switch (flowType) {
        case FlowType.Emission:
          return "Waste Gas";
        case FlowType.Good:
          return CBAMGood;
        default:
          return capitalizeString(flowType);
      }
    } else {
      switch (inputType) {
        case NodeType.ElectricityProcess:
          return capitalizeString(FlowType.Electricity);
        case NodeType.HeatProcess:
          return capitalizeString(FlowType.Heat);
        case "Purchased CBAM Good":
          return "Precursor";
        case NodeType.SubProcess:
          return CBAMGood;
        case NodeType.ProducedCBAMGood:
          return CBAMGood;
        default:
          return inputType;
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onClick={(e) => e.stopPropagation()} // Prevent modal click from propagating 
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Max Flow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FlowInputForm
            show={showModal}
            onSubmit={(formData) => {
              console.log("this happened on close", formData);
              if (resolveInput) {
                let { quantity, flowType, carbonContent } = formData;

                if (resolveInput) {
                  resolveInput({
                    flow: quantity,
                    flowType: flowType,
                    carbonContent,
                  });
                }
              }
            }}
            sourceNode={source}
            targetNode={target}
            currentFlow={quantity}
            flowType={flowType}
            edgeId={id}
          />
        </Modal.Body>
      </Modal>

      <BaseEdge className={bg} id={id} path={edgePath} style={{ ...style }} />
      <EdgeLabelRenderer>
        {contextMenuVisible && (
          <Dropdown.Menu
            show={contextMenuVisible}
            style={{
              position: "absolute",
              top: `${contextMenuPosition.y}px`,
              left: `${contextMenuPosition.x}px`,
              zIndex: 10060,
              fontSize: "24px", // Increase font size
              padding: "10px", // Adjust padding to make items larger
              minWidth: "250px", // Set a minimum width for the dropdown menu
              pointerEvents: "auto", // Ensure pointer events are enabled
            }}
          >
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation
                handleEdgeEdit();
              }}
              style={{
                padding: "10px 20px", // Add more padding to the item
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation
                handleDelete();
              }}
              style={{
                padding: "10px 20px", // Add more padding to the item
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
        <OverlayTrigger
          key={`overlay-quantity-${id}`}
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Popover id={`Popover-quantity-${id}`}>
              <div className="p-3">
                {commaSeparatedNumber(quantity)} {displayUnit}
              </div>
            </Popover>
          }
          rootClose={true}
        >
          <p
            onClick={() => handleEdgeEdit()}
            onContextMenu={handleContextMenu}
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: "all",
              borderRadius: "15px",
              border: `2px solid ${BorderColors[inputType]}`,
            }}
            className={`${bg} p-2 ${textColor}`}
          >
            {abbreviateNumber(quantity)} {displayUnit} ({getFlowName()})
          </p>
        </OverlayTrigger>
      </EdgeLabelRenderer>
    </>
  );
}
